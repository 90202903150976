import { Component, OnInit, Input, OnDestroy, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import 'rxjs/operators';
import { ClaimTypeEnums, ClaimTypeNames, PhysicianStatusesIdEnums, ViaEnums } from './../../../../common/constants/Enums';
import { ClaimsService, ClaimValues, AuthService } from '@mt-ng2/auth-module';
import { PhysicianService } from '../physician.service';
import { ClaimTypes } from '@model/ClaimTypes';
import { PhysicianStatusService } from './../physicianstatus.service';
import { IPhysician } from '@model/interfaces/physician';
import { IPhysicianDetail } from '@model/interfaces/physician-detail';
import { IPhysicianStatus } from '@model/interfaces/physician-status';
import { PhysicianDetailDynamicControlsPartial } from '@model/partials/physician-detail-partial.form-controls';
import { Subject, Subscription, forkJoin } from '../../../../../../node_modules/rxjs';
import { NotificationsService } from '../../../../../../node_modules/@mt-ng2/notifications-module';
import { PhysicianFollowupLogDynamicControlsPartial } from '@model/partials/physician-followup-log-partial.form-controls';
import { PhysicianFollowupService, emptyPhysicianFollowupLog } from '../physician-followup-log.service';
import { IPhysicianFollowupLog } from '@model/interfaces/physician-followup-log';
import { PhysicianFollowupLabelGenerator } from '../physician-followup-log.label';
import { PhysicianStatusLabelGenerator } from '../physician-status-log.label';
import { PhysicianStatusLogService, emptyPhysicianStatusLog } from '../physician-status-log.service';
import { IPhysicianStatusLogPartial } from '@model/partials/physician-status-log-partial';
import { IUser } from '@model/interfaces/user';
import { UserService } from '../../../../users/user.service';
import { IPhysicianFollowupLogPartial } from '@model/partials/physician-followup-log-partial';
import { finalize } from 'rxjs/operators';
import { CommonEmailComponent } from '@common/common-email/common-email.component';
import { IEmailTemplate } from '@model/interfaces/email-template';
import { ImedClaimTypes } from '@model/ImedClaimTypes';
import { IFeeCostType } from '@model/interfaces/fee-cost-type';

@Component({
    selector: 'app-physicians-statuses',
    templateUrl: './physician-statuses.component.html',
})
export class PhysicianStatusesComponent implements OnInit {
    searchControl = new UntypedFormControl();
    @Input('physicianModel') physicianModel: IPhysician;

    isHovered: boolean;

    physician: IPhysician;
    physicianDetail: IPhysicianDetail;
    editingComponent: Subject<any> = new Subject();
    phyisicianStatusTypes: IPhysicianStatus[];
    filmReviewFeeTypes: IFeeCostType[];
    filmReviewCostTypes: IFeeCostType[];
    physicianFollowupLog: IPhysicianFollowupLogPartial;
    physicianStatusLog: IPhysicianStatusLogPartial;
    individualId: number;
    addressBookId: number;
    physicianStatusControls: any;
    physicianStatusControlsView: any;
    physicianFollowupControls: any;
    physicianFollowupControlsView: any;
    isEditing: boolean;
    doubleClickIsDisabled = false;
    physicianStatusesIdEnums = PhysicianStatusesIdEnums;
    physicianCaseTypeEnums = ImedClaimTypes;
    physicianFollowupLogs: IPhysicianFollowupLog[] = [];
    physicianStatusLogs: IPhysicianStatusLogPartial[] = [];
    formReady: boolean;
    selectedPhysicianStatus = 1;
    selectedFilmReviewFeeType: number;
    selectedFilmReviewCostType: number;
    showEmailControl = true;
    selectedPhysicianCaseTypeId: number;
    subscriptions: Subscription = new Subscription();
    resultingSubscription: Subscription;

    // Mini lists
    followupCardName = 'Physician Follow-up Logs';
    followupLabelGenerator = new PhysicianFollowupLabelGenerator();
    statusCardName = 'Physician Status Logs';
    statusLabelGenerator = new PhysicianStatusLabelGenerator();

    currentUser: IUser;

    form: UntypedFormGroup;

    canEdit = false;
    canViewPhysicianFees = false;
    canViewPhysicianCost = false;
    canEditPhysicianFees = false;
    canViewPhysicianStatus = false;
    canEditPhysicianCost = false;

    @ViewChild('emailComponent') emailComponent: CommonEmailComponent;

    constructor(
        private claimsService: ClaimsService,
        private physicianService: PhysicianService,
        private physicianStatusService: PhysicianStatusService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private physicianFollowupService: PhysicianFollowupService,
        private physicianStatusLogService: PhysicianStatusLogService,
        private userService: UserService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.AddressBooks, [ClaimValues.FullAccess]);
        this.canViewPhysicianStatus = this.claimsService.hasClaim(ClaimTypes.AddressBooks, [ClaimValues.ReadOnly, ClaimValues.FullAccess]);
        this.canViewPhysicianFees = this.claimsService.hasClaim(ClaimTypes.PhysicianFees, [ClaimValues.ReadOnly, ClaimValues.FullAccess]);
        this.canEditPhysicianFees = this.claimsService.hasClaim(ClaimTypes.PhysicianFees, [ClaimValues.FullAccess]);
        this.canViewPhysicianCost = this.claimsService.hasClaim(ClaimTypes.PhysicianCost, [ClaimValues.ReadOnly, ClaimValues.FullAccess]);
        this.canEditPhysicianCost = this.claimsService.hasClaim(ClaimTypes.PhysicianCost, [ClaimValues.FullAccess]);
        this.individualId = +this.activatedRoute.parent.snapshot.params.individualId;
        this.addressBookId = +this.activatedRoute.parent.parent.snapshot.params.addressBookId;
        this.physician = { ...this.physicianModel };
        this.physicianDetail = { ...this.physicianModel.PhysicianDetails.find((pd) => pd.ImedClaimTypeId === +ClaimTypeEnums.GL) };
        this.physicianFollowupLogs = this.physician.PhysicianFollowupLogs;
        this.form = this.fb.group({
            PhysicianDetail: this.fb.group({}),
            PhysicianFollowupLog: this.fb.group({}),
        });
        forkJoin(
            this.physicianFollowupService.getByIndividualId(this.individualId),
            this.physicianStatusLogService.getByIndividualId(this.individualId),
            this.physicianStatusService.getItems(),
            this.physicianService.getFeeCostTypes(),
            this.userService.getById(this.authService.currentUser.getValue().Id),
        ).subscribe(([followUpLogs, statusLogs, statuses,feeCostTypes, user]) => {
            this.physicianFollowupLogs = followUpLogs;
            this.physicianStatusLogs = statusLogs;
            this.phyisicianStatusTypes = statuses;
            this.filmReviewFeeTypes = feeCostTypes;
            this.filmReviewCostTypes = feeCostTypes;
            this.currentUser = user;
            this.setConfig();
        });
        this.setPhysicianStatusDefaultView(this.physicianModel);
        this.editingComponent.next('');
    }

    setPhysicianStatusDefaultView(physician: IPhysician): void {
        if (physician.GeneralLiability) {
            this.physicianDetail = { ...this.physician.PhysicianDetails.find((pd) => pd.ImedClaimTypeId === +ClaimTypeEnums.GL) };
            this.selectedPhysicianCaseTypeId = ClaimTypeEnums.GL;
        } else if (physician.WorkersComp) {
            this.selectedPhysicianCaseTypeId = ClaimTypeEnums.WC;
            this.physicianDetail = { ...this.physician.PhysicianDetails.find((pd) => pd.ImedClaimTypeId === +ClaimTypeEnums.WC) };
        } else {
            this.selectedPhysicianCaseTypeId = ClaimTypeEnums.Auto;
            this.physicianDetail = { ...this.physician.PhysicianDetails.find((pd) => pd.ImedClaimTypeId === +ClaimTypeEnums.Auto) };
        }
    }

    ngOnChanges({ physicianModel: { currentValue } }): void {
        this.physician = { ...currentValue };
        this.physicianDetail = { ...this.physician.PhysicianDetails.find((pd) => pd.ImedClaimTypeId === this.selectedPhysicianCaseTypeId) };
        this.setPhysicianStatusDefaultView(this.physician);
        this.setConfig();
    }

    getFollowUpNotesBySelectedCaseType(): string {
        if (this.physicianFollowupLogs) {
            const physicianCaseTypeLogs = this.physicianFollowupLogs.filter((pl) => pl.ImedClaimTypeId === this.selectedPhysicianCaseTypeId);
            if (physicianCaseTypeLogs) {
                return physicianCaseTypeLogs[0]?.FollowupNotes;
            }
        }
        return '';
    }

    getFollowUpDateBySelectedCaseType(): Date {
        if (this.physicianFollowupLogs) {
            const physicianCaseTypeLogs = this.physicianFollowupLogs.filter((pl) => pl.ImedClaimTypeId === this.selectedPhysicianCaseTypeId);
            if (physicianCaseTypeLogs) {
                return physicianCaseTypeLogs[0]?.FollowupDate;
            }
        }
        return null;
    }

    setConfig(): void {
        this.selectedPhysicianStatus = this.physicianDetail.PhysicianStatusId;
        const viewAndFormPhysicianControl = new PhysicianDetailDynamicControlsPartial(
            this.physicianDetail,
            {
                physicianStatuses: this.phyisicianStatusTypes,
                filmReviewFeeTypes:this.filmReviewCostTypes,
                filmReviewCostTypes: this.filmReviewFeeTypes,
            },
            this.canEditPhysicianFees,
            this.canEditPhysicianCost,
        );

        this.physicianStatusControls = viewAndFormPhysicianControl.Form;
        this.physicianStatusControlsView = viewAndFormPhysicianControl.View;
        this.physicianStatusLog = { ...emptyPhysicianStatusLog };
        this.physicianFollowupLog = { ...emptyPhysicianFollowupLog };

        const viewAndFormPhysicianFollowupControl = new PhysicianFollowupLogDynamicControlsPartial(this.physicianFollowupLog);

        this.physicianFollowupControls = viewAndFormPhysicianFollowupControl.Form;
        this.physicianFollowupControlsView = viewAndFormPhysicianFollowupControl.View;

        this.setCurrentDate();
        this.formReady = true;
    }

    setCurrentDate(): void {
         this.cdr.detectChanges();
        if (!this.physicianStatusControls.ImeExamConsultationDate.value) {
            this.physicianStatusControls.ImeExamConsultationDate.value = new Date();
        }

        if (!this.physicianStatusControls.NoShowDate.value) {
            this.physicianStatusControls.NoShowDate.value = new Date();
        }

        if (!this.physicianStatusControls.HourlyDate.value) {
            this.physicianStatusControls.HourlyDate.value = new Date();
        }

        if (!this.physicianStatusControls.FilmReviewDate.value) {
            this.physicianStatusControls.FilmReviewDate.value = new Date();
        }

        if (!this.physicianStatusControls.AdditionalDate.value) {
            this.physicianStatusControls.AdditionalDate.value = new Date();
        }

        if (!this.physicianStatusControls.LicenseVerificationDate.value) {
            this.physicianStatusControls.LicenseVerificationDate.value = new Date();
        }

        if (!this.physicianStatusControls.InactiveDate.value) {
            this.physicianStatusControls.InactiveDate.value = new Date();
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    updatePhysicianModel(imedClaimTypeId: number): void {
        this.physicianDetail = { ...this.physicianModel.PhysicianDetails.find((pd) => pd.ImedClaimTypeId === imedClaimTypeId) };
        this.selectedPhysicianCaseTypeId = imedClaimTypeId;
        this.setConfig();
    }

    setDisabled(imedClaimTypeId: number): boolean {
        if (imedClaimTypeId === +ClaimTypeEnums.GL && this.physicianModel.GeneralLiability) {
            return false;
        }
        if (imedClaimTypeId === +ClaimTypeEnums.Auto && this.physicianModel.Auto) {
            return false;
        }
        if (imedClaimTypeId === +ClaimTypeEnums.WC && this.physicianModel.WorkersComp) {
            return false;
        }
        return true;
    }

    setActive(imedClaimTypeId: number): boolean {
        if (imedClaimTypeId === this.selectedPhysicianCaseTypeId) {
            return true;
        }
        return false;
    }

    formSubmitted(): void {
        if (
            this.form.controls.PhysicianDetail.valid &&
            (!this.isOfType(this.physicianStatusesIdEnums.Introductory_Letter_Sent) || this.form.controls.PhysicianFollowupLog.valid)
        ) {
            const prevStatusId = this.physicianDetail.PhysicianStatusId;
            Object.assign(this.physicianDetail, this.form.controls.PhysicianDetail.value);
            if (this.isOfType(this.physicianStatusesIdEnums.Introductory_Letter_Sent)) {
                this.physicianFollowupLog = { ...this.form.controls.PhysicianFollowupLog.value };
                this.physicianFollowupLog.PhysicianId = this.physician.Id;
                this.physicianFollowupLog.AddedById = this.currentUser.Id;
                this.physicianFollowupLog.AddedDate = new Date();
                this.physicianFollowupLog.ImedClaimTypeId = this.physicianDetail.ImedClaimTypeId;
                this.physician.PhysicianFollowupLogs.push(this.physicianFollowupLog);
            }

            if (this.isUnderActiveOrTrialStatuses()) {
                if (!this.isAllRequiredFieldPresent()) {
                    this.notificationsService.error(
                        'Current Physician cannot be set to Trial Period or Trial Period - Pending License Verification or Active or Active - Pending Re-verification or By Request Only.\n Please make sure CV received, Fee schedule Received, License Verification AND License Expiration Date fields have ALL been filled in',
                    );
                    return;
                }
            }

            if (this.isUnderActiveOrTrialStatuses()) {
                if (!this.isLicenseVerificationDateRequired()) {
                    this.notificationsService.error(
                        'Current Physician cannot be set to Trial Period or Trial Period - Pending License Verification or Active or Active - Pending Re-verification or By Request Only.\nPlease make sure License Verification Date fields have been filled in',
                    );
                    return;
                }
            }

            if (this.physicianDetail?.FilmReviewFee > 0 && !this.physicianDetail?.FilmReviewFeeTypeId) {
                this.notificationsService.error('Film Review Fee Type is required.');
                return;
            }

            if (this.physicianDetail?.FilmReviewCost > 0 && !this.physicianDetail?.FilmReviewCostTypeId) {
                this.notificationsService.error('Film Review Cost Type is required.');
                return;
            }

            this.physicianDetail.ModifiedById = this.authService.currentUser.getValue().Id;
            this.physicianDetail.Physician = { ...this.physician };
            this.physicianDetail.Physician.PhysicianDetails = null;
            this.physicianService.updatePhysicianStatus(this.physicianDetail).subscribe(
                (response) => {
                    this.isEditing = false;
                    this.success();
                    this.physicianService.emitChange(this.physician);
                    if (response.EmailTemplates && response.EmailTemplates.length > 0) {
                        this.handleEmailTemplate(response.EmailTemplates as IEmailTemplate[]);
                    }
                    // eslint-disable-next-line @typescript-eslint/no-for-in-array
                    for (const key in this.physician.PhysicianDetails) {
                        if (this.physician.PhysicianDetails[key].Id === this.physicianDetail.Id) {
                            this.physician.PhysicianDetails[key] = this.physicianDetail;
                        }
                    }
                },
                () => null,
                (complete) => {
                    if (this.physicianDetail.PhysicianStatusId !== prevStatusId) {
                        this.createPhysicianStatusLog();
                    }
                    if (this.isOfType(this.physicianStatusesIdEnums.Introductory_Letter_Sent)) {
                        this.createFollowUpLog();
                    }
                    this.setConfig();
                },
            );
        } else {
            this.error();
        }
    }

    createFollowUpLog(): void {
        this.physicianFollowupLog.User = this.currentUser;
        this.physicianFollowupLog.ImedClaimType = {
            Name: ClaimTypeNames[ClaimTypeEnums[this.selectedPhysicianCaseTypeId]],
            Id: this.selectedPhysicianCaseTypeId,
        };
        this.physicianFollowupLogs.unshift(this.physicianFollowupLog);
    }

    createPhysicianStatusLog(): void {
        this.physicianStatusLog.User = this.currentUser;
        this.physicianStatusLog.PhysicianDetail = { ...this.physicianDetail };
        this.physicianStatusLog.PhysicianDetail.ImedClaimType = {
            Name: ClaimTypeNames[ClaimTypeEnums[this.selectedPhysicianCaseTypeId]],
            Id: this.selectedPhysicianCaseTypeId,
        };
        this.physicianStatusLog.ChangedDate = new Date();
        this.physicianStatusLog.PhysicianStatusId = this.physicianDetail.PhysicianStatusId;
        this.physicianStatusLog.ChangedById = this.currentUser.Id;
        this.physicianStatusLog.PhysicianDetailId = this.physicianDetail.Id;
        this.physicianStatusLog.PhysicianStatus = this.phyisicianStatusTypes.find((x) => x.Id === this.physicianStatusLog.PhysicianStatusId);
        this.physicianStatusLogs.unshift(this.physicianStatusLog);
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    physicianStatusChange(doot: any): void {
        this.selectedPhysicianStatus = this.form.controls.PhysicianDetail.value.PhysicianStatusId;
    }

    filmReviewFeeTypeChange(doot: any): void {
        this.selectedFilmReviewFeeType = this.form.controls.PhysicianDetail.value.FilmReviewFeeTypeId;
    }

    filmReviewCostTypeChange(doot: any): void {
        this.selectedFilmReviewCostType = this.form.controls.PhysicianDetail.value.FilmReviewCostTypeId;
    }

    isOfType(typeId: number): boolean {
        return this.form.controls.PhysicianDetail.value.PhysicianStatusId === typeId;
    }

    isAllRequiredFieldPresent(): boolean {
        if (this.physicianDetail.CvReceivedDate && this.physicianDetail.FeeScheduleReceivedDate && this.physicianDetail.LicenseVerificationDate && this.physicianDetail.LicenseExpirationDate) {
            return true;
        }
        return false;
    }
    isLicenseVerificationDateRequired(): boolean {
        if (this.physicianDetail.LicenseVerificationDate) {
            return true;
        }
        return false;
    }
    seeAllFollowUps(): void {
        void this.router.navigate([`address-books/${this.addressBookId}/individuals/${this.individualId}/physician-followups/`]);
    }

    seeAllStatuses(): void {
        void this.router.navigate([`address-books/${this.addressBookId}/individuals/${this.individualId}/physician-statuses/`]);
    }

    handleEmailTemplate(templates: IEmailTemplate[]): void {
        this.emailComponent.emailTemplates = templates;
        this.emailComponent.individualId = this.individualId;
        this.emailComponent.showEmailForm();
    }

    updateEmailComponent(): void {
        this.showEmailControl = false;
        setTimeout(() => {
            this.showEmailControl = true;
        });
    }

    setTravelAreasNotes(willingToTravel: string): void {
        if (willingToTravel) {
            this.form.get('PhysicianDetail.TravelAreasOutsideOfVenue').enable();
        } else {
            this.form.get('PhysicianDetail.TravelAreasOutsideOfVenue').disable();
        }
    }

    isUnderActiveOrTrialStatuses(): boolean {
        return (
            this.isOfType(this.physicianStatusesIdEnums.Active) ||
            this.isOfType(this.physicianStatusesIdEnums.Trial_Period) ||
            this.isOfType(this.physicianStatusesIdEnums.Active_Pending_License_Verification) ||
            this.isOfType(this.physicianStatusesIdEnums.ByRequestOnly) ||
            this.isOfType(this.physicianStatusesIdEnums.Trial_Period_Pending_License_Verification)
        );
    }
}
