import { DynamicField, DynamicFieldType, IDynamicFieldType, DynamicFieldTypes, IDynamicField } from '@mt-ng2/dynamic-form';
import { ImedClaimServiceService } from './../../imedclaimservice.service';
import { ImedClaimSubServiceService } from '@app-shared/services/imed-claim-sub-service.service';
import { ISubService } from '@model/interfaces/sub-service.d';
import { IImedClaimService } from '@model/interfaces/imed-claim-service.d';
import { ChangeDetectorRef, Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthService } from '@mt-ng2/auth-module';
import { finalize } from 'rxjs/operators';
import { SubServiceEnums } from '@common/constants/Enums';
import { safeDetectChanges } from '@common/cdr-safety/cdr-safety.library';
import { AbstractControl } from '@angular/forms';
import { IImedClaimSubService } from '@model/interfaces/imed-claim-sub-service';

@Component({
    selector: 'app-imed-claim-sub-services',
    templateUrl: './imed-claim-sub-services.component.html',
})
export class ImedClaimSubServiceComponent implements OnInit, OnDestroy {
    @Input('imedClaimServiceModel') imedClaimServiceModel: IImedClaimService;
    @Input('canEdit') canEdit: boolean;

    doubleClickIsDisabled = false;
    formCreated = false;
    isEditing = false;
    isHovered = false;
    includedServices: ISubService[] = [];
    selectedSubServiceIds: number[] = [];
    hasAdditionalRecords = false;
    hasMultipleInsuranceClaims = false;
    additionalRecordsInfo: string;
    multipleInsuranceClaimsInfo: string;
    subServiceInfo: AbstractControl;

    private _subService;
    @Input('subService')
    get subService(): ISubService[] {
        return this._subService;
    }
    set subService(value: ISubService[]) {
        this._subService = value;
        this.radiologyretrievalSubService = this._subService.find((x: ISubService) => x.Id === +SubServiceEnums.Radiology_Retrieval || x.Id === +SubServiceEnums.Subpoena_Radiology_Retrieval);
        this.additionalrecordsSubService = this._subService.find((x: ISubService) => x.Id === +SubServiceEnums.Additional_Records || x.Id === +SubServiceEnums.Subpoena_Additional_Records);
        this.multipleInsuranceSubService = this._subService.find((x: ISubService) => x.Id === +SubServiceEnums.Multiple_Insurance_Claims || x.Id === +SubServiceEnums.Subpoena_Multiple_Insurance_Claims);
        this.hardCopySubService = this._subService.find((x: ISubService) => x.Id === +SubServiceEnums.Hard_Copy || x.Id === +SubServiceEnums.Subpoena_Hard_Copy);
        this.upsShipping = this._subService.find((x: ISubService) => x.Id === +SubServiceEnums.UPS_Shipping || x.Id === +SubServiceEnums.Subpoena_UPS_Shipping);
        this.certifiedReceipt = this._subService.find((x: ISubService) => x.Id === +SubServiceEnums.Certified_Or_Return_Receipt || x.Id === +SubServiceEnums.Subpoena_Certified_Or_Return_Receipt);
    }

    radiologyretrievalSubService: ISubService;
    additionalrecordsSubService: ISubService;
    multipleInsuranceSubService: ISubService;
    hardCopySubService: ISubService;
    upsShipping: ISubService;
    certifiedReceipt: ISubService;

    constructor(
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private imedClaimSubServiceService: ImedClaimSubServiceService,
        private imedClaimServiceService: ImedClaimServiceService,
    ) { }

    ngOnInit(): void {
        this.createForm();
        this.includedServices = this.imedClaimServiceModel.ImedClaimSubServices.map((x) => x.SubService);

        if (this.selectedSubServiceIds.findIndex((Id: number) => (Id === +SubServiceEnums.Additional_Records || Id === +SubServiceEnums.Subpoena_Additional_Records)) > -1) {
            this.additionalRecordsInfo = this.imedClaimServiceModel.ImedClaimSubServices.find(
                (x: IImedClaimSubService) => x.SubServiceId === +SubServiceEnums.Additional_Records || x.SubServiceId === +SubServiceEnums.Subpoena_Additional_Records,
            ).Info;
            this.hasAdditionalRecords = true;
        }

        if (this.selectedSubServiceIds.findIndex((Id: number) => (Id === +SubServiceEnums.Multiple_Insurance_Claims || Id === +SubServiceEnums.Subpoena_Multiple_Insurance_Claims)) > -1) {
            this.multipleInsuranceClaimsInfo = this.imedClaimServiceModel.ImedClaimSubServices.find(
                (x: IImedClaimSubService) => x.SubServiceId === +SubServiceEnums.Multiple_Insurance_Claims || x.SubServiceId === +SubServiceEnums.Subpoena_Multiple_Insurance_Claims,
            ).Info;
            this.hasMultipleInsuranceClaims = true;
        }
    }

    ngOnDestroy(): void {
        this.cdr.detach();
    }

    createForm(): void {
        this.getControls();
        this.formCreated = true;
        safeDetectChanges(this.cdr);
    }

    getControls(): void {
        this.selectedSubServiceIds = this.imedClaimServiceModel.ImedClaimSubServices.map((imedClaimSubService) => imedClaimSubService.SubServiceId);
    }

    setAdditionalInfo(input: Input): void {
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        this.additionalRecordsInfo = input.toString();
    }

    setMultipleInsuranceInfo(input: Input): void {
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        this.multipleInsuranceClaimsInfo = input.toString();
    }

    createCheckboxForSubService(subService: ISubService): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: subService.Name,
            name: 'SubServiceId',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
            } as IDynamicFieldType),
            value: this.isChecked(subService),
        } as IDynamicField);
    }

    createAdditionalRecordsInfo(): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: 'Info: ',
            name: 'additionalRecordsInfo',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
            } as IDynamicFieldType),
            value: this.additionalRecordsInfo,
        } as IDynamicField);
    }

    createMultipleInsuranceClaimsInfo(): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: 'Info: ',
            name: 'multipleInsuranceClaimsInfo',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
            } as IDynamicFieldType),
            value: this.multipleInsuranceClaimsInfo,
        } as IDynamicField);
    }

    isChecked(subService: ISubService): boolean {
        return this.selectedSubServiceIds.findIndex((item) => item === subService.Id) > -1;
    }

    checkSubService(serviceTypeObject: ISubService, checked: boolean): void {
        if (checked) {
            this.includedServices.push(serviceTypeObject);
        } else {
            const i = this.includedServices.findIndex((item) => item.Id === serviceTypeObject.Id);
            this.includedServices.splice(i, 1);
        }
    }

    formSubmitted(): void {
        this.formatDataForSave();
        this.imedClaimSubServiceService
            .saveSubServices(this.imedClaimServiceModel)            .subscribe(() => {
                this.notificationsService.success('Sub Services Saved Successfully');
                this.createForm();
                this.imedClaimServiceService.emitChange(this.imedClaimServiceModel);
                this.isEditing = false;
            });
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    getSubServicName(subServiceId: number): string {
        return this.includedServices.find((x) => x.Id === subServiceId).Name;
    }

    formatDataForSave(): void {
        Object.assign(this.imedClaimServiceModel, this.includedServices);
        this.mergeSubServices(this.includedServices.map((x) => x.Id));
    }

    mergeSubServices(subServiceIds: number[]): void {
        if (subServiceIds.length > 0) {
            this.imedClaimServiceModel.ImedClaimSubServices = this.imedClaimServiceModel.ImedClaimSubServices.filter((imedClaimSubServices) => {
                return subServiceIds.indexOf(imedClaimSubServices.Id) > -1;
            });
            subServiceIds.forEach((id) => {
                if (this.imedClaimServiceModel.ImedClaimSubServices.findIndex((subService) => subService.Id === id) === -1) {
                    this.imedClaimServiceModel.ImedClaimSubServices.push({
                        Archived: false,
                        CreatedById: this.authService.currentUser.getValue().Id,
                        DateCreated: new Date(),
                        Id: 0,
                        ImedClaimServiceId: this.imedClaimServiceModel.Id,
                        Info:
                            id === +SubServiceEnums.Multiple_Insurance_Claims
                                ? this.multipleInsuranceClaimsInfo
                                : id === +SubServiceEnums.Additional_Records
                                    ? this.additionalRecordsInfo
                                    : null,
                        SubServiceId: id,
                    });
                }
            });
        } else {
            this.imedClaimServiceModel.ImedClaimSubServices = [];
        }
    }
}
