import { Component, OnInit, Output, EventEmitter, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { FaxEmailService } from './fax-email.service';
import { IFilmRetrievalExtrasDTO } from '@model/interfaces/custom/film-retrieval-extras-dto';
import { ImedClaimServiceService } from '../../imed-claims/services/imedclaimservice.service';
import { IImedClaimService } from '@model/partials/imed-claim-service.partial';
import { Router } from '@angular/router';
import { IViewGetServicesForList } from '@model/interfaces/custom/view-get-services-for-list';
import { IDocumentGenerationDTO } from '@model/interfaces/custom/document-generation-dto';
import { finalize } from 'rxjs/operators';
import { ICommunicationRequest } from '@model/interfaces/custom/communication-request';
import { CommunicationLogService } from '../services/communication-log.service';
import { OverlayPositionBuilder, Overlay } from '@angular/cdk/overlay';
import { BaseAttachmentsService } from '../attachments/base-attachments.service';
import { AttachmentTypeService } from '../attachments/attachment-type.service';
import { BaseFaxEmailComponent } from '@common/base-fax-email/base-fax-email.component';
import { BaseFaxEmailHelperService } from '@common/base-fax-email/base-fax-email-helper.service';
import { FaxEmailEntityTypeEnums, FaxEmailEnum } from '@common/constants/Enums';
import { NameTypeEnum } from '@model/interfaces/custom/name';
import { UserRoleService } from '../../user-roles/user-role.service';
import { AuthService } from '@mt-ng2/auth-module';
import { IUserRole } from '@model/interfaces/user-role';

import { IViewGetFollowUpsForList } from '@model/interfaces/view-get-follow-ups-for-list';

export interface IAppFaxEmailComponentApi {
    close: () => void;
    createEmail: (entityTypeId?: FaxEmailEntityTypeEnums) => void;
    setSelectedType: (type: FaxEmailEnum) => void;
    show: () => void;
    validateServiceDateOfInjuryFromList: (service: IViewGetServicesForList) => void;
}

@Component({
    selector: 'app-fax-email',
    styleUrls: ['../base-fax-email/base-fax-email.component.less'],
    templateUrl: './fax-email-component.html',
})
export class FaxEmailComponent extends BaseFaxEmailComponent implements OnInit, OnDestroy {
    filmRetrievalExtras: IFilmRetrievalExtrasDTO = { recordsType: '', notes: '' };
    isFilmRetrievalLabel: boolean;
    dateOfInjuryMissing = false;
    showSendAsAttachment = false;
    sendAsAttachment = false;
    userRole: IUserRole;

    missingFields: string[] = [];

    // public Editor = Editor;

    get faxNumberIsValid(): boolean {
        return this.faxNumber !== undefined && this.faxNumber !== null && this.faxNumber !== '';
    }

    get NameFirstLast(): string {
        return this.name.nameType === NameTypeEnum.Individual ? `${this.name.firstName} ${this.name.lastName}` : `${this.name.facilityName}`;
    }
    get NameLastFirst(): string {
        return this.name.nameType === NameTypeEnum.Individual ? `${this.name.lastName}, ${this.name.firstName}` : `${this.name.facilityName}`;
    }

    @Input() entityTypeId: FaxEmailEntityTypeEnums;

    @Output() ready = new EventEmitter<IAppFaxEmailComponentApi>();

    constructor(
        protected notificationsService: NotificationsService,
        protected faxEmailService: FaxEmailService,
        private communicationLogService: CommunicationLogService,
        private imedClaimServiceService: ImedClaimServiceService,
        private route: Router,
        protected overlay: Overlay,
        protected overlayPositionBuilder: OverlayPositionBuilder,
        protected cdr: ChangeDetectorRef,
        private baseAttachmentService: BaseAttachmentsService,
        private attachmentTypeService: AttachmentTypeService,
        protected baseFaxEmailHelperService: BaseFaxEmailHelperService,
        private userRoleService: UserRoleService,
        private authService: AuthService,
    ) {
        super(cdr, baseFaxEmailHelperService, notificationsService, overlay, overlayPositionBuilder);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.setInitialContent();

        this.ready.emit({
            close: this.closeFaxEmailModal.bind(this),
            createEmail: this.createEmail.bind(this),
            setSelectedType: this.setSelectedType.bind(this),
            show: this.showModal.bind(this),
            validateServiceDateOfInjuryFromList: this.validateServiceDateOfInjuryFromList.bind(this),
        });
    }

    setInitialContent(): void {
        this.isFilmRetrievalLabel = this.entityTypeId === FaxEmailEntityTypeEnums.FilmRetrievalLabel ? true : false;
        if (this.route.url.includes('/services') && !this.route.url.endsWith('/appointments')) {
            this.imedClaimServiceService.getDefaultContactEmails(this.imedclaimServiceId).subscribe((defaultEmailString) => {
                this.emailId = defaultEmailString;
                if (this.fromServicesListPage) {
                    const entity: IViewGetServicesForList = this.entity;
                    this.missingFields = this.baseFaxEmailHelperService.validateServiceFromList(entity);
                    this.claimNumber = entity.ClaimNumber;
                    this.fileNumber = entity.FileNumber;
                } else {
                    const entity: IImedClaimService = this.entity;
                    this.claimNumber = entity.ImedClaim.ClaimNumber ? entity.ImedClaim.ClaimNumber : '';
                    this.fileNumber = entity.ImedClaim.FileNumber ? entity.ImedClaim.FileNumber : '';
                    this.validateServiceFromDetail(entity);
                    this.createEmail();
                    this.subscriptions.add(
                        this.imedClaimServiceService.changeEmitted$.subscribe((service) => {
                            this.imedClaimService = service;
                            this.validateServiceFromDetail(this.imedClaimService);
                        }),
                    );
                }
            });
        }

        if (this.route.url.includes('/appointments')) {
            this.validateServiceFromDetail(this.entity.ImedClaimService as IImedClaimService);
            this.claimNumber = this.entity.ImedClaimService.ImedClaim.ClaimNumber ? this.entity.ImedClaimService.ImedClaim.ClaimNumber : '';
            this.fileNumber = this.entity.ImedClaimService.ImedClaim.FileNumber ? this.entity.ImedClaimService.ImedClaim.FileNumber : '';
        }

        if (this.route.url.includes('/individuals')) {
            this.createEmail();
        }

        const currentUserId = this.authService.currentUser.getValue().Id;
        this.userRoleService.getRoleByUserId(currentUserId).subscribe((response) => {
            this.userRole = response;
            this.showSendAsAttachment = this.userRole.AllowToSendAsAttachment;
            if (this.showSendAsAttachment) {
                this.sendAsAttachment = true;
            }
        });
    }

    validateServiceDateOfInjuryFromList(service: IViewGetServicesForList): void {
        if (
            !service.DateOfAllegedAccidentOrInjury &&
            this.baseFaxEmailHelperService.noDateOfInjuryValidationServices.indexOf(this.entityTypeId) < 0
        ) {
            this.dateOfInjuryMissing = true;
        }
    }

    validateServiceFromDetail(service: IImedClaimService): void {
        if (
            !service.ImedClaim.DateofAllegedAccidentOrInjury &&
            this.baseFaxEmailHelperService.noDateOfInjuryValidationServices.indexOf(this.entityTypeId) < 0
        ) {
            this.dateOfInjuryMissing = true;
        }
        this.missingFields = this.baseFaxEmailHelperService.validateServiceFromDetail(service);
    }

    createEmail(entityTypeId?: FaxEmailEntityTypeEnums): void {
        if (entityTypeId) {
            this.entityTypeId = entityTypeId;
        }
        const docType = this.baseFaxEmailHelperService.getEntityName(this.entityTypeId);
        if (this.entityTypeId === FaxEmailEntityTypeEnums.IntroLetter || this.entityTypeId === FaxEmailEntityTypeEnums.WelcomeLetter) {
            this.emailSubject = `${docType} for ${this.NameFirstLast}`;
        } else if (this.entityTypeId === FaxEmailEntityTypeEnums.AdditionalRecordsRetrieved) {
            const toName =
                (this.entity.AddressBook && this.entity.AddressBook.FacilityName + ' -') ||
                (this.entity.physician ? `${this.entity.physician.FirstName} ${this.entity.physician.LastName} -` : '');
            this.emailSubject = `IMEDVIEW: ${this.NameLastFirst} - ${toName} Additional Records`;
        } else {
            this.emailSubject = `IMEDVIEW: ${this.NameLastFirst} - ${this.claimNumber} ${this.fileNumber}`;
        }

        if (this.entityTypeId === FaxEmailEntityTypeEnums.RecordsRetrievalComplex) {
            this.emailSubject += ` - ${this.imedclaimServiceId}`;
            this.emailBody =
                'A request for medical / employment / school records customarily includes PHI and thus cannot be forwarded via PDF attachment in ' +
                'order to maintain HIPAA compliance.' +
                '<br/><br/>' +
                'The link shown below will open simply in another browser tab to enable you to review and print the request on the subject claimant. ' +
                'It does not require a log-in or password, and will not bring you to another site or program. No executable files are necessary.' +
                '<br/><br/>' +
                '[ATTACHMENT LINK]' +
                '<br/>' +
                'If you have any questions or concerns, please contact us at 888-747-1090 or by e-mail at retrievals@imedview.com.' +
                '<br/>' +
                '[SIGNATURE BLOCK USER]';
        } else {
            this.emailBody = `Please see attached ${docType} for ${this.NameFirstLast}.`;
            if (this.entityTypeId === FaxEmailEntityTypeEnums.IntroLetter) {
                this.emailBody +=
                    ' A fillable version of the required W9 form is available at <a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf">https://www.irs.gov/pub/irs-pdf/fw9.pdf.</a>';
            }
            this.emailBody += '<br/><br />[ATTACHMENT LINK]';
            this.emailBody += '<br/>[SIGNATURE BLOCK USER]';
        }
    }

    closeFaxEmailModal(): void {
        this.modalWrapperApi.close();
        this.closeModal.emit();
    }

    SendFax(): void {
        if (!this.faxNumberIsValid) {
            this.notificationsService.error('Fax number is required.');
            setTimeout(() => (this.doubleClickIsDisabled = false));
            return;
        }

        const emailDTO: ICommunicationRequest = {
            AppointmentId: this.entity && this.entity.AppointmentDateTime && this.entity.Timezone ? this.entity.Id : null,
            Body: null,
            ImedClaimServiceId:
                this.entity && this.entity.ImedClaimServiceId
                    ? this.entity.ImedClaimServiceId
                    : this.entity && !this.entity.IndividualId
                    ? this.entity.Id
                    : null,
            IndividualId: this.entity && this.entity.IndividualId ? this.entity.IndividualId : null,
            Recepient: null,
            Subject: null,
        };

        this.faxEmailService
            .faxDocuments(this.baseFaxEmailHelperService.getEntityPath(this.entityTypeId), this.createDocDTO(), emailDTO, this.faxNumber)
            .pipe(
                finalize(() => {

                    this.isProcessing = false;
                }),
            )
            .subscribe(
                () => {
                    this.communicationLogService.notifySubscribersOfNewLogs();
                    this.handleFaxEmailResponse();
                },
                () => {
                    this.notificationsService.error('Sending Fax failed.');
                    this.selectedType = FaxEmailEnum.EMAIL;

                },
            );
    }

    SendEmail(): void {
        this.setInvalidEmails();
        this.setInvalidCCEmails();
        this.setInvalidBCCEmails();
        if (!this.invalidEmails && !this.invalidCCEmails && !this.invalidBCCEmails) {
            this.isProcessing = true;
            const emailDTO: ICommunicationRequest = this.buildEmailDto();
            this.faxEmailService
                .emailDocumentsWithConditionalAttachments(this.baseFaxEmailHelperService.getEntityPath(this.entityTypeId), this.createDocDTO(), emailDTO)
                .pipe(
                    finalize(() => {

                        this.isProcessing = false;
                    }),
                )
                .subscribe(
                    () => {
                        this.communicationLogService.notifySubscribersOfNewLogs();
                        this.handleDocumentEmailResponse();
                    },
                    () => {
                        this.notificationsService.error('Sending Email failed.');
                        this.selectedType = FaxEmailEnum.EMAIL;
                    },
                );
        } else {
            this.notificationsService.error('A valid email address must be provided.');
            setTimeout(() => {
                this.isProcessing = false;
            });
        }
    }

    uploadPdfAndHandleResponse(callback: (document) => void, isFax: boolean): void {
        this.setInvalidEmails();
        this.setInvalidCCEmails();
        this.setInvalidBCCEmails();
        if (
            this.fileToUpload &&
            ((!isFax && !this.invalidEmails && !this.invalidCCEmails && !this.invalidBCCEmails) || (isFax && this.faxNumberIsValid))
        ) {
            this.isProcessing = true;
            // We currently only use the fax email control for services and physicians, we will need to find a
            // better way to get the entity type name if we expand the use of this component
            const entityType = this.imedclaimServiceId ? 'imed-claim-service' : 'physician';
            const entityId = this.baseFaxEmailHelperService.getEntityPath(this.entityTypeId).includes('appointment')
                ? this.imedclaimServiceId
                : this.entityId;
            this.baseAttachmentService
                .createAndUpload(
                    entityType,
                    entityId,
                    this.fileToUpload,
                    this.baseFaxEmailHelperService.letterTypeAttachmentTypeIdMap.get(this.entityTypeId),
                    0,
                )
                .pipe(
                    finalize(() => {

                        this.isProcessing = false;
                    }),
                )
                .subscribe((document) => {
                    const emailDTO: ICommunicationRequest = this.buildEmailDto();
                    emailDTO.DocumentId = document.Id;
                    callback.call(this, emailDTO);
                });
        } else {
            if (!isFax && (this.invalidEmails || this.invalidCCEmails || this.invalidBCCEmails)) {
                this.notificationsService.error('Please check emails in recipients, CC, and BCC fields. An invalid email(s) present.');
            } else if (isFax && !this.faxNumberIsValid) {
                this.notificationsService.error('Fax number is required.');
            } else {
                this.notificationsService.error('Please upload a PDF.');
            }
            setTimeout(() => {
                this.isProcessing = false;
            });
        }
    }

    buildEmailDto(): ICommunicationRequest {
        return {
            // Only set appointment Id if entity has AppointmentDateTime and Timezone property.
            // We need to check for Timezone because the ImedClaimService entity from the list page also has AppointmentDateTime property
            AppointmentId: this.entity && this.entity.AppointmentDateTime && this.entity.Timezone ? this.entity.Id : null,
            BCC: this.bccEmails,
            Body: this.emailBody,
            CC: this.ccEmails,
            // Set ImedClaimService id if entity is from service list page, ime appointment list page, or service detail page
            // Services don't have individiualId, the !this.entity.IndividualId prevents this property from being set on physician letters
            ImedClaimServiceId:
                this.entity && this.entity.ImedClaimServiceId
                    ? this.entity.ImedClaimServiceId
                    : this.entity && !this.entity.IndividualId
                    ? this.entity.Id
                    : null,
            ImedClaimId:
                this.entity && this.entity.ImedClaimId
                    ? this.entity.ImedClaimId
                    : null,
            IndividualId: this.entity && this.entity.IndividualId ? this.entity.IndividualId : null,
            Recepient: this.emailId,
            SendAsAnAttachment: this.sendAsAttachment,
            Subject: this.emailSubject,
        };
    }

    sendEmailAfterUpload(emailDTO: ICommunicationRequest): void {
        this.faxEmailService
            .emailDocumentsWithConditionalAttachments(this.baseFaxEmailHelperService.getEntityPath(this.entityTypeId), this.createDocDTO(), emailDTO)
            .pipe(
                finalize(() => {

                    this.isProcessing = false;
                }),
            )
            .subscribe(
                () => {
                    this.communicationLogService.notifySubscribersOfNewLogs();
                    this.handleDocumentEmailResponse();
                    this.fileToUpload = null;
                },
                () => {
                    this.notificationsService.error('Sending Email failed.');
                    this.selectedType = FaxEmailEnum.EMAIL;
                },
            );
    }

    sendFaxAfterUpload(emailDTO: ICommunicationRequest): void {
        this.faxEmailService
            .faxDocumentsWithAttachment(this.baseFaxEmailHelperService.getEntityPath(this.entityTypeId), emailDTO, this.faxNumber)
            .pipe(
                finalize(() => {

                    this.isProcessing = false;
                }),
            )
            .subscribe(
                () => {
                    this.communicationLogService.notifySubscribersOfNewLogs();
                    this.handleFaxEmailResponse();
                    this.fileToUpload = null;
                },
                () => {
                    this.notificationsService.error('Sending Fax failed.');
                    this.selectedType = FaxEmailEnum.EMAIL;
                },
            );
    }

    downloadDocument = (): void => {
        this.isProcessing = true;

        this.faxEmailService
            .generateDocuments(this.baseFaxEmailHelperService.getEntityPath(this.entityTypeId), this.createDocDTO())
            .pipe(
                finalize(() => {

                    this.isProcessing = false;
                }),
            )
            .subscribe(
                (data) => {
                    this.handleDocumentDownloadResponse(data.body as Blob, data.headers.get('X-File-Name') as string);
                    this.modalWrapperApi.close();
                    this.download.emit();
                    this.closeModal.emit();
                },
                () => {
                    this.notificationsService.error('Document Download failed.');
                    this.selectedType = FaxEmailEnum.EMAIL;

                },
            );
    }

    downloadAsDocX(): void {
        this.isProcessing = true;
        this.faxEmailService
            .generateDocumentsAsDocX(this.baseFaxEmailHelperService.getEntityPath(this.entityTypeId), this.createDocDTO())
            .pipe(
                finalize(() => {

                    this.isProcessing = false;
                }),
            )
            .subscribe(
                (data) => {
                    this.handleDocumentDownloadResponse(data.body as Blob, data.headers.get('X-File-Name') as string);
                },
                () => {
                    this.notificationsService.error('Document Download failed.');

                    this.isProcessing = false;
                },
            );
    }

    createDocDTO(): IDocumentGenerationDTO {
        return { EntityId: this.entityId, Extras: this.filmRetrievalExtras };
    }

    handleDocumentEmailResponse(): void {
        this.modalWrapperApi.close();
        this.notificationsService.success('Email sent successfully');
        this.createEmail(this.entityTypeId);
        this.selectedType = FaxEmailEnum.EMAIL;
        this.sendEmail.emit();
        this.closeModal.emit();
    }

    handleFaxEmailResponse(): void {
        this.sendFax.emit();
        this.faxNumber = '';
        this.modalWrapperApi.close();
        this.notificationsService.success('Fax sent successfully');
        this.selectedType = FaxEmailEnum.EMAIL;
        this.closeModal.emit();
    }
}
