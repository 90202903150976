import { ImeServiceDynamicControls, IImeServiceDynamicControlsParameters } from './../form-controls/ime-service.form-controls';
import { IImeService } from './../interfaces/ime-service.d';
import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { IImeServicePartial } from './ime-service';
import { ServiceIdEnums } from '@common/constants/Enums';

export class ImeServiceDynamicControlsPartial extends ImeServiceDynamicControls {
    constructor(imeservice?: IImeService & IImeServicePartial, additionalParameters?: IImeServiceDynamicControlsParameters, latestAppointmentDate?: Date) {
        super(imeservice, additionalParameters);

        (<DynamicField>this.Form.PatientPreviouslyTreatedByPhysician).label = 'Has patient ever been treated by indicated expert / physician?';
        (<DynamicField>this.Form.ConflictOfInterestApproved).label = 'Has client approved possible conflict of interest?';

        (<DynamicLabel>this.View.PatientPreviouslyTreatedByPhysician).label = 'Has patient ever been treated with indicated expert / physician? ';
        (<DynamicLabel>this.View.ConflictOfInterestApproved).label = 'Has client approved possible conflict of interest? ';

        (<DynamicField>this.Form.BackgroundInvestigationScheduledDate).labelHtml = '<strong>Report due by</strong>';
        (<DynamicField>this.Form.BackgroundInvestigationNotes).labelHtml = '<strong>To include</strong>';

        (<DynamicLabel>this.View.BackgroundInvestigationScheduledDate).label = 'Report due by';
        (<DynamicLabel>this.View.BackgroundInvestigationNotes).label = 'To include';

        (<DynamicField>this.Form.LanguageTypeId).labelHtml = '<strong>Language Requested:</strong>';
        (<DynamicLabel>this.View.LanguageTypeId).label = 'Language Requested';
        if (imeservice.ImedClaimService_ImedClaimServiceId?.ServiceId === ServiceIdEnums.IME_WC) {
           (<DynamicField>this.Form.LanguageTypeId).setRequired(true);
        }
        (<DynamicField>this.Form.SurveillanceNumHoursAuthorized).labelHtml = '<strong>Number of hours authorized:</strong>';
        (<DynamicLabel>this.View.SurveillanceNumHoursAuthorized).label = 'Number of hours authorized';

        (<DynamicField>this.Form.SurveillanceNumOperativesAuthorized).labelHtml = '<strong>Number of operatives authorized:</strong>';
        (<DynamicLabel>this.View.SurveillanceNumOperativesAuthorized).label = 'Number of operatives authorized';
        this.fillInDates(latestAppointmentDate, imeservice);
    }

    private fillInDates(latestAppointmentDate?: Date, imeservice?: IImeService): void {
        const defaultDate = latestAppointmentDate || null;
        (<DynamicField>this.Form.BackgroundInvestigationScheduledDate).value =
            (imeservice && imeservice.BackgroundInvestigationScheduledDate) || defaultDate;
        (<DynamicField>this.Form.SurveillanceScheduledDate).value = (imeservice && imeservice.SurveillanceScheduledDate) || defaultDate;
        (<DynamicField>this.Form.TranslationServicesScheduledDate).value = (imeservice && imeservice.TranslationServicesScheduledDate) || defaultDate;
        (<DynamicField>this.Form.TransportationServicesScheduledDate).value =
            (imeservice && imeservice.TransportationServicesScheduledDate) || defaultDate;
    }
}
