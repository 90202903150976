import { ChangeDetectorRef, Component, OnInit, Input, ViewRef, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthService } from '@mt-ng2/auth-module';
import { IPhysician } from '@model/interfaces/physician';
import { IndividualService } from '../../individual.service';
import { IIndividual } from '@model/interfaces/individual';
import { PhysicianService } from '../physician.service';
import { Subscription } from 'rxjs';
import { safeDetectChanges } from '../../../../common/cdr-safety/cdr-safety.library';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IPhysicianProfileAssociation } from '@model/interfaces/physician-profile-association';
import { PhysicianProfileAssociationDynamicControlsPartial } from '@model/partials/physician-linked-duplicates-partial.form-controls';
import { DynamicField } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'linked-physicians',
    templateUrl: './linked-physician.component.html',
})
export class LinkedPhysiciansComponent implements OnInit {
    @Input('individual') individual: IIndividual;
    @Input('canEdit') canEdit: boolean;

    isHovered: boolean;

    // abstract controls
    abstractPhysicianProfileAssociationControls: any;
    abstractPhysicianProfileAssociationControlsView: any;

    physicianProfileAssociationForm: UntypedFormGroup;
    physicians: IPhysician[] = [];
    physician: IPhysician;
    doubleClickIsDisabled = false;
    formCreated = false;
    isEditing = false;
    physicianSubscription: Subscription;

    placeholder = 'Select physician(s)';
    selectedIds: number[] = [];
    selectAllSubset: boolean = true;
    virtualScroll: boolean = true;
    bindValue = 'Id';
    bindLabel = 'Name';
    searchStartsWith = false;
    clearSearchOnAdd = true;
    selectAll = false;
    searchSet = [];

    constructor(
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private physicianService: PhysicianService,
        private individualService: IndividualService,
    ) {}

    ngOnInit(): void {
        this.physician = this.individual.Physicians[0];
        this.physicianSubscription = this.physicianService.getAllPhysicians().subscribe((physicians) => {
            this.physicians = physicians;
            this.physicians = this.physicians.filter((physician) => physician.Id !== this.physician.Id);
            this.createForm();
        });
        if (this.individual.Id !== 0 && this.physician.PhysicianProfileAssociations_PrimaryPhysicianId) {
            this.selectedIds = this.physician.PhysicianProfileAssociations_PrimaryPhysicianId.filter(
                (association) => association.DuplicatePhysician && association.DuplicatePhysician.Individual,
            )
            .map((association) => association.DuplicatePhysician.Id);
            this.placeholder = this.selectedIds.length ? '' : 'Select physician(s)';
        }   
    }

    ngOnDestroy(): void {
        this.physicianSubscription.unsubscribe();
        this.cdr.detach();
    }

    createForm(): void {
        this.getControls();
        this.physicianProfileAssociationForm = this.assignFormGroups();
        this.formCreated = true;
        safeDetectChanges(this.cdr);
    }

    getControls(): void {
        const abstractPhysicianProfileAssociationControls = new PhysicianProfileAssociationDynamicControlsPartial(null, {
            formGroup: 'PhysicianProfileAssociation',
            primaryPhysicians: null,
            duplicatePhysicians: this.physicians,
        });
        this.abstractPhysicianProfileAssociationControls = abstractPhysicianProfileAssociationControls.Form;
        this.abstractPhysicianProfileAssociationControlsView = abstractPhysicianProfileAssociationControls.View;
        this.abstractPhysicianProfileAssociationControls.DuplicatePhysicianId.value =
            this.physician.PhysicianProfileAssociations_PrimaryPhysicianId.map((physician) => {
                return physician.DuplicatePhysicianId;
            });
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            PhysicianProfileAssociation: this.fb.group({}),
        });
    }

    formSubmitted(): void {
            this.formatDataForSave();
            this.physicianService.saveDuplicatePhysicians(this.physician).subscribe(() => {
                this.success();
                this.individualService.getById(this.individual.Id).subscribe((individual) => {
                    this.individual = individual;
                    this.physician = this.individual.Physicians[0];
                    this.isEditing = false;
                    this.getControls();
                });
            });
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    formatDataForSave(): void {
        const duplicatePhysicianIds: number[] = this.selectedIds;
        this.mergeDuplicatePhysicianProfiles(duplicatePhysicianIds);
    }

    mergeDuplicatePhysicianProfiles(duplicatePhysicianIds: number[]): void {
        this.physician.PhysicianProfileAssociations_DuplicatePhysicianId = this.physician.PhysicianProfileAssociations_DuplicatePhysicianId.filter(
            (physician) => {
                return duplicatePhysicianIds.indexOf(physician.Id) > -1;
            },
        );
        duplicatePhysicianIds?.forEach((id) => {
            if (
                this.physician.PhysicianProfileAssociations_DuplicatePhysicianId.findIndex(
                    (physicianAssociation: IPhysicianProfileAssociation) => physicianAssociation.Id === id,
                ) === -1
            ) {
                this.physician.PhysicianProfileAssociations_DuplicatePhysicianId.push({
                    Id: 0,
                    PrimaryPhysicianId: this.physician.Id,
                    DuplicatePhysicianId: id,
                });
            }
        });
    }

    getValueToDisplay(): string {
        return this.physician.PhysicianProfileAssociations_PrimaryPhysicianId.filter(
            (association) => association.DuplicatePhysician && association.DuplicatePhysician.Individual,
        )
        .map((association) => `${association.DuplicatePhysician.Individual.FirstName} ${association.DuplicatePhysician.Individual.LastName} - ${association.DuplicatePhysician.Individual.Id}`)
       .join(', ');
    }

    onClear(): void {
        this.selectedIds = [];
        this.placeholder = 'Select physician(s)';
    }

    onRemove(event: IPhysician): void {
        this.selectedIds = this.selectedIds.filter(id => id !== event.Id);
        if (this.selectAll) {
            this.selectAll = false;
        }
        this.placeholder = this.selectedIds.length ? '' : 'Select physician(s)';
    }

    onAdd(event: IPhysician): void {
        this.selectedIds.push(event.Id);
        this.placeholder = '';
    }

    searchFunction(): (term:string, item:unknown) => boolean {
        if (this.searchStartsWith) {
            return (term: string, item: unknown) => item[this.bindLabel].toLowerCase().startsWith(term.toLowerCase());
        }
        return (term: string, item: unknown) => item[this.bindLabel].toLowerCase().includes(term.toLowerCase());
    }

    selectAllItems(): void {
        if (this.selectAll) {
            this.selectedIds = this.searchSet.length ? this.searchSet.map((s) => s[this.bindValue]) : this.physicians.map((s) => s[this.bindValue]);
        } else {
            this.selectedIds = [];
        }
    }
}
