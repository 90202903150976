import { IAppFaxEmailComponentApi } from './../../../common/fax-email/fax-email-component';
import { AddressFormatterService } from './../../../common/services/address-formatter.service';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { AddressService } from './../../addresses/address.service';
import { PhoneTypeService } from './../../phonetype.service';
import { IPhoneType, IEntity } from '@model/interfaces/base.d';
import { IAddressBook } from '@model/interfaces/address-book';
import { AddressBookService } from '../../addressbook.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Observable, Subscription, of, forkJoin } from 'rxjs';
import { IIndividual } from '@model/interfaces/individual';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IndividualService } from '../individual.service';
import { ClaimsService, ClaimValues, AuthService } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@model/ClaimTypes';
import {
    MetaAddressBookTypeIdEnums,
    AttachmentServiceTypeEnums,
    ViewLogEntityTypeEnums,
    FaxEmailEntityTypeEnums,
    AutomatedTemplateFaxEmailEntityTypeEnums,
    AddressBookTypeIdEnums,
} from '../../../common/constants/Enums';
import { IndividualSharedEntities } from '../shared/individual.shared-entities';
import { AddressLabelGenerator } from '../../addresses/address.label';
import { PhysicianService } from '../physicians/physician.service';
import { IAttachmentDTO } from '@model/interfaces/custom/attachment-dto';
import { BaseAttachmentsService } from '../../../common/attachments/base-attachments.service';
import { IPhysicianVacation } from '@model/interfaces/physician-vacation';
import { PhysicianVacationLabelGenerator } from '../physicians/physician-vacation.label';
import { tap, catchError, finalize } from 'rxjs/operators';
import { ViewLogService } from '../../../common/services/viewlog.service';
import { IndividualEmailService } from '../shared/individual-email.service';
import { IEmailAddress } from '@model/interfaces/email-address';
import { UserService } from '../../../users/user.service';
import { CommunicationLogLabelGenerator } from '../../../imed-claims/services/common-components/communication-log/communication-log-label-generator';
import { ICommunicationLog } from '@model/interfaces/communication-log';
import {
    CommunicationLogService,
    IResendCommunicationConfig,
    ResendCommunicationAttachmentTypes,
} from '../../../common/services/communication-log.service';
import { ICommunicationLogResponse } from '@model/interfaces/custom/communication-request';
import { NullHostDirective } from '../../../common/directives/null-host.directive';
import { CommonEmailComponent } from '../../../common/common-email/common-email.component';
import { CommonFaxComponent } from '../../../common/common-fax/common-fax.component';
import { IPhysician } from '@model/interfaces/physician';
import { IEmailType } from '@model/interfaces/email-type';
import { EmailTypeService } from '@model/shared-entities/email-type/emailtype.service';
import { IPhoneNumber } from '@model/interfaces/phone-number';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDynamicField, IDynamicFieldType, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IAddressBookType } from '@model/interfaces/address-book-type';
import { AddressBookTypeService } from '../../addressbooktype.service';
import { IDupAddressBookDto } from '@model/interfaces/custom/dup-address-book-dto';
import { IName, NameTypeEnum } from '@model/interfaces/custom/name';
import { HttpResponse } from '@angular/common/http';
import { IAddress } from '@model/interfaces/address';

@Component({
    selector: 'app-individual-detail',
    templateUrl: './individual-detail.component.html',
})
export class IndividualDetailComponent implements OnInit, OnDestroy {
    individual: IIndividual;
    canUploadWetSignature: false;
    individualEmails: IEmailAddress[] = [];
    facilityEmails: IEmailAddress[] = [];
    individualPhones: IPhoneNumber[] = [];
    facilityPhones: IPhoneNumber[] = [];
    physician: IPhysician;
    addressBook: IAddressBook;
    phoneTypes: IPhoneType[];
    emailTypes: IEmailType[];
    editingComponent: Subject<string> = new Subject();
    canEdit: boolean;
    canAdd: boolean;
    canManageExternalAccess: boolean;
    canViewExternalAccess: boolean;
    individualId: number;
    addressBookId: number;

    individualAddress: IAddress;
    subscriptions: Subscription = new Subscription();

    addressCardName: string;
    numbeOfAddressesToShow = 3;
    addressLabelGenerator = new AddressLabelGenerator(this.addressFormatterService);

    emailSharedEntityId = IndividualSharedEntities.EmailAddresses;
    individualPhoneNumberSharedEntityId = IndividualSharedEntities.PhoneNumbers;
    addresses: IAddress;
    totalAddresses: number;
    formRendered = false;
    showEmailComponent = true;
    documentArray: IAttachmentDTO[];

    attachmentServiceTypeEnum: AttachmentServiceTypeEnums = AttachmentServiceTypeEnums.Address_Book;
    physicianCardName = 'Physician Vacations';
    vacationLabelGenerator = new PhysicianVacationLabelGenerator();

    communicationLogCardName = 'Communication Logs';
    communicationlLogLabelGenerator: CommunicationLogLabelGenerator;
    communicationlLogs: ICommunicationLogResponse[];
    communicationlLogsCount: number;
    // For injecting email component
    @ViewChild(NullHostDirective) commonEmailHost: NullHostDirective;

    introLetterComponentApi: IAppFaxEmailComponentApi;
    welcomeLetterComponentApi: IAppFaxEmailComponentApi;
    introImesLetterComponentApi: IAppFaxEmailComponentApi;
    showATPopup = false;
    appFaxEmailComponentExists = true;

    entityTypeIdEnums = FaxEmailEntityTypeEnums;
    automatedTemplateEntityTypeIdEnums = AutomatedTemplateFaxEmailEntityTypeEnums;

    duplicateAddressBookForm = new UntypedFormGroup({});
    addressBookTypeSelectDropdown: DynamicField;
    doubleClickIsDisabled = false;
    isDuplicatePhysicianProfile: boolean = false;
    modalWrapperApi: IModalWrapperApi;
    modalOptions: IModalOptions = {
        allowEscapeKey: false,
        allowOutsideClick: false,
        title: 'Duplicate Individual?',
    };

    get addressBookIsFacilityType(): boolean {
        return this.addressBook.MetaAddressBookTypeId === +MetaAddressBookTypeIdEnums.Facility_Organization;
    }
    get addressBookIsIndividualType(): boolean {
        return this.addressBook.MetaAddressBookTypeId === +MetaAddressBookTypeIdEnums.Individual;
    }

    get canDuplicate(): boolean {
        return this.claimsService.hasClaim(ClaimTypes.DuplicateAddressBooks, [ClaimValues.FullAccess]) && this.addressBookIsIndividualType;
    }

    constructor(
        public individualService: IndividualService,
        public individualEmailService: IndividualEmailService,
        private claimsService: ClaimsService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private addressBookService: AddressBookService,
        private phoneTypeService: PhoneTypeService,
        private emailTypeService: EmailTypeService,
        private authService: AuthService,
        private notificationsService: NotificationsService,
        private addressService: AddressService,
        private addressFormatterService: AddressFormatterService,
        private physicianService: PhysicianService,
        private attachmentsService: BaseAttachmentsService,
        private viewLogService: ViewLogService,
        private userService: UserService,
        private communicationLogService: CommunicationLogService,
        private addressBookTypeService: AddressBookTypeService,
    ) {}

    ngOnInit(): void {
        this.addressCardName = 'Addresses';
        this.communicationlLogLabelGenerator = new CommunicationLogLabelGenerator();
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.AddressBooks, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        this.canManageExternalAccess = this.claimsService.hasClaim(ClaimTypes.Users, [ClaimValues.FullAccess]);
        this.canViewExternalAccess = this.claimsService.hasClaim(ClaimTypes.Users, [ClaimValues.FullAccess, ClaimValues.ReadOnly]);
        this.individualId = +this.activatedRoute.snapshot.paramMap.get('individualId');
        this.addressBookId = +this.activatedRoute.parent.parent.snapshot.params.addressBookId; // PS:TODO -  NGIN to figure out a good way of doing this
        if (this.addressBookId > 0) {
            this.getAddressBookById();
            forkJoin([this.addressBookTypeService.getItems(), this.getCommunicationLogs()]).subscribe(([addressBookTypes]) => {
                this.initAddressBookTypeSelector(addressBookTypes);
                this.subscribeToCommunicationLogs();
            });
        } else {
            this.addressBook = this.addressBookService.getEmptyAddressBook();
            this.individual = this.individualService.getEmptyIndividual();
            this.phoneTypeService.getItems().subscribe(() => (this.phoneTypes = this.phoneTypeService.items));
            this.emailTypeService.getItems().subscribe(() => (this.emailTypes = this.emailTypeService.items));
            this.editingComponent.next('');
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    initAddressBookTypeSelector(addressBookTypes: IAddressBookType[]): void {
        this.addressBookTypeSelectDropdown = new DynamicField({
            formGroup: '',
            label: 'Address Book Type',
            name: 'AddressBookType',
            options: addressBookTypes,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.Dropdown,
            } as IDynamicFieldType),
            validation: [(c: AbstractControl) => Validators.required(c)],
            validators: { required: true },
            value: '',
        } as IDynamicField);
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'AddressBookId',
                value: this.addressBookId.toString(),
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'includeArchived',
                value: 'false',
            }),
        );
        return _extraSearchParams;
    }

    subscribeToCommunicationLogs(): void {
        this.subscriptions.add(this.communicationLogService.emitLogChange.subscribe(() => this.getCommunicationLogs().subscribe()));
    }

    createViewLog(): void {
        const log = this.viewLogService.getEmptyViewLog();
        log.EntityType = ViewLogEntityTypeEnums.AddressBook;
        log.Route = this.router.url;
        log.UserId = this.authService.currentUser.getValue().Id;
        log.View = 'Individual: ' + this.individual.FirstName + ' ' + this.individual.LastName;
        this.viewLogService
            .create(log)
            .pipe(
                // Catch error and return empty observable in case max int size is reached in ViewLog table
                catchError(() => of([])),
            )
            .subscribe();
    }

    getPhysicianName(): IName {
        return { firstName: this.individual.FirstName, lastName: this.individual.LastName, nameType: NameTypeEnum.Individual };
    }

    physicianSaved(physician: IPhysician): void {
        this.physician = physician;
    }

    getPhysician(): void {
        this.physicianService.getByIndividualId(this.individualId).subscribe((answer) => {
            this.physician = answer;
            this.formRendered = true;
        });
    }

    getIndividualEmails(): void {
        this.individualService.getIndividualEmails(this.individualId).subscribe((emails) => {
            this.individualEmails = emails;
        });
    }

    getFacilityEmails(): void {
        this.addressBookService.getFacilityEmails(this.addressBookId).subscribe((emails) => {
            this.facilityEmails = emails;
        });
    }

    getFacilityPhones(): void {
        this.addressBookService.getFacilityPhones(this.addressBookId).subscribe((phones) => (this.facilityPhones = phones));
    }

    private createAddressSearchObservable(): Observable<HttpResponse<IAddress[]>> {
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: 'Id',
            orderDirection: 'desc',
            query: '',
            skip: 0,
            take: this.numbeOfAddressesToShow,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.addressService.searchAddress(this.addressBookId, searchparams);
    }

    private buildConditionalObservables(): Observable<any>[] {
        const addressBookObservable = this.addressBookService.getById(this.addressBookId);
        let individualObservable: Observable<IIndividual>;
        let addressSearchObservable: Observable<any> = of(null);
        let individualPhonesObservable: Observable<any> = of(null);
        let phoneTypesObservable: Observable<IPhoneType[]> = of(null);
        let emailTypesObservable: Observable<IEmailType[]> = of(null);
        let canUploadWetSignatureObservable: Observable<any> = of(null);
        if (this.individualId > 0) {
            individualObservable = this.individualService.getById(this.individualId);
            addressSearchObservable = this.createAddressSearchObservable();
            individualPhonesObservable = this.individualService.getIndividualPhones(this.individualId);
            phoneTypesObservable = this.phoneTypeService.getItems();
            emailTypesObservable = this.emailTypeService.getItems();
            canUploadWetSignatureObservable = this.individualService.canUploadWetSignaturePic(this.individualId);
        } else {
            individualObservable = of(this.individualService.getEmptyIndividual());
            canUploadWetSignatureObservable = this.addressBookService.canUploadWetSignaturePic(this.addressBookId);
        }
        return [
            addressBookObservable,
            individualObservable,
            addressSearchObservable,
            individualPhonesObservable,
            phoneTypesObservable,
            emailTypesObservable,
            canUploadWetSignatureObservable,
        ];
    }

    getAddressBookById(): void {
        forkJoin(this.buildConditionalObservables()).subscribe(
            ([addressBook, individual, addressResponse, individualPhones, , , canUploadWetSignature]) => {
                this.addressBook = addressBook;
                this.individual = individual;
                if (this.individualId > 0) {
                    addressResponse.body = addressResponse.body.filter((element) => element.Archived === false);
                    this.addresses = addressResponse.body;
                    this.totalAddresses = addressResponse.headers.get('X-List-Count');
                    this.phoneTypes = this.phoneTypeService.items;
                    this.emailTypes = this.emailTypeService.items;
                    this.individualPhones = individualPhones;
                    this.canUploadWetSignature = canUploadWetSignature;
                    if (this.isExpertOrPhysician()) {
                        this.physicianService.getByIndividualId(this.individualId).subscribe((answer) => {
                            this.physician = answer;
                            this.isDuplicatePhysicianProfile = this.physician.PhysicianProfileAssociations_DuplicatePhysicianId.length > 0;
                            this.physicianService.emitChange(this.physician);
                            this.formRendered = true;
                        });
                    } else {
                        this.formRendered = true;
                    }
                    this.editingComponent.next('');
                    this.createViewLog();
                    this.createExternalAccessSubscriptions();
                    this.getIndividualEmails();
                    if (this.addressBookIsFacilityType) {
                        this.getFacilityEmails();
                        this.getFacilityPhones();
                    }
                } else {
                    this.phoneTypeService.getItems().subscribe(() => (this.phoneTypes = this.phoneTypeService.items));
                    this.editingComponent.next('');
                }
            },
        );
    }

    private createExternalAccessSubscriptions(): void {
        this.individualEmailService.individualEmailsChanged$.subscribe((updatedIndividualId) => {
            this.checkEmailRefresh(updatedIndividualId);
        });
        this.userService.externalUserCreated$.subscribe((createdIndividualId) => {
            this.checkEmailRefresh(createdIndividualId);
        });
    }

    private checkEmailRefresh(individualIdToCheck: number): void {
        if (individualIdToCheck === this.individualId) {
            this.getIndividualEmails();
        }
    }

    reloadWetSignature(): void {
        if (this.individualId > 0) {
            this.individualService.canUploadWetSignaturePic(this.individualId).subscribe((answer) => {
                this.canUploadWetSignature = answer;
            });
        } else {
            this.addressBookService.canUploadWetSignaturePic(this.addressBookId).subscribe((answer) => {
                this.canUploadWetSignature = answer;
            });
        }
    }

    isIndividualOfIndividualType(): boolean {
        return this.addressBook.MetaAddressBookTypeId === +MetaAddressBookTypeIdEnums.Individual;
    }

    cancelClick(): void {
        if (this.isIndividualOfIndividualType()) {
            void this.router.navigate(['address-books']);
        } else {
            void this.router.navigate([`address-books/${this.addressBook.Id}/individuals`]);
        }
    }

    addAddress(): void {
        void this.router.navigate([`address-books/${this.addressBook.Id}/addresses/add`]);
    }

    seeAllAddresses(): void {
        void this.router.navigate([`address-books/${this.addressBook.Id}/addresses`]);
    }

    selectAddress(address: IEntity): void {
        void this.router.navigate([`address-books/${this.addressBook.Id}/addresses/${address.Id}`]);
    }

    isExpertOrPhysician(): boolean {
        return (
            this.addressBook &&
            (this.addressBook.AddressBookTypeId === +AddressBookTypeIdEnums.Physician_panel ||
                this.addressBook.AddressBookTypeId === +AddressBookTypeIdEnums.Expert_panel)); // if addressBookType is physician or expert
    }

    isAttorney(): boolean {
        return this.addressBook && this.addressBook.AddressBookTypeId === +AddressBookTypeIdEnums.Attorney;
    }

    getIntoLetter(): void {
        this.introImesLetterComponentApi.show();
    }

    getIntroNewToImesLetter(): void {
        this.introImesLetterComponentApi.show();
    }

    getWelcomeLetter(): void {
        this.welcomeLetterComponentApi.show();
    }

    seeAllPhysicianVacations(): void {
        void this.router.navigate([`address-books/${this.addressBookId}/individuals/${this.individualId}/physician-vacations/`]);
    }

    addPhysicianVacation(): void {
        void this.router.navigate([`address-books/${this.addressBookId}/individuals/${this.individualId}/physician-vacations/add`]);
    }

    selectPhysicianVacation(physicianVacation: IPhysicianVacation): void {
        void this.router.navigate([
            `address-books/${this.addressBookId}/individuals/${this.individualId}/physician-vacations/${physicianVacation.Id}`,
        ]);
    }

    getCommunicationLogs(): Observable<ICommunicationLog[]> {
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'individualId',
                value: this.individualId.toString(),
            }),
        );
        const searchParams: SearchParams = new SearchParams({
            extraParams: _extraSearchParams,
            order: 'DateSent',
            orderDirection: 'desc',
            query: '',
            skip: 0,
            take: 3,
        });

        return this.communicationLogService.searchLogs(searchParams, 'individual').pipe(
            tap((answer: any) => {
                this.communicationlLogs = answer.body;
                this.communicationlLogsCount = +answer.headers.get('X-List-Count');
            }),
        );
    }

    seeAllCommunicationLogs(): void {
        void this.router.navigate(['address-books', this.addressBookId, 'individuals', this.individualId, 'communication-logs']);
    }

    loadResendComponent(e: IEntity): void {
        const response = e as ICommunicationLogResponse;
        const data: IResendCommunicationConfig = {
            attachmentType: this.physician ? ResendCommunicationAttachmentTypes.PHYSICIAN : ResendCommunicationAttachmentTypes.INDIVIDUAL,
            component: response.Type === 'Fax' ? CommonFaxComponent : CommonEmailComponent,
            data: response,
        };
        this.communicationLogService.loadResendCommunicationComponent(this.commonEmailHost, data);
    }

    toggleAutomatedTemplateModal($event): void {
        this.showATPopup = !this.showATPopup;
    }

    getIndividualName(): IName {
        return { firstName: this.individual.FirstName, lastName: this.individual.LastName, nameType: NameTypeEnum.Individual };
    }
    phoneSaved(): void {
        this.individualService.getIndividualPhones(this.individualId).subscribe((phones) => (this.individualPhones = phones));
    }

    refreshDocuments(): void {
        this.attachmentsService.requestRefresh();
        setTimeout(() => {
            this.showATPopup = false;
        });
    }

    resetFaxComponents(): void {
        // force destroy and recreate app-fax-email components so that CKEditor doesn't error out
        this.appFaxEmailComponentExists = false;
        setTimeout(() => {
            this.appFaxEmailComponentExists = true;
        });
    }

    duplicate(): void {
        if (this.modalWrapperApi) {
            this.modalWrapperApi.show();
        }
    }

    saveDuplicate(): void {
        if (this.duplicateAddressBookForm.valid) {
            const addressBookType: number = this.duplicateAddressBookForm.value.AddressBookType;
            this.addressBookService.duplicateAddressBook(this.addressBook.Id, addressBookType).subscribe(
                (dupAddressBook: IDupAddressBookDto) => {
                    this.notificationsService.success('Address Book successfully duplicated!');
                    void this.router
                        .navigate(['address-books', dupAddressBook.AddressBookId, 'individuals', dupAddressBook.IndividualId])
                        .then(() => window.location.reload());
                },
                () => {
                    this.notificationsService.error('An error occurred while duplicating the Address Book.');
                },
            );
        } else {
            markAllFormFieldsAsTouched(this.duplicateAddressBookForm);
            setTimeout(() => (this.doubleClickIsDisabled = false));
        }
    }

    closeModal(): void {
        this.modalWrapperApi.close();
        this.duplicateAddressBookForm.reset();
    }
}
