import { IImedClaim } from '@model/interfaces/imed-claim';
import { IImedClaimPartial } from '@model/partials/imed-claim-partial';
import { EntityListConfig, EntityListColumn, IEntityListConfig } from '@mt-ng2/entity-list-module';
import { RequestorTypeEnums } from '../../common/constants/Enums';
import { ImedClaimDeleteDynamicCellComponent } from './imed-claims-delete-dynamic-cell.component';
export class ImedClaimsEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['Id'],
                    name: 'Id',
                }),
                new EntityListColumn({
                    accessorFunction: function (claim: IImedClaimPartial): string {
                        let claimantName = '';
                        if (claim.FirstName) {
                            claimantName = '<a href="#/cases/' + claim.Id + '">' + claim.FirstName + ' ' + claim.LastName + '</a>';
                        } else {
                            claimantName = '';
                        }

                        if (claim.RequestorTypeId === +RequestorTypeEnums.Claimant) {
                            claimantName = `<strong>${claimantName}</strong>`;
                        }

                        return claimantName;
                    },
                    bindToInnerHtml: true,
                    fireOnColumnSelected: true,
                    name: 'Claimant Name',
                    sort: {
                        sortProperty: 'ClaimantName',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IImedClaimPartial) => entity.ClaimNumber || ' ',
                    name: 'Claim Number',
                    sort: {
                        sortProperty: 'ClaimNumber',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (entity: IImedClaimPartial) => entity.FileNumber || ' ',
                    name: 'File Number',
                    sort: {
                        sortProperty: 'FileNumber',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (claim: IImedClaimPartial) => {
                        const requestorType = claim.RequestorTypeId;
                        switch (requestorType) {
                            case +RequestorTypeEnums.Defense_Attorney:
                                return claim.DefenseAttorneyIndividual
                                    ? claim.DefenseAttorneyIndividual.FirstName + ' ' + claim.DefenseAttorneyIndividual.LastName
                                    : claim.DefenseAttorneyAddressBook
                                    ? claim.DefenseAttorneyAddressBook.FacilityName
                                    : '';
                            case +RequestorTypeEnums.Paralegal:
                                return claim.ParaLegalIndividual
                                    ? claim.ParaLegalIndividual.FirstName + ' ' + claim.ParaLegalIndividual.LastName
                                    : claim.ParaLegalAddressBook
                                    ? claim.ParaLegalAddressBook.FacilityName
                                    : '';
                            case +RequestorTypeEnums.Carrier:
                                return claim.CarrierPartyIndividual
                                    ? claim.CarrierPartyIndividual.FirstName + ' ' + claim.CarrierPartyIndividual.LastName
                                    : claim.CarrierPartyAddressBook
                                    ? claim.CarrierPartyAddressBook.FacilityName
                                    : '';
                            default:
                                return '';
                        }
                    },
                    name: 'Requestor Name',
                    sort: {
                        sortProperty: 'RequestorName',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (claim: IImedClaimPartial) => {
                        let defenseAttorneyFirm =
                            claim.DefenseAttorneyAddressBook && claim.DefenseAttorneyAddressBook.FacilityName !== ''
                                ? claim.DefenseAttorneyAddressBook.FacilityName
                                : claim.DefenseAttorneyIndividual
                                ? claim.DefenseAttorneyIndividual.FirstName + ' ' + claim.DefenseAttorneyIndividual.LastName
                                : ' ';

                        if (claim.RequestorTypeId === +RequestorTypeEnums.Defense_Attorney) {
                            defenseAttorneyFirm = `<strong>${defenseAttorneyFirm}</strong>`;
                        }

                        return defenseAttorneyFirm;
                    },
                    bindToInnerHtml: true,
                    name: 'Defense Attorney Firm',
                    sort: {
                        sortProperty: 'DefenseAttorney',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (claim: IImedClaim) =>
                        claim.PlantiffAttorneyAddressBook && claim.PlantiffAttorneyAddressBook.FacilityName !== ''
                            ? claim.PlantiffAttorneyAddressBook.FacilityName
                            : claim.PlantiffAttorneyIndividual
                            ? claim.PlantiffAttorneyIndividual.FirstName + ' ' + claim.PlantiffAttorneyIndividual.LastName
                            : ' ',
                    name: 'Plaintiff Attorney Firm',
                    sort: {
                        sortProperty: 'PlaintiffAttorney',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (claim: IImedClaimPartial) => {
                        let carrier =
                            claim.CarrierPartyAddressBook && claim.CarrierPartyAddressBook.FacilityName !== ''
                                ? claim.CarrierPartyAddressBook.FacilityName
                                : claim.CarrierPartyIndividual
                                ? claim.CarrierPartyIndividual.FirstName + ' ' + claim.CarrierPartyIndividual.LastName
                                : ' ';

                        if (claim.RequestorTypeId === +RequestorTypeEnums.Carrier) {
                            carrier = `<strong>${carrier}</strong>`;
                        }

                        return carrier;
                    },
                    bindToInnerHtml: true,
                    name: 'Carrier/Bill-To Party Firm',
                    sort: {
                        sortProperty: 'CarrierParty',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (claim: IImedClaimPartial) => claim.ImedClaimStatus.Name,
                    name: 'Status',
                }),
                new EntityListColumn({
                    accessors: ['DateCreated'],
                    name: 'Date Created',
                    pipes: ['date:medium'],
                }),
                new EntityListColumn({
                    component: ImedClaimDeleteDynamicCellComponent,
                    excludeFromExport: true,
                    fireOnColumnSelected: false,
                    name: 'Delete',
                    sort: { disableSort: true },
                }),
            ],
            rowClass: (entity: IImedClaimPartial) => (entity.Archived ? 'archived' : null),
        };
        super(listConfig);
    }
}
