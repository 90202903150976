import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IPhysicianDetail } from '../interfaces/physician-detail';
import { IUser } from '../interfaces/user';
import { IFeeCostType } from '../interfaces/fee-cost-type';
import { IImedClaimType } from '../interfaces/imed-claim-type';
import { IPhysician } from '../interfaces/physician';
import { IPhysicianStatus } from '../interfaces/physician-status';
import { IMetaItem } from '@mt-ng2/base-service';

export interface IPhysicianDetailDynamicControlsParameters {
    formGroup?: string;
    physicians?: IPhysician[];
    imedClaimTypes?: IImedClaimType[];
    physicianStatuses?: IPhysicianStatus[];
    filmReviewFees?: IFeeCostType[];
    filmReviewCosts?: IFeeCostType[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
    filmReviewFeeTypes?: IFeeCostType[];
    filmReviewCostTypes?: IFeeCostType[];
}

export class PhysicianDetailDynamicControls {

    formGroup: string;
    physicians: IPhysician[];
    imedClaimTypes: IImedClaimType[];
    physicianStatuses: IPhysicianStatus[];
    filmReviewFees: IFeeCostType[];
    filmReviewCosts: IFeeCostType[];
    createdBies: IUser[];
    modifiedBies: IUser[];
    filmReviewFeeTypes: IFeeCostType[];
    filmReviewCostTypes: IFeeCostType[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private physiciandetail?: IPhysicianDetail, additionalParameters?: IPhysicianDetailDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'PhysicianDetail';
        this.physicians = additionalParameters && additionalParameters.physicians || undefined;
        this.imedClaimTypes = additionalParameters && additionalParameters.imedClaimTypes || undefined;
        this.physicianStatuses = additionalParameters && additionalParameters.physicianStatuses || undefined;
        this.filmReviewFees = additionalParameters && additionalParameters.filmReviewFees || undefined;
        this.filmReviewCosts = additionalParameters && additionalParameters.filmReviewCosts || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;
        this.filmReviewFeeTypes = additionalParameters && additionalParameters.filmReviewFeeTypes || undefined;
        this.filmReviewCostTypes = additionalParameters && additionalParameters.filmReviewCostTypes || undefined;

        this.Form = {
            AddendumCost: new DynamicField({
                formGroup: this.formGroup,
                label: 'Addendum Cost',
                name: 'AddendumCost',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.AddendumCost || null,
            }),
            AddendumDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Addendum Date',
                name: 'AddendumDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.AddendumDate || null,
            }),
            AddendumFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Addendum Fee',
                name: 'AddendumFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.AddendumFee || null,
            }),
            AdditionalCost: new DynamicField({
                formGroup: this.formGroup,
                label: 'Additional Cost',
                name: 'AdditionalCost',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.AdditionalCost || null,
            }),
            AdditionalDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Additional Date',
                name: 'AdditionalDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.AdditionalDate || null,
            }),
            AdditionalFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Additional Fee',
                name: 'AdditionalFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.AdditionalFee || null,
            }),
            AdditionalFilmReviewCost: new DynamicField({
                formGroup: this.formGroup,
                label: 'Additional Film Review Cost',
                name: 'AdditionalFilmReviewCost',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.AdditionalFilmReviewCost || null,
            }),
            AdditionalFilmReviewFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Additional Film Review Fee',
                name: 'AdditionalFilmReviewFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.AdditionalFilmReviewFee || null,
            }),
            AdditionalFilmReviewFeeDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Additional Film Review Fee Date',
                name: 'AdditionalFilmReviewFeeDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.AdditionalFilmReviewFeeDate || null,
            }),
            AdditionalNotes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Additional Notes',
                name: 'AdditionalNotes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('AdditionalNotes') && this.physiciandetail.AdditionalNotes !== null ? this.physiciandetail.AdditionalNotes.toString() : '',
            }),
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('Archived') && this.physiciandetail.Archived !== null ? this.physiciandetail.Archived : false,
            }),
            BoardExpirationDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Board Expiration Date',
                name: 'BoardExpirationDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.BoardExpirationDate || null,
            }),
            ConferenceCallCost: new DynamicField({
                formGroup: this.formGroup,
                label: 'Conference Call Cost',
                name: 'ConferenceCallCost',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.ConferenceCallCost || null,
            }),
            ConferenceCallFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Conference Call Fee',
                name: 'ConferenceCallFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.ConferenceCallFee || null,
            }),
            ConfirmationCancellationDays: new DynamicField({
                formGroup: this.formGroup,
                label: 'Confirmation Cancellation Days',
                name: 'ConfirmationCancellationDays',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.ConfirmationCancellationDays || 3,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.physiciandetail && this.physiciandetail.CreatedById || null,
            }),
            CvReceivedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cv Received Date',
                name: 'CvReceivedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.CvReceivedDate || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.DateModified || null,
            }),
            ExcludeCredentialingForm: new DynamicField({
                formGroup: this.formGroup,
                label: 'Exclude Credentialing Form',
                name: 'ExcludeCredentialingForm',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('ExcludeCredentialingForm') && this.physiciandetail.ExcludeCredentialingForm !== null ? this.physiciandetail.ExcludeCredentialingForm : false,
            }),
            ExcludeLiabilityInsurance: new DynamicField({
                formGroup: this.formGroup,
                label: 'Exclude Liability Insurance',
                name: 'ExcludeLiabilityInsurance',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('ExcludeLiabilityInsurance') && this.physiciandetail.ExcludeLiabilityInsurance !== null ? this.physiciandetail.ExcludeLiabilityInsurance : false,
            }),
            ExcludeLicenseExpiration: new DynamicField({
                formGroup: this.formGroup,
                label: 'Exclude License Expiration',
                name: 'ExcludeLicenseExpiration',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('ExcludeLicenseExpiration') && this.physiciandetail.ExcludeLicenseExpiration !== null ? this.physiciandetail.ExcludeLicenseExpiration : false,
            }),
            ExcludeLicenseVerification: new DynamicField({
                formGroup: this.formGroup,
                label: 'Exclude License Verification',
                name: 'ExcludeLicenseVerification',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('ExcludeLicenseVerification') && this.physiciandetail.ExcludeLicenseVerification !== null ? this.physiciandetail.ExcludeLicenseVerification : false,
            }),
            ExcludeSampleReport: new DynamicField({
                formGroup: this.formGroup,
                label: 'Exclude Sample Report',
                name: 'ExcludeSampleReport',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('ExcludeSampleReport') && this.physiciandetail.ExcludeSampleReport !== null ? this.physiciandetail.ExcludeSampleReport : false,
            }),
            FeeScheduleReceivedDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Fee Schedule Received Date',
                name: 'FeeScheduleReceivedDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.FeeScheduleReceivedDate || null,
            }),
            FilmReviewCost: new DynamicField({
                formGroup: this.formGroup,
                label: 'Film Review Cost',
                name: 'FilmReviewCost',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.FilmReviewCost || null,
            }),
            FilmReviewCostTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Film Review Cost Type',
                name: 'FilmReviewCostTypeId',
                options: this.filmReviewCostTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.FilmReviewCostTypeId || null,
            }),
            FilmReviewDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Film Review Date',
                name: 'FilmReviewDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.FilmReviewDate || null,
            }),
            FilmReviewFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Film Review Fee',
                name: 'FilmReviewFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.FilmReviewFee || null,
            }),
            FilmReviewFeeTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Film Review Fee Type',
                name: 'FilmReviewFeeTypeId',
                options: this.filmReviewFeeTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.FilmReviewFeeTypeId || null,
            }),
            FullDayTelephoneDepositionCost: new DynamicField({
                formGroup: this.formGroup,
                label: 'Full Day Telephone Deposition Cost',
                name: 'FullDayTelephoneDepositionCost',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.FullDayTelephoneDepositionCost || null,
            }),
            FullDayTelephoneDepositionFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Full Day Telephone Deposition Fee',
                name: 'FullDayTelephoneDepositionFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.FullDayTelephoneDepositionFee || null,
            }),
            FullDayTestimonyCost: new DynamicField({
                formGroup: this.formGroup,
                label: 'Full Day Testimony Cost',
                name: 'FullDayTestimonyCost',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.FullDayTestimonyCost || null,
            }),
            FullDayTestimonyFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Full Day Testimony Fee',
                name: 'FullDayTestimonyFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.FullDayTestimonyFee || null,
            }),
            HalfDayTelephoneDepositionCost: new DynamicField({
                formGroup: this.formGroup,
                label: 'Half Day Telephone Deposition Cost',
                name: 'HalfDayTelephoneDepositionCost',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.HalfDayTelephoneDepositionCost || null,
            }),
            HalfDayTelephoneDepositionFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Half Day Telephone Deposition Fee',
                name: 'HalfDayTelephoneDepositionFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.HalfDayTelephoneDepositionFee || null,
            }),
            HalfDayTestimonyCost: new DynamicField({
                formGroup: this.formGroup,
                label: 'Half Day Testimony Cost',
                name: 'HalfDayTestimonyCost',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.HalfDayTestimonyCost || null,
            }),
            HalfDayTestimonyFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Half Day Testimony Fee',
                name: 'HalfDayTestimonyFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.HalfDayTestimonyFee || null,
            }),
            HourlyCost: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hourly Cost',
                name: 'HourlyCost',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.HourlyCost || null,
            }),
            HourlyDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hourly Date',
                name: 'HourlyDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.HourlyDate || null,
            }),
            HourlyFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hourly Fee',
                name: 'HourlyFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.HourlyFee || null,
            }),
            ImedClaimTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Type',
                name: 'ImedClaimTypeId',
                options: this.imedClaimTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.physiciandetail && this.physiciandetail.ImedClaimTypeId || null,
            }),
            ImeExamConsultationCost: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ime Exam Consultation Cost',
                name: 'ImeExamConsultationCost',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.ImeExamConsultationCost || null,
            }),
            ImeExamConsultationDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ime Exam Consultation Date',
                name: 'ImeExamConsultationDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.ImeExamConsultationDate || null,
            }),
            ImeExamConsultationFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ime Exam Consultation Fee',
                name: 'ImeExamConsultationFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.ImeExamConsultationFee || null,
            }),
            InactiveDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Inactive Date',
                name: 'InactiveDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.InactiveDate || null,
            }),
            InactiveReason: new DynamicField({
                formGroup: this.formGroup,
                label: 'Inactive Reason',
                name: 'InactiveReason',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('InactiveReason') && this.physiciandetail.InactiveReason !== null ? this.physiciandetail.InactiveReason.toString() : '',
            }),
            IntroductoryLetterSentDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Introductory Letter Sent Date',
                name: 'IntroductoryLetterSentDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.IntroductoryLetterSentDate || null,
            }),
            IsPrepayment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Prepayment',
                name: 'IsPrepayment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('IsPrepayment') && this.physiciandetail.IsPrepayment !== null ? this.physiciandetail.IsPrepayment : false,
            }),
            LiabilityInsuranceExpirationDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Liability Insurance Expiration Date',
                name: 'LiabilityInsuranceExpirationDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.LiabilityInsuranceExpirationDate || null,
            }),
            LicenseExpirationDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'License Expiration Date',
                name: 'LicenseExpirationDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.LicenseExpirationDate || null,
            }),
            LicenseVerificationDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'License Verification Date',
                name: 'LicenseVerificationDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.LicenseVerificationDate || null,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.ModifiedById || null,
            }),
            NonCompulsoryNote: new DynamicField({
                formGroup: this.formGroup,
                label: 'Non Compulsory Note',
                name: 'NonCompulsoryNote',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('NonCompulsoryNote') && this.physiciandetail.NonCompulsoryNote !== null ? this.physiciandetail.NonCompulsoryNote.toString() : '',
            }),
            NoShowCost: new DynamicField({
                formGroup: this.formGroup,
                label: 'No Show Cost',
                name: 'NoShowCost',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.NoShowCost || null,
            }),
            NoShowDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'No Show Date',
                name: 'NoShowDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.NoShowDate || null,
            }),
            NoShowFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'No Show Fee',
                name: 'NoShowFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.NoShowFee || null,
            }),
            NotRequiredForThisSpecialty: new DynamicField({
                formGroup: this.formGroup,
                label: 'Not Required For This Specialty',
                name: 'NotRequiredForThisSpecialty',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('NotRequiredForThisSpecialty') && this.physiciandetail.NotRequiredForThisSpecialty !== null ? this.physiciandetail.NotRequiredForThisSpecialty : false,
            }),
            PhysicianId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physician',
                name: 'PhysicianId',
                options: this.physicians,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.physiciandetail && this.physiciandetail.PhysicianId || null,
            }),
            PhysicianLegacyId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physician Legacy',
                name: 'PhysicianLegacyId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('PhysicianLegacyId') && this.physiciandetail.PhysicianLegacyId !== null ? this.physiciandetail.PhysicianLegacyId.toString() : '',
            }),
            PhysicianStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physician Status',
                name: 'PhysicianStatusId',
                options: this.physicianStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.physiciandetail && this.physiciandetail.PhysicianStatusId || null,
            }),
            RecordReviewRetainer: new DynamicField({
                formGroup: this.formGroup,
                label: 'Record Review Retainer',
                name: 'RecordReviewRetainer',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.RecordReviewRetainer || null,
            }),
            RecordReviewRetainerCost: new DynamicField({
                formGroup: this.formGroup,
                label: 'Record Review Retainer Cost',
                name: 'RecordReviewRetainerCost',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.RecordReviewRetainerCost || null,
            }),
            ReportDays: new DynamicField({
                formGroup: this.formGroup,
                label: 'Report Days',
                name: 'ReportDays',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.ReportDays || 10,
            }),
            Retainer: new DynamicField({
                formGroup: this.formGroup,
                label: 'Retainer',
                name: 'Retainer',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.Retainer || null,
            }),
            RetainerCost: new DynamicField({
                formGroup: this.formGroup,
                label: 'Retainer Cost',
                name: 'RetainerCost',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.RetainerCost || null,
            }),
            ServiceCompletedOnTrialPeriod: new DynamicField({
                formGroup: this.formGroup,
                label: 'Service Completed On Trial Period',
                name: 'ServiceCompletedOnTrialPeriod',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.ServiceCompletedOnTrialPeriod || 0,
            }),
            SpecialNote: new DynamicField({
                formGroup: this.formGroup,
                label: 'Special Note',
                name: 'SpecialNote',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('SpecialNote') && this.physiciandetail.SpecialNote !== null ? this.physiciandetail.SpecialNote.toString() : '',
            }),
            TravelAreasOutsideOfVenue: new DynamicField({
                formGroup: this.formGroup,
                label: 'Travel Areas Outside Of Venue',
                name: 'TravelAreasOutsideOfVenue',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('TravelAreasOutsideOfVenue') && this.physiciandetail.TravelAreasOutsideOfVenue !== null ? this.physiciandetail.TravelAreasOutsideOfVenue.toString() : '',
            }),
            VirtualTestimonyCost: new DynamicField({
                formGroup: this.formGroup,
                label: 'Virtual Testimony Cost',
                name: 'VirtualTestimonyCost',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.VirtualTestimonyCost || null,
            }),
            VirtualTestimonyFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Virtual Testimony Fee',
                name: 'VirtualTestimonyFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.VirtualTestimonyFee || null,
            }),
            WillCommentOnPediatricCases: new DynamicField({
                formGroup: this.formGroup,
                label: 'Will Comment On Pediatric Cases',
                name: 'WillCommentOnPediatricCases',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('WillCommentOnPediatricCases') && this.physiciandetail.WillCommentOnPediatricCases !== null ? this.physiciandetail.WillCommentOnPediatricCases : false,
            }),
            WillingToTravel: new DynamicField({
                formGroup: this.formGroup,
                label: 'Willing To Travel',
                name: 'WillingToTravel',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('WillingToTravel') && this.physiciandetail.WillingToTravel !== null ? this.physiciandetail.WillingToTravel : false,
            }),
        };

        this.View = {
            AddendumCost: new DynamicLabel({
                label: 'Addendum Cost',
                value: this.physiciandetail && this.physiciandetail.AddendumCost || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            AddendumDate: new DynamicLabel({
                label: 'Addendum Date',
                value: this.physiciandetail && this.physiciandetail.AddendumDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            AddendumFee: new DynamicLabel({
                label: 'Addendum Fee',
                value: this.physiciandetail && this.physiciandetail.AddendumFee || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            AdditionalCost: new DynamicLabel({
                label: 'Additional Cost',
                value: this.physiciandetail && this.physiciandetail.AdditionalCost || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            AdditionalDate: new DynamicLabel({
                label: 'Additional Date',
                value: this.physiciandetail && this.physiciandetail.AdditionalDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            AdditionalFee: new DynamicLabel({
                label: 'Additional Fee',
                value: this.physiciandetail && this.physiciandetail.AdditionalFee || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            AdditionalFilmReviewCost: new DynamicLabel({
                label: 'Additional Film Review Cost',
                value: this.physiciandetail && this.physiciandetail.AdditionalFilmReviewCost || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            AdditionalFilmReviewFee: new DynamicLabel({
                label: 'Additional Film Review Fee',
                value: this.physiciandetail && this.physiciandetail.AdditionalFilmReviewFee || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            AdditionalFilmReviewFeeDate: new DynamicLabel({
                label: 'Additional Film Review Fee Date',
                value: this.physiciandetail && this.physiciandetail.AdditionalFilmReviewFeeDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            AdditionalNotes: new DynamicLabel({
                label: 'Additional Notes',
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('AdditionalNotes') && this.physiciandetail.AdditionalNotes != null ? this.physiciandetail.AdditionalNotes.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Archived: new DynamicLabel({
                label: 'Archived',
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('Archived') && this.physiciandetail.Archived != null ? this.physiciandetail.Archived : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            BoardExpirationDate: new DynamicLabel({
                label: 'Board Expiration Date',
                value: this.physiciandetail && this.physiciandetail.BoardExpirationDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            ConferenceCallCost: new DynamicLabel({
                label: 'Conference Call Cost',
                value: this.physiciandetail && this.physiciandetail.ConferenceCallCost || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            ConferenceCallFee: new DynamicLabel({
                label: 'Conference Call Fee',
                value: this.physiciandetail && this.physiciandetail.ConferenceCallFee || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            ConfirmationCancellationDays: new DynamicLabel({
                label: 'Confirmation Cancellation Days',
                value: this.physiciandetail && this.physiciandetail.ConfirmationCancellationDays || 3,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            CreatedById: new DynamicLabel({
                label: 'Created By',
                value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.physiciandetail && this.physiciandetail.hasOwnProperty('CreatedById') ? this.physiciandetail.CreatedById : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CvReceivedDate: new DynamicLabel({
                label: 'Cv Received Date',
                value: this.physiciandetail && this.physiciandetail.CvReceivedDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DateCreated: new DynamicLabel({
                label: 'Date Created',
                value: this.physiciandetail && this.physiciandetail.DateCreated || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DateModified: new DynamicLabel({
                label: 'Date Modified',
                value: this.physiciandetail && this.physiciandetail.DateModified || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            ExcludeCredentialingForm: new DynamicLabel({
                label: 'Exclude Credentialing Form',
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('ExcludeCredentialingForm') && this.physiciandetail.ExcludeCredentialingForm != null ? this.physiciandetail.ExcludeCredentialingForm : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ExcludeLiabilityInsurance: new DynamicLabel({
                label: 'Exclude Liability Insurance',
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('ExcludeLiabilityInsurance') && this.physiciandetail.ExcludeLiabilityInsurance != null ? this.physiciandetail.ExcludeLiabilityInsurance : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ExcludeLicenseExpiration: new DynamicLabel({
                label: 'Exclude License Expiration',
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('ExcludeLicenseExpiration') && this.physiciandetail.ExcludeLicenseExpiration != null ? this.physiciandetail.ExcludeLicenseExpiration : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ExcludeLicenseVerification: new DynamicLabel({
                label: 'Exclude License Verification',
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('ExcludeLicenseVerification') && this.physiciandetail.ExcludeLicenseVerification != null ? this.physiciandetail.ExcludeLicenseVerification : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ExcludeSampleReport: new DynamicLabel({
                label: 'Exclude Sample Report',
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('ExcludeSampleReport') && this.physiciandetail.ExcludeSampleReport != null ? this.physiciandetail.ExcludeSampleReport : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            FeeScheduleReceivedDate: new DynamicLabel({
                label: 'Fee Schedule Received Date',
                value: this.physiciandetail && this.physiciandetail.FeeScheduleReceivedDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            FilmReviewCost: new DynamicLabel({
                label: 'Film Review Cost',
                value: this.physiciandetail && this.physiciandetail.FilmReviewCost || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            FilmReviewCostTypeId: new DynamicLabel({
                label: 'Film Review Cost Type',
                value:  getMetaItemValue(this.filmReviewCostTypes, this.physiciandetail && this.physiciandetail.hasOwnProperty('FilmReviewCostTypeId') && this.physiciandetail.FilmReviewCostTypeId !== null ? this.physiciandetail.FilmReviewCostTypeId : null),
                type: new DynamicFieldType({
                     fieldType: DynamicFieldTypes.Select,
                     inputType: null,
                     scale: null,
                 }),
             }),
            FilmReviewDate: new DynamicLabel({
                label: 'Film Review Date',
                value: this.physiciandetail && this.physiciandetail.FilmReviewDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            FilmReviewFee: new DynamicLabel({
                label: 'Film Review Fee',
                value: this.physiciandetail && this.physiciandetail.FilmReviewFee || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            FilmReviewFeeTypeId: new DynamicLabel({
                label: 'Film Review Fee Type',
                value: getMetaItemValue(this.filmReviewFeeTypes, this.physiciandetail && this.physiciandetail.hasOwnProperty('FilmReviewFeeTypeId') && this.physiciandetail.FilmReviewFeeTypeId !== null ? this.physiciandetail.FilmReviewFeeTypeId : null),
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            FullDayTelephoneDepositionCost: new DynamicLabel({
                label: 'Full Day Telephone Deposition Cost',
                value: this.physiciandetail && this.physiciandetail.FullDayTelephoneDepositionCost || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            FullDayTelephoneDepositionFee: new DynamicLabel({
                label: 'Full Day Telephone Deposition Fee',
                value: this.physiciandetail && this.physiciandetail.FullDayTelephoneDepositionFee || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            FullDayTestimonyCost: new DynamicLabel({
                label: 'Full Day Testimony Cost',
                value: this.physiciandetail && this.physiciandetail.FullDayTestimonyCost || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            FullDayTestimonyFee: new DynamicLabel({
                label: 'Full Day Testimony Fee',
                value: this.physiciandetail && this.physiciandetail.FullDayTestimonyFee || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            HalfDayTelephoneDepositionCost: new DynamicLabel({
                label: 'Half Day Telephone Deposition Cost',
                value: this.physiciandetail && this.physiciandetail.HalfDayTelephoneDepositionCost || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            HalfDayTelephoneDepositionFee: new DynamicLabel({
                label: 'Half Day Telephone Deposition Fee',
                value: this.physiciandetail && this.physiciandetail.HalfDayTelephoneDepositionFee || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            HalfDayTestimonyCost: new DynamicLabel({
                label: 'Half Day Testimony Cost',
                value: this.physiciandetail && this.physiciandetail.HalfDayTestimonyCost || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            HalfDayTestimonyFee: new DynamicLabel({
                label: 'Half Day Testimony Fee',
                value: this.physiciandetail && this.physiciandetail.HalfDayTestimonyFee || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            HourlyCost: new DynamicLabel({
                label: 'Hourly Cost',
                value: this.physiciandetail && this.physiciandetail.HourlyCost || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            HourlyDate: new DynamicLabel({
                label: 'Hourly Date',
                value: this.physiciandetail && this.physiciandetail.HourlyDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            HourlyFee: new DynamicLabel({
                label: 'Hourly Fee',
                value: this.physiciandetail && this.physiciandetail.HourlyFee || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            ImedClaimTypeId: new DynamicLabel({
                label: 'Imed Claim Type',
                value: getMetaItemValue(this.imedClaimTypes as unknown as IMetaItem[], this.physiciandetail && this.physiciandetail.hasOwnProperty('ImedClaimTypeId') ? this.physiciandetail.ImedClaimTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ImeExamConsultationCost: new DynamicLabel({
                label: 'Ime Exam Consultation Cost',
                value: this.physiciandetail && this.physiciandetail.ImeExamConsultationCost || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            ImeExamConsultationDate: new DynamicLabel({
                label: 'Ime Exam Consultation Date',
                value: this.physiciandetail && this.physiciandetail.ImeExamConsultationDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            ImeExamConsultationFee: new DynamicLabel({
                label: 'Ime Exam Consultation Fee',
                value: this.physiciandetail && this.physiciandetail.ImeExamConsultationFee || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            InactiveDate: new DynamicLabel({
                label: 'Inactive Date',
                value: this.physiciandetail && this.physiciandetail.InactiveDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            InactiveReason: new DynamicLabel({
                label: 'Inactive Reason',
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('InactiveReason') && this.physiciandetail.InactiveReason != null ? this.physiciandetail.InactiveReason.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
            }),
            IntroductoryLetterSentDate: new DynamicLabel({
                label: 'Introductory Letter Sent Date',
                value: this.physiciandetail && this.physiciandetail.IntroductoryLetterSentDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            IsPrepayment: new DynamicLabel({
                label: 'Is Prepayment',
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('IsPrepayment') && this.physiciandetail.IsPrepayment != null ? this.physiciandetail.IsPrepayment : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            LiabilityInsuranceExpirationDate: new DynamicLabel({
                label: 'Liability Insurance Expiration Date',
                value: this.physiciandetail && this.physiciandetail.LiabilityInsuranceExpirationDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            LicenseExpirationDate: new DynamicLabel({
                label: 'License Expiration Date',
                value: this.physiciandetail && this.physiciandetail.LicenseExpirationDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            LicenseVerificationDate: new DynamicLabel({
                label: 'License Verification Date',
                value: this.physiciandetail && this.physiciandetail.LicenseVerificationDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            ModifiedById: new DynamicLabel({
                label: 'Modified By',
                value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.physiciandetail && this.physiciandetail.hasOwnProperty('ModifiedById') ? this.physiciandetail.ModifiedById : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            NonCompulsoryNote: new DynamicLabel({
                label: 'Non Compulsory Note',
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('NonCompulsoryNote') && this.physiciandetail.NonCompulsoryNote != null ? this.physiciandetail.NonCompulsoryNote.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
            }),
            NoShowCost: new DynamicLabel({
                label: 'No Show Cost',
                value: this.physiciandetail && this.physiciandetail.NoShowCost || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            NoShowDate: new DynamicLabel({
                label: 'No Show Date',
                value: this.physiciandetail && this.physiciandetail.NoShowDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            NoShowFee: new DynamicLabel({
                label: 'No Show Fee',
                value: this.physiciandetail && this.physiciandetail.NoShowFee || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            NotRequiredForThisSpecialty: new DynamicLabel({
                label: 'Not Required For This Specialty',
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('NotRequiredForThisSpecialty') && this.physiciandetail.NotRequiredForThisSpecialty != null ? this.physiciandetail.NotRequiredForThisSpecialty : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            PhysicianId: new DynamicLabel({
                label: 'Physician',
                value: getMetaItemValue(this.physicians as unknown as IMetaItem[], this.physiciandetail && this.physiciandetail.hasOwnProperty('PhysicianId') ? this.physiciandetail.PhysicianId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            PhysicianLegacyId: new DynamicLabel({
                label: 'Physician Legacy',
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('PhysicianLegacyId') && this.physiciandetail.PhysicianLegacyId != null ? this.physiciandetail.PhysicianLegacyId.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            PhysicianStatusId: new DynamicLabel({
                label: 'Physician Status',
                value: getMetaItemValue(this.physicianStatuses as unknown as IMetaItem[], this.physiciandetail && this.physiciandetail.hasOwnProperty('PhysicianStatusId') ? this.physiciandetail.PhysicianStatusId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            RecordReviewRetainer: new DynamicLabel({
                label: 'Record Review Retainer',
                value: this.physiciandetail && this.physiciandetail.RecordReviewRetainer || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            RecordReviewRetainerCost: new DynamicLabel({
                label: 'Record Review Retainer Cost',
                value: this.physiciandetail && this.physiciandetail.RecordReviewRetainerCost || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            ReportDays: new DynamicLabel({
                label: 'Report Days',
                value: this.physiciandetail && this.physiciandetail.ReportDays || 10,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            Retainer: new DynamicLabel({
                label: 'Retainer',
                value: this.physiciandetail && this.physiciandetail.Retainer || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            RetainerCost: new DynamicLabel({
                label: 'Retainer Cost',
                value: this.physiciandetail && this.physiciandetail.RetainerCost || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            ServiceCompletedOnTrialPeriod: new DynamicLabel({
                label: 'Service Completed On Trial Period',
                value: this.physiciandetail && this.physiciandetail.ServiceCompletedOnTrialPeriod || 0,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            SpecialNote: new DynamicLabel({
                label: 'Special Note',
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('SpecialNote') && this.physiciandetail.SpecialNote != null ? this.physiciandetail.SpecialNote.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
            }),
            TravelAreasOutsideOfVenue: new DynamicLabel({
                label: 'Travel Areas Outside Of Venue',
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('TravelAreasOutsideOfVenue') && this.physiciandetail.TravelAreasOutsideOfVenue != null ? this.physiciandetail.TravelAreasOutsideOfVenue.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
            }),
            VirtualTestimonyCost: new DynamicLabel({
                label: 'Virtual Testimony Cost',
                value: this.physiciandetail && this.physiciandetail.VirtualTestimonyCost || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            VirtualTestimonyFee: new DynamicLabel({
                label: 'Virtual Testimony Fee',
                value: this.physiciandetail && this.physiciandetail.VirtualTestimonyFee || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            WillCommentOnPediatricCases: new DynamicLabel({
                label: 'Will Comment On Pediatric Cases',
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('WillCommentOnPediatricCases') && this.physiciandetail.WillCommentOnPediatricCases != null ? this.physiciandetail.WillCommentOnPediatricCases : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            WillingToTravel: new DynamicLabel({
                label: 'Willing To Travel',
                value: this.physiciandetail && this.physiciandetail.hasOwnProperty('WillingToTravel') && this.physiciandetail.WillingToTravel != null ? this.physiciandetail.WillingToTravel : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}

