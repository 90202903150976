import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { IDuplicateAddressBook } from '@model/interfaces/custom/duplicate-address-book';
import { MetaAddressBookTypeIdEnums, AddressBookTypeIdEnums, PhysicianStatusesIdEnums, ClaimTypeEnums } from '../common/constants/Enums';
import { IAddressBookWithPrimaryAddress } from '@model/interfaces/custom/address-book-with-primary-address';
import { IPhoneNumber } from '@model/interfaces/phone-number';
import { IAddress } from '@model/interfaces/address';
import { IAddressBook } from '@model/partials/address-book.partial';
import { IEmailAddress } from '@model/interfaces/email-address';
import { IIndividual } from '@model/interfaces/individual';
import { AuthService } from '@mt-ng2/auth-module';
import { IPhysician } from '@model/interfaces/physician';
import { IPhysicianDetail } from '@model/interfaces/physician-detail';
import { IPhysicianStatusLog } from '@model/interfaces/physician-status-log';
import { IPhysicianFollowupLog } from '@model/interfaces/physician-followup-log';
import { AddressBookWithPrimaryAddress } from './address-book-with-primary-address.library';
import { map } from 'rxjs/operators';
import { IAddressBookOptions } from '@model/interfaces/custom/address-book-options';
import { AddressBookOptions } from './address-book-options.library';
import { IAddressBooksForList } from '@model/interfaces/address-books-for-list';
import { INf10CCsDTO } from '@model/interfaces/custom/nf10-ccs-dto';
import { IDupAddressBookDto } from '@model/interfaces/custom/dup-address-book-dto';
import { ICourtSubpoenaDocument } from '@model/interfaces/court-subpoena-document';
import { IStrivenCategory } from '@model/partials/striven-category';
import { IStrivenCreationResult } from '@model/partials/striven-category.d copy';
import { IPhysicianSpecialty } from '@model/interfaces/physician-specialty';
import { IPhysicianSpecialtyDetail } from '@model/interfaces/physician-specialty-detail';

export const emptyAddressBook: IAddressBook = {
    AddressBookTypeId: null,
    Archived: false,
    Attention: null,
    CreatedById: null,
    DateCreated: new Date(),
    Ein: null,
    FacilityName: null,
    Id: 0,
    Individuals: [],
    MetaAddressBookTypeId: MetaAddressBookTypeIdEnums.Facility_Organization,
    ModifiedById: null,
    Naic: null,
    Notes: null,
    PrintOnCheckAs: null,
};

export interface ICreateAddressBookFormValue {
    AddressBook: IAddressBook;
    Address?: IAddress;
    EmailAddress?: IEmailAddress;
    PhoneNumber?: IPhoneNumber;
    Individual: IIndividual;
    IndividualEmailAddress?: IEmailAddress;
    IndividualPhoneNumber?: IPhoneNumber;
    Physician?: IPhysician;
    PhysicianSpecialty? : any;
    PhysicianSpecialtyDetail? : any;
}

export interface IHasCreatedBy {
    CreatedById: number;
    DateCreated: Date;
    IsPrimary?: boolean;
}

export function addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

@Injectable()
export class AddressBookService extends BaseService<IAddressBook> {
    constructor(public http: HttpClient, public authService: AuthService) {
        super('/address-books', http);
    }

    getEmptyAddressBook(): IAddressBook {
        return { ...emptyAddressBook };
    }

    initializeAddressBook(addrressBook: any): Observable<IAddressBook> {
        return this.http.post<IAddressBook>('/address-books/initialize', addrressBook);
    }

    getPotentialDuplicates(addrressBook: any): Observable<IDuplicateAddressBook[]> {
        return this.http.post<IDuplicateAddressBook[]>('/address-books/get-duplicates', addrressBook);
    }

    savePhones(addressBookId: number, phones: IPhoneNumber[]): any {
        return this.http.put<number>(`/phones/addressbooks/${addressBookId}/phones`, phones);
    }

    getWithPrimaryAddressofFacilityWithOrganizationTypeONLY(includeArchived: boolean): Observable<AddressBookWithPrimaryAddress[]> {
        return this.http
            .get<IAddressBookWithPrimaryAddress[]>(`/address-books/primary-addresses/${includeArchived}`)
            .pipe(map((arrayObject) => arrayObject.map((item) => new AddressBookWithPrimaryAddress(item))));
    }

    getFacilities(data: SearchParams): Observable<IAddressBookOptions[]> {
        const params = this.getHttpParams(data);
        return this.http
            .get<IAddressBookOptions[]>(`/address-books/facilities/`, { params: params })
            .pipe(map((arrayObject) => arrayObject.map((item) => new AddressBookOptions(item))));
    }

    getFacilityAddresses(facilityId: number): Observable<IAddress[]> {
        return this.http.get<IAddress[]>(`/address-books/${facilityId}/addresses/`);
    }

    getFacilityPhones(facilityId: number): Observable<IPhoneNumber[]> {
        return this.http.get<IPhoneNumber[]>(`/address-books/${facilityId}/phones/`);
    }

    getFacilityEmails(facilityId: number): Observable<IEmailAddress[]> {
        return this.http.get<IEmailAddress[]>(`/address-books/${facilityId}/emails/`);
    }

    getFacilityName(facilityId: number): Observable<string> {
        return this.http.get<string>(`/address-books/${facilityId}/facility/`);
    }

    searchAddressBooks(data: SearchParams): any {
        const params = this.getHttpParams(data);
        return this.http.get<IAddressBooksForList>(`/address-books/_searchAddressBookPayload`, { observe: 'response', params: params });
    }

    archive(id: number, userId: number): any {
        return this.http.put<number>(`/address-books/archive/${id}/user/${userId}`, {});
    }

    getCreateAddressBookPayload(formValueParam: ICreateAddressBookFormValue, metaTypeId: MetaAddressBookTypeIdEnums): IAddressBook {
        const formValue = { ...formValueParam };
        const isIndividual = metaTypeId === MetaAddressBookTypeIdEnums.Individual;

        const addressBook: IAddressBook = this.setCreatedValues(formValue.AddressBook);
        addressBook.MetaAddressBookTypeId = metaTypeId;
        if (isIndividual) {
            addressBook.FacilityName = '';
        }
        if (!isIndividual) {
            if (formValue.PhoneNumber) {
                const phoneNumber = this.setCreatedValues(formValue.PhoneNumber, true);
                addressBook.PhoneNumbers = [phoneNumber];
            }

            if (formValue.EmailAddress) {
                const emailAddress = this.setCreatedValues(formValue.EmailAddress, true);
                addressBook.EmailAddresses = [emailAddress];
            }

            if (formValue.Address) {
                addressBook.PhysicalAddress = this.setCreatedValues(formValue.Address, true);
                addressBook.MailingAddress = this.setCreatedValues(formValue.Address, true);
            }
        }

        if (formValue.Address) {
            const address: IAddress = this.setCreatedValues(formValue.Address, true);
            addressBook.OverridenAddresses = [address];
        }

        this.setIndividuals(addressBook, formValue, isIndividual);

        return addressBook;
    }

    private setIndividuals(addressBook: IAddressBook, formValue: ICreateAddressBookFormValue, isIndividual: boolean): void {
        const individual = this.getIndividualFromCreateAddressBookFormValue(addressBook, formValue, isIndividual);

        if (individual) {
            addressBook.Individuals = [individual];
        } else {
            addressBook.Individuals = [];
        }
    }

    getIndividualFromCreateAddressBookFormValue(
        addressBook: IAddressBook,
        formValue: ICreateAddressBookFormValue,
        isIndividual: boolean,
    ): IIndividual {
        const individualEntity = formValue.Individual;

        // if no valid individual, then just set return null
        if (!(individualEntity && individualEntity.FirstName && individualEntity.LastName)) {
            return null;
        }

        const individual: IIndividual = this.setCreatedValues(individualEntity);
        if (addressBook.Id) {
            individual.AddressBookId = addressBook.Id;
        }
        individual.PhoneNumbers = [];
        individual.EmailAddresses = [];

        const individualPhoneNumber = isIndividual ? formValue.PhoneNumber : formValue.IndividualPhoneNumber;
        if (individualPhoneNumber && individualPhoneNumber.Phone && individualPhoneNumber.PhoneTypeId) {
            const phoneNumber = this.setCreatedValues(individualPhoneNumber, true);
            individual.PhoneNumbers = [phoneNumber];
        }

        const individualEmailAddress = isIndividual ? formValue.EmailAddress : formValue.IndividualEmailAddress;
        if (individualEmailAddress && individualEmailAddress.Email && individualEmailAddress.EmailTypeId) {
            const emailAddress = this.setCreatedValues(individualEmailAddress, true);
            individual.EmailAddresses = [emailAddress];
        }

        this.setPhysicianStatus(addressBook, individual, formValue);

        return individual;
    }


    private setPhysicianStatus(addressBook: IAddressBook, individual: IIndividual, formValue: ICreateAddressBookFormValue,): void {
        if (!this.isAPhysician(addressBook)) {
            return;
        }

        const currentUserId = this.authService.currentUser.getValue().Id;
        const todaysDate = new Date();
        const toCreatePhysician: IPhysician = { Id: 0, Auto: formValue.Physician.Auto, WorkersComp: formValue.Physician.WorkersComp, IndividualId: 0, GeneralLiability: formValue.Physician.GeneralLiability };

        const createPhysicianDetail = (claimTypeId: ClaimTypeEnums): IPhysicianDetail => {
            const detail = this.setCreatedValues({} as IPhysicianDetail, false);
            detail.IntroductoryLetterSentDate = new Date();
            detail.PhysicianStatusId = PhysicianStatusesIdEnums.Introductory_Letter_Sent;
            detail.ImedClaimTypeId = claimTypeId;

            const toCreatePhysicianLog = <IPhysicianStatusLog>{};
            toCreatePhysicianLog.ChangedById = currentUserId;
            toCreatePhysicianLog.ChangedDate = todaysDate;
            toCreatePhysicianLog.PhysicianStatusId = PhysicianStatusesIdEnums.Introductory_Letter_Sent;
            detail.PhysicianStatusLogs = [toCreatePhysicianLog];

            return detail;
        };

        const physicianDetails: IPhysicianDetail[] = [];

        if (toCreatePhysician.GeneralLiability) {
            physicianDetails.push(createPhysicianDetail(ClaimTypeEnums.GL));
        }

        if (toCreatePhysician.Auto) {
            physicianDetails.push(createPhysicianDetail(ClaimTypeEnums.Auto));
        }

        if (toCreatePhysician.WorkersComp) {
            physicianDetails.push(createPhysicianDetail(ClaimTypeEnums.WC));
        }

        toCreatePhysician.PhysicianDetails = physicianDetails;

        toCreatePhysician.PhysicianSpecialties = [];
        if(formValue.PhysicianSpecialty.SpecialtyId && formValue.PhysicianSpecialty.SpecialtyId.length > 0){
            toCreatePhysician.PhysicianSpecialties = formValue.PhysicianSpecialty.SpecialtyId.map((specialtyId: number) => ({
                PhysicianId: 0,
                SpecialtyId: specialtyId,
                CreatedById: this.authService.currentUser.getValue().Id,
                DateCreated: new Date(),
            }));
        }


        toCreatePhysician.PhysicianSpecialtyDetails = [];
        if(formValue.PhysicianSpecialtyDetail.SpecialtyDetailId && formValue.PhysicianSpecialtyDetail.SpecialtyDetailId.length > 0) {
            toCreatePhysician.PhysicianSpecialtyDetails = formValue.PhysicianSpecialtyDetail.SpecialtyDetailId.map((specialtyDetailId: number) => ({
                PhysicianId: 0,
                SpecialtyDetailId: specialtyDetailId,
                CreatedById: this.authService.currentUser.getValue().Id,
                DateCreated: new Date(),
            }));
        }

        const toCreatePhysicianFollowupLog = <IPhysicianFollowupLog>{};
        toCreatePhysicianFollowupLog.AddedById = currentUserId;
        toCreatePhysicianFollowupLog.AddedDate = todaysDate;
        toCreatePhysicianFollowupLog.FollowupDate = addDays(todaysDate, 7);
        toCreatePhysicianFollowupLog.ImedClaimTypeId = ClaimTypeEnums.GL;
        toCreatePhysician.PhysicianFollowupLogs = [toCreatePhysicianFollowupLog];
        individual.Physicians = [toCreatePhysician];
    }

    private isAPhysician(addressBook: IAddressBook): boolean {
        return (
            addressBook.AddressBookTypeId === +AddressBookTypeIdEnums.Physician_panel ||
            addressBook.AddressBookTypeId === +AddressBookTypeIdEnums.Expert_panel
        );
    }

    private setCreatedValues<T extends IHasCreatedBy>(valueToSet: T, setIsPrimary?: boolean): T {
        valueToSet.CreatedById = this.authService.currentUser.getValue().Id;
        valueToSet.DateCreated = new Date();
        if (setIsPrimary) {
            valueToSet.IsPrimary = true;
        }
        return valueToSet;
    }

    saveLetterheadPhoto(facilityId: number, photo: File): any {
        const formData: FormData = new FormData();
        formData.append('file', photo, photo.name);
        return this.http.post(`/address-books/${facilityId}/letterhead`, formData);
    }

    deleteLetterheadPhoto(facilityId: number): any {
        return this.http.delete(`/address-books/${facilityId}/letterhead`);
    }

    saveCourtSealPhoto(facilityId: number, photo: File): any {
        const formData: FormData = new FormData();
        formData.append('file', photo, photo.name);
        return this.http.post(`/address-books/${facilityId}/courtseal`, formData);
    }

    deleteCourtSealPhoto(facilityId: number): any {
        return this.http.delete(`/address-books/${facilityId}/courtseal`);
    }

    saveWetSignaturePhoto(facilityId: number, photo: File): any {
        const formData: FormData = new FormData();
        formData.append('file', photo, photo.name);
        return this.http.post(`/address-books/${facilityId}/wetsignature`, formData);
    }

    deleteWetSignaturePhoto(facilityId: number): any {
        return this.http.delete(`/address-books/${facilityId}/wetsignature`);
    }

    getAddressBooksByIds(addressBookIds: number[]): Observable<IAddressBook[]> {
        return this.http.post<IAddressBook[]>(`/address-books/get-by-ids/`, addressBookIds);
    }

    createNF10CC(addressBookId: number): Observable<INf10CCsDTO> {
        return this.http.get<INf10CCsDTO>(`/address-books/create-nf10cc/${addressBookId}`);
    }

    duplicateAddressBook(Id: number, addressBookType: number): Observable<IDupAddressBookDto> {
        return this.http.post<IDupAddressBookDto>(`/address-books/duplicate/${Id}`, addressBookType);
    }

    getSubpoenaDocumentsForAddressBook(addressBookId: number): Observable<ICourtSubpoenaDocument[]> {
        return this.http.get<ICourtSubpoenaDocument[]>(`/address-books/subpoena-documents/${addressBookId}`);
    }

    deleteSubpoenaDocument(addressBookId: number, subpoenaDocumentId: number): any {
        return this.http.put<number>(`/address-books/subpoena-documents/${subpoenaDocumentId}`, {});
    }

    createSubpoenaDocument(addressBookId: number, subpoenaDocument: ICourtSubpoenaDocument): Observable<number> {
        return this.http.post<number>(`/address-books/subpoena-documents/create/${addressBookId}`, subpoenaDocument);
    }

    updateSubpoenaDocument(addressBookId: number, subpoenaDocument: ICourtSubpoenaDocument): any {
        return this.http.put(`/address-books/subpoena-documents/update/${addressBookId}`, subpoenaDocument);
    }

    canUploadWetSignaturePic(addressBookId: number): any {
        return this.http.get(`/address-books/${addressBookId}/canUploadWetSignature`);
    }

    createStrivenClient(name: string, primaryAddress:IAddress, categoryId: number): Observable<IStrivenCreationResult> {
        return this.http.post<IStrivenCreationResult>(`/address-books/striven-client`, {
            Id: 0,
            Name: name,
            PrimaryAddress: {
                Address1: primaryAddress?.Address1,
                Address2: primaryAddress?.Address2,
                City: primaryAddress?.City,
                State: primaryAddress?.State?.StateCode,
                PostalCode: primaryAddress?.Zip
            },
            Categories: [{
                Id: categoryId
            }]
        });
    }

    getStrivenClientCategories(): Observable<IStrivenCategory[]> {
        return this.http.get<IStrivenCategory[]>(`/address-books/striven-categories/client`);
    }

    createStrivenVendor(name: string, primaryAddress: IAddress, categoryId: number): Observable<IStrivenCreationResult> {
        return this.http.post<IStrivenCreationResult>(`/address-books/striven-vendor`, {
            Id: 0,
            Name: name,
            Categories: [
                {
                    Id: categoryId,
                },
            ],
            PrimaryAddress: {
                Address1: primaryAddress?.Address1,
                Address2: primaryAddress?.Address2,
                City: primaryAddress?.City,
                State: primaryAddress?.State?.StateCode,
                PostalCode: primaryAddress?.Zip,
            },
        });
    }

    getStrivenVendorCategories(): Observable<IStrivenCategory[]> {
        return this.http.get<IStrivenCategory[]>(`/address-books/striven-categories/vendor`);
    }
}
