import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, ViewRef } from '@angular/core';
import { UntypedFormGroup, AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { IDynamicField, DynamicField, } from '@mt-ng2/dynamic-form';
import { emptyAddress } from '../addresses/address.service';
import { AddressDynamicConfig } from '../addresses/address.dynamic-config';
import { IState } from '@model/interfaces/state';
import { IAddressType } from '@model/interfaces/address-type';
import { AddressTypeService } from '../addresses/addresstype.service';
import { CommonService } from '../../common/services/common.service';
import { CountyService } from '@app-shared/services/county.service';
import { debounceTime } from 'rxjs/operators';
import { safeDetectChanges } from '../../common/cdr-safety/cdr-safety.library';
import { AddressDynamicControlsPartial } from '@model/partials/address-partial.form-controls';
import { IAddress } from '@model/interfaces/address';

@Component({
    selector: 'app-primary-address',
    templateUrl: './primary-address.component.html',
})
export class PrimaryAddressComponent implements OnInit, OnDestroy {
    @Input() form: UntypedFormGroup;
    @Input() overrideFormGroup = 'Address';

    private _required = true;
    @Input('required')
    get required(): boolean {
        return this._required;
    }
    set required(value: boolean) {
        this._required = value;
        this.updateRequired();
    }

    addressConfig: IDynamicField[];
    addressControls: any;
    addressControlsView: any;
    formFactory: AddressDynamicConfig<IAddress>;
    addressTypes: IAddressType[];
    states: IState[];
    formCreated = false;
    selectedAddressTypeIdsArray: number[] = [];

    county: string;

    subscriptions = new Subscription();

    constructor(
        private addressTypeService: AddressTypeService,
        private commonService: CommonService,
        private countyService: CountyService,
        private cdr: ChangeDetectorRef,
        private fb: UntypedFormBuilder,
    ) { }

    ngOnInit(): void {
        this.addressControls = <AddressDynamicControlsPartial>this.addressControls;

        forkJoin(this.addressTypeService.getItems(), this.commonService.getStates()).subscribe((forkJoinResponses) => {
            const [addressTypes, states] = forkJoinResponses;
            this.createForm(addressTypes, states as IState[]);
            safeDetectChanges(this.cdr);
        });
    }

    createForm(addressTypes: IAddressType[], states: IState[]): void {
        this.formFactory = new AddressDynamicConfig<IAddress>(emptyAddress, null);
        this.addressTypes = addressTypes;
        this.states = states;
        this.getControls();
        this.formCreated = true;
        this.cdr.detectChanges();
    }


    getControls(): void {
        emptyAddress.AddressTypes = [];
        const viewAndFormAddressControl = new AddressDynamicControlsPartial(emptyAddress, {
            addressTypes: this.addressTypes,
            formGroup: this.overrideFormGroup,
            selectedAddressTypeIdsArray: this.selectedAddressTypeIdsArray,
            states: this.states,
        });
        this.addressControls = viewAndFormAddressControl.Form;
        this.addressControlsView = viewAndFormAddressControl.View;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.cdr.detach();
    }

    setCountyValue(zipValue: string): void {
        if (!zipValue || zipValue.length < 5) {
            this.county = '';
            return;
        }
        this.countyService.getByZip(zipValue).subscribe((answer) => {
            this.county = answer ? answer.CountyName : '';
        });
    }

    updateRequired(): void {
        const phoneFormGroup = this.form.get(this.overrideFormGroup) as UntypedFormGroup;
        if (phoneFormGroup) {
            for (const key in phoneFormGroup.controls) {
                if (Object.prototype.hasOwnProperty.call(phoneFormGroup.controls, key)) {
                    (phoneFormGroup.controls[key] ).mtSetRequired(this.required);
                }
            }
        }
    }

    getCountyName(): void {
        this.countyService.getByZip(this.form.value.Address.Zip as string).subscribe((answer) => {
            if (answer) {
                this.county = answer.CountyName;
            }
        });
    }

    addressTypeSelectionChanged(event: any): void {
        this.selectedAddressTypeIdsArray = event === null ? [] : event;
        this.getControls();
    }
}
