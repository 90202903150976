import { DynamicField, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { IPhysicianProfileAssociationDynamicControlsParameters, PhysicianProfileAssociationDynamicControls } from '@model/form-controls/physician-profile-association.form-controls';
import { IPhysicianProfileAssociation } from '@model/interfaces/physician-profile-association';
import { IPhysician } from '@model/interfaces/physician';

export class PhysicianProfileAssociationDynamicControlsPartial extends PhysicianProfileAssociationDynamicControls {
    constructor(physicianrecruitmentPartial?: IPhysicianProfileAssociation, additionalParameters?: IPhysicianProfileAssociationDynamicControlsParameters) {
        super(physicianrecruitmentPartial, additionalParameters);

        (<DynamicField>this.View.DuplicatePhysicianId).label = 'Physician';
        (<DynamicField>this.Form.DuplicatePhysicianId).label = 'Physician';
        (<DynamicField>this.Form.DuplicatePhysicianId).labelHtml = '<label>Physician</label>';
        (<DynamicField>this.Form.DuplicatePhysicianId).type.inputType = SelectInputTypes.MultiselectDropdown;
        (<DynamicField>this.Form.DuplicatePhysicianId).options = (<DynamicField>this.Form.DuplicatePhysicianId).options.map((physician: IPhysician) => {
            return Object.assign(physician, { Name: `${physician.Individual.FirstName} ${physician.Individual.LastName} - ${physician.Individual.Id}`});
        });
        <DynamicField>this.Form.DuplicatePhysicianId.setRequired(false);
    }
}
