import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { finalize } from 'rxjs/operators';
import { DynamicField, DynamicLabel, IDynamicFormConfig } from '@mt-ng2/dynamic-form';
import { SubpoenaServiceService } from '../subpoena-service.service';
import { SubpoenaServiceDynamicConfig } from '../supoena-service.dynamic-config';
import { ISubpoenaService } from '@model/interfaces/subpoena-service';
import { SubpoenaServiceDynamicControls } from '@model/form-controls/subpoena-service.form-controls';

@Component({
    selector: 'app-subpoena-radiology-facility-info',
    templateUrl: './radiology-facility-info.component.html',
})
export class SubpoenaRadiologyFacilityComponent implements OnInit, OnChanges {
    @Input() subpoenaServiceModel: ISubpoenaService;
    @Input() isAddingFilmInfo = false;
    isEditing: boolean;

    config: IDynamicFormConfig;
    subpoenaServiceForm: any;
    subpoenaFormFactory: SubpoenaServiceDynamicConfig<ISubpoenaService>;

    doubleClickIsDisabled = false;
    controls: any;

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
        private subpoenaService: SubpoenaServiceService,
    ) { }

    ngOnInit(): void {
        this.config = { formObject: [], viewOnly: [] };
        this.isEditing = false;
        this.setConfig();
    }

    ngOnChanges(): void {
        this.setConfig();
    }

    setConfig(): void {
        this.subpoenaServiceForm = this.fb.group({
            SubpoenaService: this.fb.group({}),
        });

        this.controls = new SubpoenaServiceDynamicControls(this.subpoenaServiceModel);

        this.subpoenaFormFactory= new SubpoenaServiceDynamicConfig<ISubpoenaService>(this.subpoenaServiceModel,null,null, [
            'RadiologyFacility',
        ]);
        this.config = this.subpoenaFormFactory.getForUpdate();

        this.viewOnly = this.config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x) => new DynamicField(x));
    }

    edit(): void {
        this.isEditing = true;
    }

    cancelClick(): void {
        this.isEditing = false;
    }

    formSubmitted(): void {
        this.subpoenaFormFactory.assignFormValues(
            this.subpoenaServiceModel,
            this.subpoenaServiceForm.value.SubpoenaService as ISubpoenaService,
        );
        this.subpoenaService
            .update(this.subpoenaServiceModel).subscribe(
                () => {
                   this.isEditing = false;
                   this.success();
                   this.subpoenaService.emitChange(this.subpoenaServiceModel);
                   this.setConfig();
                },
                (error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    this.error();
                },
            );
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }
}
