import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IAutoIssue } from '@model/interfaces/auto-issue';
import { ServiceIdEnums } from '../../common/constants/Enums';
import { ICreateAutoIssueDTO } from '@model/interfaces/custom/create-autoissue.dto';
import { Observable } from 'rxjs';

export const emptyAutoRecordReviewIssue: IAutoIssue = {
    AdLsThatMayAffectThisClaim: false,
    Apportionment: false,
    AppropriatenessOfTreatment: true,
    CausalRelationship: true,
    CompleteHistory: true,
    DegreeOfDisability: false,
    Diagnosis: false,
    FunctionalCapabilityEvaluation: false,
    HistoryOfAllegedInjury: false,
    Id: 0,
    Mmi: false,
    NeedForContinuedFollowUp: false,
    NeedForDme: false,
    NeedForEssentialServicesHouseholdHelp: false,
    NeedForFurtherDiagnosticTesting: false,
    NeedForFurtherTreatment: false,
    NeedForFurtherTreatmentWithinYourScope: false,
    NeedForInjections: false,
    NeedForMassage: false,
    NeedForPhysicalTherapy: false,
    NeedForPrescriptionMedication: false,
    NeedForSurgery: false,
    NeedForTransportation: false,
    ObjectiveFindingsOnExamDiagnosis: true,
    OccupationalHistory: false,
    PastSubsequentMedicalHistory: false,
    Permanency: false,
    PreExistingConditions: true,
    PresentComplaints: true,
    RecommendationForFurtherTreatment: false,
    ReturnToWorkRestrictionsAdl: false,
    ReviewOfRecords: true,
    Slu: false,
    SpecificTreatmentQuestions: null,
};

export const emptyAutoPeerReviewIssue: IAutoIssue = {
    AdLsThatMayAffectThisClaim: true,
    Apportionment: false,
    AppropriatenessOfTreatment: true,
    CausalRelationship: true,
    CompleteHistory: true,
    DegreeOfDisability: true,
    Diagnosis: true,
    FunctionalCapabilityEvaluation: false,
    HistoryOfAllegedInjury: true,
    Id: 0,
    Mmi: false,
    NeedForContinuedFollowUp: false,
    NeedForDme: false,
    NeedForEssentialServicesHouseholdHelp: false,
    NeedForFurtherDiagnosticTesting: false,
    NeedForFurtherTreatment: false,
    NeedForFurtherTreatmentWithinYourScope: true,
    NeedForInjections: false,
    NeedForMassage: false,
    NeedForPhysicalTherapy: false,
    NeedForPrescriptionMedication: false,
    NeedForSurgery: false,
    NeedForTransportation: false,
    ObjectiveFindingsOnExamDiagnosis: true,
    OccupationalHistory: true,
    PastSubsequentMedicalHistory: true,
    Permanency: false,
    PreExistingConditions: true,
    PresentComplaints: true,
    RecommendationForFurtherTreatment: false,
    ReturnToWorkRestrictionsAdl: true,
    ReviewOfRecords: true,
    Slu: false,
    SpecificTreatmentQuestions: null,
};

@Injectable({
    providedIn: 'root',
})
export class AutoIssueService extends BaseService<IAutoIssue> {
    constructor(public http: HttpClient) {
        super('/autoissues', http);
    }

    getEmptyAutoIssue(serviceIds: number[]): IAutoIssue {
        if (serviceIds.length > 1 || serviceIds.length === 0) {
            return this.getEmptyComboAutoIssue();
        } else {
            switch (serviceIds[0] as ServiceIdEnums) {
                case ServiceIdEnums.AUTO_Record_Review:
                    return { ...emptyAutoRecordReviewIssue };
                case ServiceIdEnums.AUTO_Peer_Review:
                    return { ...emptyAutoPeerReviewIssue };
                default:
                    return this.getEmptyComboAutoIssue();
            }
        }
    }

    getEmptyComboAutoIssue(): IAutoIssue {
        const returnObject = { ...emptyAutoRecordReviewIssue };

        for (const key of Object.keys(returnObject)) {
            returnObject[key] = returnObject[key] || emptyAutoPeerReviewIssue[key];
        }

        return returnObject;
    }

    createAutoIssue(autoIssue: ICreateAutoIssueDTO): Observable<number> {
        return this.http.post<number>(`/autoissues/create`, autoIssue);
    }
}
