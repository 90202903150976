import { DynamicField, DynamicFieldType, DynamicFieldTypes, IDynamicFieldType, IDynamicField, InputTypes } from '@mt-ng2/dynamic-form';
import { IService } from '@model/interfaces/service.d';
import { IServicePartial } from '@model/partials/service.d.partial';
import { Component, Input, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { AuthService } from '@mt-ng2/auth-module';
import { IAddress } from '@model/interfaces/address.d';
import { ISoftPopUp, ISoftPopUpValidationFunctionResult, SoftPopUpResults } from '@model/interfaces/custom/soft-popup';
import { ImedClaimService } from '../imedclaim.service';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { ImedClaimDynamicConfig } from '../imed-claim.dynamic-config';
import { ImedClaimStatusService } from '../imedclaimstatus.service';
import { IIMedClaimContactsInfo } from './imed-claim-contacts-info';
import { IImedClaimPartial } from '@model/partials/imed-claim-partial';
import { IImedClaimStatus } from '@model/interfaces/imed-claim-status';
import { ServiceService, emptyService } from '../services/service.service';
import { SoftPopUpValidationFunction } from '../../common/directives/click-popup-directive';
import { AddressBooksSelectService } from '../../common/address-book-select-list/address-books-select.service';
import { IAddressBookSelectEntity } from '../../common/address-book-select-list/address-books-select-entity';
import { ImedClaimRequestorTypeService } from '../imed-claim-requestor-type.service';
import { IRequestorType } from '@model/interfaces/requestor-type';
import { finalize, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { PopUpService, IPopUpParams } from '../../common/services/popup-service';
import { PopUpTypeEnums, IMedClaimStatusIdEnums, ServiceIdEnums, ClaimTypeEnums } from '../../common/constants/Enums';
import { StateService } from '@app-shared/services/state.service';
import { AddressService } from '../../address-books/addresses/address.service';
import { ImedClaimServiceService } from '../services/imedclaimservice.service';
import { trimObjectStringProperties } from '../../common/custom/trimObjectStringProperties';
import { UserRoleService } from '../../user-roles/user-role.service';
import { UserService } from '../../users/user.service';
import { IPhoneNumber } from '@model/interfaces/phone-number';
import { IEmailAddress } from '@model/interfaces/email-address';
import { ImedClaimTypes } from '@model/ImedClaimTypes';
import { AutoIssueService } from '../services/auto-issue.service';
import { WCIssueService } from '../services/wc-issue.service';
import { IModalOptions, IModalWrapperApi } from '@mt-ng2/modal-module';
import { IState } from '@model/interfaces/state';
import { AddressTypeService } from '../../address-books/addresses/addresstype.service';
import { IAddressType } from '@model/interfaces/address-type';
import { IIMedClaimContactsInfoPartial } from '@model/interfaces/imed-claim-contacts-info';

export interface IAddressBookTooltipEntity {
    id: number;
    type: string;
}

@Component({
    selector: 'app-imed-claim-basic-info',
    styles: [
        `
            .template-div {
                position: relative;
            }
            .template-edit-icon {
                position: absolute;
                top: 9px;
                right: 8px;
            }
            .float-right {
                position: absolute;
                right: 35px;
            }
            .shared-list {
                display: block;
                padding: 5px;
                margin: 10px 0 10px;
                word-break: break-all;
                word-wrap: break-word;
                border: 1px solid #ccc;
                border-radius: 4px;
            }
            .shared-list .col-md-2 {
                padding: 0;
            }
        `,
    ],
    templateUrl: './imed-claim-basic-info.component.html',
})
export class ImedClaimBasicInfoComponent implements OnInit {
    @Input() imedClaim: IImedClaimPartial;
    @Input() canEdit: boolean;
    @Input() imedClaimContacts: IIMedClaimContactsInfo & IIMedClaimContactsInfoPartial;
    @Input() imedClaimTypeId: number;
    @Input() emailSharedEntityId: number;
    @Input() phoneNumberSharedEntityId: number;
    @ViewChild('saveBtn') saveBtn: ElementRef;

    modalOptions: IModalOptions = {
        cancelButtonColor: '#8AC175',
        cancelButtonText: 'Continue',
        confirmButtonText: 'Enter new case',
        showCancelButton: true,
        width: 600,
    };
    duplicateCaseModalApi: IModalWrapperApi;

    isEditing: boolean;
    isHovered: boolean;
    firstConfig: any = {};
    inBetweenConfig: any = {};
    firstFormFactory: ImedClaimDynamicConfig<IImedClaim>;
    inBetweenFormFactory: ImedClaimDynamicConfig<IImedClaim>;

    doubleClickIsDisabled = false;

    allStates: IState[];
    imedClaimStatuses: IImedClaimStatus[];
    imedClaimServices: ImedClaimService[];
    allServices: IService[];
    imedServices: IServicePartial[];
    userId: number;
    userRoleServiceTypeIds: number[] = [];
    requestorTypes: IRequestorType[];
    serviceForm: UntypedFormGroup;

    imedClaimAddress: IAddress;
    imedClaimPhoneNumber: IPhoneNumber;
    imedClaimEmailAddress: IEmailAddress;
    formIsRendered = false;
    softPopUpErrorMessages: SoftPopUpValidationFunction;
    isHeaderForCourRelatedServiceShown = false;
    includedServices: IServicePartial[] = [];

    subscriptions: Subscription = new Subscription();
    claimantFullAddress = 'No address provided';
    showAddressComponent = false;

    entityForTooltip: IAddressBookTooltipEntity;
    showingOnServicePage = false;
    schedulingAssignedToUsers: { Id: number; Name: string }[];
    compilationAssignedToUsers: { Id: number; Name: string }[];
    reportAssignedToUsers: { Id: number; Name: string }[];

    duplicates: IImedClaim[];

    duplicateWarningDismissed = false;

    canAdd: boolean;

    phoneNumbers: IPhoneNumber[] = [];
    emailAddresses: IEmailAddress[] = [];
    address: IAddress;
    addressTypes: IAddressType[];

    isEditingPhoneNumbers = false;
    isEditingEmailAddresses = false;

    caseSettledFields: DynamicField[] = [];
    caseTransferredFields: DynamicField[] = [];

    caseSettled = false;
    caseTransferred = false;
    caseSettledDate: Date;
    caseTransferredDate: Date;

    caseSettledDateField: DynamicField;
    caseSettledField: DynamicField;

    caseTransferredDateField: DynamicField;
    medicalCanvassingFiled: DynamicField;

    get isForAllStates(): boolean {
        return this.imedClaim.AllStates;
    }

    constructor(
        private imedClaimService: ImedClaimService,
        private imedClaimStatusService: ImedClaimStatusService,
        private serviceService: ServiceService,
        private notificationsService: NotificationsService,
        private router: Router,
        private addressService: AddressService,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private addressSelectService: AddressBooksSelectService,
        private requestorTypeService: ImedClaimRequestorTypeService,
        private popUpService: PopUpService,
        private stateService: StateService,
        private imedClaimServiceService: ImedClaimServiceService,
        private userRoleService: UserRoleService,
        private userService: UserService,
        private route: ActivatedRoute,
        private autoIssueService: AutoIssueService,
        private wcIssueService: WCIssueService,
        private cdr: ChangeDetectorRef,
        private addressTypeService: AddressTypeService,
    ) {}

    ngOnInit(): void {
        this.serviceForm = this.fb.group({
            Address: this.fb.group({}),
            ImedClaim: this.fb.group({}),
            CaseDates: this.fb.group({
                CaseSettled: false,
                CaseSettledDate: null,
                CaseTransferred: false,
                CaseTransferredDate: null,
            }),
        });

        this.isEditing = false;
        this.canAdd = this.canEdit;
        this.firstConfig = { formObject: [], viewOnly: [] };
        this.inBetweenConfig = { formObject: [], viewOnly: [] };

        const imedClaimTypeId = this.imedClaim.Id > 0 ? this.imedClaim.ClaimTypeId : this.imedClaim.Id === 0 ? this.imedClaimTypeId : 0;

        forkJoin([
            this.userService.getSchedulingAssignedToUsers(imedClaimTypeId),
            this.userService.getCompilationAssignedToUsers(imedClaimTypeId),
            this.userService.getReportAssignedToUsers(imedClaimTypeId),
            this.stateService.getAll(),
            this.imedClaimStatusService.getItems(),
            this.serviceService.getItems(),
            this.requestorTypeService.getItems(),
            this.addressTypeService.getItems(),
        ]).subscribe(([schedulingAssignedToUsers, compilationAssignedToUsers, reportAssignedToUsers, allStates, addressTypes]) => {
            this.schedulingAssignedToUsers = schedulingAssignedToUsers.map((u) => {
                return {
                    Id: u.Id,
                    Name: u.FirstName + ' ' + u.LastName,
                };
            });
            this.compilationAssignedToUsers = compilationAssignedToUsers.map((u) => {
                return {
                    Id: u.Id,
                    Name: u.FirstName + ' ' + u.LastName,
                };
            });
            this.reportAssignedToUsers = reportAssignedToUsers.map((u) => {
                return {
                    Id: u.Id,
                    Name: u.FirstName + ' ' + u.LastName,
                };
            });
            this.allStates = allStates;
            this.addressTypes = addressTypes;
            this.setConfig();
        });
        this.softPopUpErrorMessages = this.claimValidationFunction.bind(this);

        this.subscriptions.add(
            this.addressService.changeEmitted$.subscribe(() => {
                this.setClaimantAddress();
            }),
        );

        this.subscriptions.add(
            this.imedClaimServiceService.getShowCaseInfo().subscribe((showCaseInfo) => (this.showingOnServicePage = showCaseInfo)),
        );

        this.userRoleService.getRolesServicesByAuthUserId(this.authService.currentUser.getValue().AuthId).subscribe((userRoles) => {
            this.userRoleServiceTypeIds = userRoles.map((r) => r.Id);
        });

        this.phoneNumbers =
            this.imedClaim.PhoneNumbers && this.imedClaim.PhoneNumbers.length > 0 ? this.imedClaim.PhoneNumbers.filter((x) => !x.Archived) : [];
        this.emailAddresses =
            this.imedClaim.EmailAddresses && this.imedClaim.EmailAddresses.length > 0 ? this.imedClaim.EmailAddresses.filter((x) => !x.Archived) : [];
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    closeOnServicePage(): void {
        this.imedClaimServiceService.setShowCaseInfo(false);
    }

    setAddress(address: IAddress) {
        this.address = address;
    }

    setOtherField() {
        const additionalOther = this.imedClaimContacts.AdditionalOthers[0];
        this.imedClaimContacts.OtherAddressBookId = additionalOther.OtherAddressBookId;
        this.imedClaimContacts.OtherIndividualId = additionalOther.OtherIndividualId;
        this.imedClaimContacts.OtherName = additionalOther.Individual
            ? `${additionalOther.Individual?.FirstName} ${additionalOther.Individual?.LastName}`
            : additionalOther.AddressBook?.FacilityName;
    }

    setConfig(): void {
        this.setClaimantAddress();
        this.imedClaimStatuses = this.imedClaimStatusService.items;
        this.requestorTypes = this.requestorTypeService.items;
        this.allServices = this.serviceService.items.sort((a, b) => (a.Sort < b.Sort ? -1 : 1));
        this.imedServices = [];
        this.allServices.forEach((item) => {
            if (!this.serviceService.isRestrictedToBeCreatedAsStandAlone(item.Id) && item.ClaimTypeId === this.imedClaim.ClaimTypeId) {
                const newService = { ...emptyService };
                newService.ShowCourtRelatedHeaderShowing = false;
                newService.Name = item.Alias;
                newService.Id = item.Id;
                newService.Sort = item.Sort;
                newService.IsCourtRelatedService = item.IsCourtRelatedService;
                this.setShowCourtRelatedHeader(newService);
                this.imedServices.push(newService);
            }
        });

        let firstFormConfigArray: string[];

        if (this.isAutoOrWorkersCompensationClaim()) {
            firstFormConfigArray = [
                'ClaimNumber',
                'FileNumber',
                'JobNumber',
                'AllStates',
                'StateId',
                'FirstName',
                'LastName',
                'AdditionalInformation',
                'BeginningPageCount',
                'EndingPageCount',
            ];
        } else {
            firstFormConfigArray = [
                'ClaimNumber',
                'FileNumber',
                'JobNumber',
                'FirstName',
                'LastName',
                'AdditionalInformation',
                'BeginningPageCount',
                'EndingPageCount',
            ];
        }

        this.firstFormFactory = new ImedClaimDynamicConfig<IImedClaim>(
            this.imedClaim,
            this.imedClaimStatuses,
            this.requestorTypes,
            null,
            null,
            null,
            this.allStates,
            firstFormConfigArray,
        );

        this.inBetweenFormFactory = new ImedClaimDynamicConfig<IImedClaim>(
            this.imedClaim,
            this.imedClaimStatuses,
            this.requestorTypes,
            this.schedulingAssignedToUsers,
            this.compilationAssignedToUsers,
            this.reportAssignedToUsers,
            null,
            [
                'Birthdate',
                'Ssn',
                'Insured',
                'DateofAllegedAccidentOrInjury',
                'RequestorTypeId',
                'Notes',
                'SchedulingAssignedToId',
                'CompilationAssignedToId',
                'ReportAssignedToId',
            ],
        );

        if (this.imedClaim.Id === 0) {
            // new imedClaim
            this.isEditing = true;
            if (this.isGeneralLiabilityClaim()) {
                this.firstConfig = this.firstFormFactory.getForCreateGL();
                this.inBetweenConfig = this.inBetweenFormFactory.getForCreateGL();
            } else if (this.isAutoOrWorkersCompensationClaim) {
                this.firstConfig = this.firstFormFactory.getForCreateWorkersCompOrAuto();
                this.inBetweenConfig = this.inBetweenFormFactory.getForCreateWorkersCompOrAuto();
            }
        } else {
            // existing imedClaim
            if (this.isGeneralLiabilityClaim()) {
                this.firstConfig = this.firstFormFactory.getForUpdateGL();
                this.inBetweenConfig = this.inBetweenFormFactory.getForUpdateGL(this.getAdditionalConfigs());
            } else if (this.isAutoOrWorkersCompensationClaim()) {
                this.firstConfig = this.firstFormFactory.getForUpdateWorkersCompOrAuto();
                this.inBetweenConfig = this.inBetweenFormFactory.getForUpdateWorkersCompOrAuto(this.getAdditionalConfigs());
            }
        }

        this.caseSettledDateField = this.getCaseSettledField();
        this.caseTransferredDateField = this.getCaseTransferredField();
        this.medicalCanvassingFiled = this.getMedicalCanvassingField();

        this.caseSettled = !!this.imedClaim.CaseSettledDate;
        this.caseTransferred = !!this.imedClaim.CaseTransferredDate;

        this.formIsRendered = true;
    }

    onCaseSettledChanged(caseSettled: boolean) {
        if (!caseSettled) {
            this.caseSettledDateField.validation = [];
        }
        this.cdr.detectChanges();
    }

    onCaseTransferredChanged(caseTransferred: boolean) {
        if (!caseTransferred) {
            this.caseTransferredDateField.validation = [];
        }
        this.cdr.detectChanges();
    }

    getTooltipEntity(type: string): IAddressBookTooltipEntity {
        let entity = { id: 0, type: null };
        switch (type) {
            case 'paralegal':
                entity = this.imedClaimContacts.ParaLegalIndividualId
                    ? {
                          id: this.imedClaimContacts.ParaLegalIndividualId,
                          type: 'individual',
                      }
                    : {
                          id: this.imedClaimContacts.ParaLegalAddressBookId,
                          type: 'addressbook',
                      };
                break;
            case 'defenseAttorney':
                entity = this.imedClaimContacts.DefenseAttorneyIndividualId
                    ? {
                          id: this.imedClaimContacts.DefenseAttorneyIndividualId,
                          type: 'individual',
                      }
                    : {
                          id: this.imedClaimContacts.DefenseAttorneyAddressBookId,
                          type: 'addressbook',
                      };
                break;
            case 'other':
                entity = this.imedClaimContacts.OtherIndividualId
                    ? {
                          id: this.imedClaimContacts.OtherIndividualId,
                          type: 'individual',
                      }
                    : {
                          id: this.imedClaimContacts.OtherAddressBookId,
                          type: 'addressbook',
                      };
                break;
            case 'plantiffAttorney':
                entity = this.imedClaimContacts.PlantiffAttorneyIndividualId
                    ? {
                          id: this.imedClaimContacts.PlantiffAttorneyIndividualId,
                          type: 'individual',
                      }
                    : {
                          id: this.imedClaimContacts.PlantiffAttorneyAddressBookId,
                          type: 'addressbook',
                      };
                break;
            case 'carrierParty':
                entity = this.imedClaimContacts.CarrierPartyIndividualId
                    ? {
                          id: this.imedClaimContacts.CarrierPartyIndividualId,
                          type: 'individual',
                      }
                    : {
                          id: this.imedClaimContacts.CarrierPartyAddressBookId,
                          type: 'addressbook',
                      };
                break;
            case 'claimantsAttorney':
                entity = this.imedClaimContacts.ClaimantsAttorneyIndividualId
                    ? {
                          id: this.imedClaimContacts.ClaimantsAttorneyIndividualId,
                          type: 'individual',
                      }
                    : {
                          id: this.imedClaimContacts.ClaimantsAttorneyAddressBookId,
                          type: 'addressbook',
                      };
                break;
            case 'carriersCounsel':
                entity = this.imedClaimContacts.CarriersCounselIndividualId
                    ? {
                          id: this.imedClaimContacts.CarriersCounselIndividualId,
                          type: 'individual',
                      }
                    : {
                          id: this.imedClaimContacts.CarriersCounselAddressBookId,
                          type: 'addressbook',
                      };
                break;
            case 'employer':
                entity = this.imedClaimContacts.EmployerIndividualId
                    ? {
                          id: this.imedClaimContacts.EmployerIndividualId,
                          type: 'individual',
                      }
                    : {
                          id: this.imedClaimContacts.EmployerAddressBookId,
                          type: 'addressbook',
                      };
                break;
                case 'adjuster':
                    entity = this.imedClaimContacts.AdjusterIndividualId
                        ? {
                              id: this.imedClaimContacts.AdjusterIndividualId,
                              type: 'individual',
                          }
                        : {
                              id: this.imedClaimContacts.AdjusterAddressBookId,
                              type: 'addressbook',
                          };
                    break;
            default:
                break;
        }

        return entity;
    }

    editAddress(): void {
        this.toggleForm();
        this.showAddressComponent = !this.showAddressComponent;
    }

    toggleForm(): void {
        if (this.serviceForm.enabled) {
            this.serviceForm.disable();
            this.saveBtn.nativeElement.disabled = true;
        } else {
            this.serviceForm.enable();
            this.saveBtn.nativeElement.disabled = false;
        }
    }

    handleAddressChange(): void {
        this.showAddressComponent = false;
        this.toggleForm();
        this.setClaimantAddress();
    }

    setClaimantAddress(): void {
        if (this.imedClaim.Id > 0 && this.imedClaim.Addresses[0]) {
            this.imedClaimAddress = this.imedClaim.Addresses[0];
            const state = this.allStates.find((s) => s.Id === this.imedClaimAddress.StateId).StateCode;
            this.claimantFullAddress = `${this.imedClaimAddress.Address1}, ${
                this.imedClaimAddress.Address2 ? this.imedClaimAddress.Address2 + ',' : ''
            } ${this.imedClaimAddress.City}, ${state} ${this.imedClaimAddress.Zip}`;
        } else {
            this.imedClaimAddress = this.addressService.getEmptyAddress();
        }
    }
    setShowCourtRelatedHeader(service: IServicePartial): void {
        if (!this.isHeaderForCourRelatedServiceShown && service.IsCourtRelatedService) {
            this.isHeaderForCourRelatedServiceShown = service.IsCourtRelatedService;
            service.ShowCourtRelatedHeaderShowing = true;
        } else {
            service.ShowCourtRelatedHeaderShowing = false;
        }
    }
    get dateCreated(): Date {
        return this.imedClaim.DateCreated;
    }

    getDynamicField(serviceTypeObject: IServicePartial): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: serviceTypeObject.Name,
            name: 'ServiceId',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
            } as IDynamicFieldType),
            value: false,
        } as IDynamicField);
    }

    getIsDefaultDynamicField(entityName: string, entityValue: boolean): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: `Default`,
            name: `${entityName}IsDefault`,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
            } as IDynamicFieldType),
            value: entityValue,
        } as IDynamicField);
    }

    getCaseSettledField(): DynamicField {
        return new DynamicField({
            formGroup: 'CaseDates',
            label: 'Case Settled Date',
            name: 'CaseSettledDate',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.DateInput,
            } as IDynamicFieldType),
            value: this.imedClaim.CaseSettledDate && new Date(this.imedClaim.CaseSettledDate),
            validation: [(c: AbstractControl) => Validators.required(c)],
        } as IDynamicField);
    }

    getCaseTransferredField(): DynamicField {
        return new DynamicField({
            formGroup: 'CaseDates',
            label: 'Case Transferred',
            name: 'CaseTransferredDate',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.DateInput,
            } as IDynamicFieldType),
            value: this.imedClaim.CaseTransferredDate && new Date(this.imedClaim.CaseTransferredDate),
            validation: [(c: AbstractControl) => Validators.required(c)],
        } as IDynamicField);
    }

    getMedicalCanvassingField(): DynamicField {
        return new DynamicField({
            formGroup: 'ImedClaim',
            label: 'Medical Canvassing',
            name: 'MedicalCanvassing',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Checkbox,
            } as IDynamicFieldType),
            value: this.imedClaim.MedicalCanvassing,
        } as IDynamicField);
    }

    setServiceCheck(serviceTypeObject: IServicePartial, checked: boolean): void {
        if (checked) {
            this.includedServices.push(serviceTypeObject);
        } else {
            const i = this.includedServices.findIndex((item) => item.Id === serviceTypeObject.Id);
            this.includedServices.splice(i, 1);
        }
    }

    setIsDefault(addressBookType: string, checked: boolean): void {
        this.imedClaimContacts = this.imedClaimService.setIsDefault(addressBookType, checked, this.imedClaimContacts);
    }

    getAdditionalConfigs(): ImedClaimDynamicConfig<IImedClaim>[] {
        const archConfigControls: string[] = ['ImedClaimStatusId'];
        const imedClaim = this.imedClaim.Id === 0 ? null : this.imedClaim;
        const archConfig = new ImedClaimDynamicConfig<IImedClaim>(
            imedClaim,
            this.imedClaimStatuses,
            this.requestorTypes,
            null,
            null,
            null,
            null,
            archConfigControls,
        );
        return [archConfig];
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.imedClaim.Id === 0) {
            void this.router.navigate(['/cases']);
        } else {
            this.isEditing = false;
            this.showAddressComponent = false;
        }
    }

    createImedClaim(): void {
        this.imedClaimService.createWithFks(this.imedClaim).subscribe((answer) => {
            void this.router.navigate(['/cases/' + answer]);
            this.success();
            this.imedClaimService.emitChange(this.imedClaim);
        });
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    success(): void {
        this.notificationsService.success('Saved Successfully');
    }

    ClearAddressBooks(name: string): void {
        this.imedClaimService.unAssignContactFromClaims(this.imedClaimContacts, name);
    }

    SearchAddressBooks(addressBookType: string): void {
        const claimTypeId = this.imedClaim.Id > 0 ? this.imedClaim.ClaimTypeId : this.imedClaim.Id === 0 ? this.imedClaimTypeId : 0;
        this.addressSelectService.openAddressBookSelect(addressBookType, this.select, null, claimTypeId); // note the bind(this)
    }

    select = (addressBookSelect: IAddressBookSelectEntity): void => {
        this.imedClaimContacts[addressBookSelect.AddressBookType + 'Name'] = addressBookSelect.IndividualId
            ? addressBookSelect.IndividualName
            : addressBookSelect.AddressBookName;
        this.imedClaimContacts[addressBookSelect.AddressBookType + 'IndividualId'] = addressBookSelect.IndividualId;
        this.imedClaimContacts[addressBookSelect.AddressBookType + 'AddressBookId'] = addressBookSelect.AddressBookId;
    };

    claimValidationFunction(): ISoftPopUpValidationFunctionResult {
        // first check the form for valid, and if not handle error display and return failed result
        if (!this.serviceForm.valid) {
            markAllFormFieldsAsTouched(this.serviceForm);
            this.error();
            return { result: SoftPopUpResults.Failed };
        }

        // assign form values
        this.firstFormFactory.assignFormValues(this.imedClaim, this.serviceForm.value.ImedClaim as IImedClaim);
        this.imedClaim.JobNumber = this.serviceForm.value.ImedClaim.JobNumber;
        this.imedClaim.RequestorTypeId = this.serviceForm.value.ImedClaim.RequestorTypeId;
        this.imedClaim = this.imedClaimService.assignContactsToClaim(this.imedClaim, this.imedClaimContacts);

        if (this.imedClaim.AllStates) {
            this.imedClaim.StateId = null;
            this.imedClaim.State = null;
        } else if (this.imedClaim.Id > 0) {
            if (!this.imedClaim.State || this.imedClaim.StateId !== this.imedClaim.State.Id) {
                this.imedClaim.State = this.allStates.find((s) => s.Id === this.imedClaim.StateId);
            }
        } else {
            this.imedClaim.State = null;
        }

        // build out popup
        const popup: ISoftPopUp = { Messages: [] };
        if (this.includedServices.length > 0) {
            const includedServiceIds = this.includedServices.map((x) => x.Id);

            if (this.imedClaimService.showSoftPopupWhenDateOfAllegedNotFilled(this.imedClaim, includedServiceIds)) {
                popup.Messages.push('Date of Alleged Accident / Injury is missing');
            }

            if (!this.imedClaim.ClaimNumber && !this.imedClaim.FileNumber) {
                popup.Messages.push('Claim Number or File Number is missing');
            }

            if (this.userRoleDoesNotIncludeServiceTypes()) {
                popup.Messages.push('You are trying to add a service outside of your defined role.');
            }
        }

        // if popup has messages, return failed result with popup to display
        // else return passed result
        if (popup.Messages.length) {
            return { result: SoftPopUpResults.Failed, popup: popup };
        } else {
            return { result: SoftPopUpResults.Passed };
        }
    }

    confirmServiceIsSelected(): Observable<boolean> {
        if (this.includedServices.length === 0) {
            const popUp: IPopUpParams = {
                html: 'Service is required',
                type: PopUpTypeEnums.Hard_Pop_Up,
            };
            return this.popUpService.showPopUp(popUp).pipe(map((result) => !result.dismiss));
        } else if (!this.address) {
            const popUp: IPopUpParams = {
                html: 'Address is required',
                type: PopUpTypeEnums.Hard_Pop_Up,
            };
            return this.popUpService.showPopUp(popUp).pipe(map((result) => !result.dismiss));
        }
        return of(true);
    }

    userRoleDoesNotIncludeServiceTypes(): boolean {
        const serviceIds = this.includedServices.map((s) => s.Id);

        return !serviceIds.every((s) => this.userRoleServiceTypeIds.includes(s));
    }

    setDatesToTimeZoneRelatedDate(dateToBeConverted: string): Date {
        const dateFromFormAsISOString = new Date(dateToBeConverted).toISOString();
        const dateWithoutUTCIdentifier = new Date(dateFromFormAsISOString.substring(0, dateFromFormAsISOString.length - 1));
        return new Date(dateWithoutUTCIdentifier.mtDate.toMoment().format('MM/DD/YYYY'));
    }

    setBirthDate(): void {
        if (this.imedClaim.Birthdate) {
            this.imedClaim.Birthdate = this.setDatesToTimeZoneRelatedDate(this.serviceForm.controls.ImedClaim.get('Birthdate').value as string);
        }
    }

    setDateOfInjuryAccident(): void {
        if (this.imedClaim.DateofAllegedAccidentOrInjury) {
            this.imedClaim.DateofAllegedAccidentOrInjury = this.setDatesToTimeZoneRelatedDate(
                this.serviceForm.controls.ImedClaim.get('DateofAllegedAccidentOrInjury').value as string,
            );
        }
    }

    validateBirthdate(): boolean {
        const birthdate = this.serviceForm.controls.ImedClaim.get('Birthdate');
        if (birthdate.value && !isNaN(birthdate.value.getTime() as number)) {
            return this.dateValidation(birthdate.value as Date);
        }
        this.imedClaim.Birthdate = null;
        return true;
    }

    validateDateOfInjury(): boolean {
        const date = this.serviceForm.controls.ImedClaim.get('DateofAllegedAccidentOrInjury');
        if (date.value && !isNaN(date.value.getTime() as number)) {
            return this.dateValidation(date.value as Date);
        }
        this.imedClaim.DateofAllegedAccidentOrInjury = null;
        return true;
    }

    dateValidation(date: Date): boolean {
        if (date > new Date('01/01/1753') && date <= new Date()) {
            return true;
        } else {
            this.notificationsService.error('Invalid date');
            return false;
        }
    }

    saveClaim(): void {
        trimObjectStringProperties(this.imedClaim);

        if (!this.imedClaim.CaseSettledDate && !this.imedClaim.CaseTransferredDate && this.caseSettled && this.caseTransferred) {
            return this.notificationsService.error('Case cannot be both settled and transferred at the same time');
        }

        this.imedClaim.CaseSettledDate = this.serviceForm.controls.CaseDates.get('CaseSettledDate')?.value;
        this.imedClaim.CaseTransferredDate = this.serviceForm.controls.CaseDates.get('CaseTransferredDate')?.value;
        this.imedClaim.MedicalCanvassing = this.serviceForm.controls.ImedClaim.get('MedicalCanvassing')?.value;

        if (this.imedClaim.CaseSettledDate) {
            this.imedClaim.CaseSettledDate = this.setDatesToTimeZoneRelatedDate(this.imedClaim.CaseSettledDate.toString());
        }

        if (this.imedClaim.CaseTransferredDate) {
            this.imedClaim.CaseTransferredDate = this.setDatesToTimeZoneRelatedDate(this.imedClaim.CaseTransferredDate.toString());
        }

        if (!this.caseSettled) {
            this.imedClaim.CaseSettledDate = null;
        }

        if (!this.caseTransferred) {
            this.imedClaim.CaseTransferredDate = null;
        }

        if (!this.hasAllRequiredFirms())
            return this.notificationsService.error('Please select all required firms');

        if (!this.imedClaim.Id || this.imedClaim.Id === 0) {
            this.confirmServiceIsSelected().subscribe((result) => {
                if (result === false || !this.validateBirthdate() || !this.validateDateOfInjury()) {
                    return;
                }
                this.imedClaim = this.imedClaimService.createServicesForClaimCreation(
                    this.imedClaim,
                    this.includedServices.map((x) => x.Id),
                    this.authService.currentUser.getValue().Id,
                );
                this.imedClaim = this.imedClaimService.attachAddressForClaimCreation(
                    this.imedClaim,
                    this.address,
                    this.authService.currentUser.getValue().Id,
                );
                this.imedClaim = this.imedClaimService.attachPhoneNumberAndEmailForClaimCreation(
                    this.imedClaim,
                    this.serviceForm.value.EmailAddress as IEmailAddress,
                    this.serviceForm.value.PhoneNumber as IPhoneNumber,
                    this.authService.currentUser.getValue().Id,
                );

                // Null out email field if email address is not provided. This is to prevent the backend from trying to validate an empty email
                if (!this.imedClaim.EmailAddresses[0].Email) {
                    this.imedClaim.EmailAddresses = null;
                }

                // Null out email field if phone is not provided. This is to prevent the backend from trying to validate an empty phone number
                if (!this.imedClaim.PhoneNumbers[0].Phone) {
                    this.imedClaim.PhoneNumbers = null;
                }

                // Null out address fields if address is not provided. This is to prevent the backend from trying to validate an empty address
                if (!this.address) {
                    this.imedClaim.Addresses = null;
                }
                this.imedClaim.CompilationAssignedToId = this.serviceForm.value.ImedClaim.CompilationAssignedToId;
                this.imedClaim.SchedulingAssignedToId = this.serviceForm.value.ImedClaim.SchedulingAssignedToId;
                this.imedClaim.ReportAssignedToId = this.serviceForm.value.ImedClaim.ReportAssignedToId;
                this.imedClaim.BeginningPageCount = this.serviceForm.value.ImedClaim.BeginningPageCount;
                this.imedClaim.EndingPageCount = this.serviceForm.value.ImedClaim.EndingPageCount;

                switch (this.imedClaim.ClaimTypeId as ImedClaimTypes) {
                    case ImedClaimTypes.Auto:
                        this.imedClaim.ImedClaimServices.forEach((service) => {
                            service.AutoIssue = this.autoIssueService.getEmptyAutoIssue(this.includedServices.map((x) => x.Id));
                        });
                        break;
                    case ImedClaimTypes.WorkersCompensation:
                        this.imedClaim.ImedClaimServices.forEach((service) => {
                            service.WcIssue = this.wcIssueService.getEmptyWcIssue();
                        });
                        break;
                    default:
                    // Do nothing.
                }

                this.setBirthDate();
                this.setDateOfInjuryAccident();
                this.imedClaim.CreatedById = this.authService.currentUser.getValue().Id;
                this.imedClaim.DateCreated = new Date();
                this.createImedClaim();
            });
            // handle new imedClaim save
        } else {
            // handle existing imedClaim save
            if (!this.validateBirthdate() || !this.validateDateOfInjury()) {
                return;
            }
            this.setBirthDate();
            this.setDateOfInjuryAccident();
            this.imedClaim.ModifiedById = this.authService.currentUser.getValue().Id;
            this.imedClaim.DateModified = new Date();

            if (!this.imedClaimContacts.OtherAddressBookId && this.imedClaimContacts.AdditionalOthers[0]) {
                this.setOtherField();
            }
            this.imedClaimService.update(this.imedClaim).subscribe(
                () => {
                    this.isEditing = false;
                    this.success();
                    this.setConfig();
                    this.imedClaimService.emitChange(this.imedClaim);
                },
                (error: HttpErrorResponse) => {
                    if (error.status === 400) {
                        const popUp: IPopUpParams = {
                            html: error.error,
                            type: PopUpTypeEnums.Hard_Pop_Up,
                        };
                        this.popUpService.showPopUp(popUp).subscribe(() => {
                            this.serviceForm.get('ImedClaim.ImedClaimStatusId').setValue(IMedClaimStatusIdEnums.Active);
                        });
                    }
                },
            );
        }
    }

    navigateToDuplicate(id: number): void {
        void this.router.navigate(['cases', id]);
    }

    formValueChanged(event: any): void {
        if (this.imedClaim.Id === 0 && !this.duplicateWarningDismissed) {
            const claimNumberControl = event.target.form[0];
            const fileNumberControl = event.target.form[1];
            const jobNumberControl = event.target.form[2];
            const firstNameControl = this.imedClaim.ClaimTypeId === +ImedClaimTypes.GeneralLiability ? event.target.form[3] : event.target.form[5];
            const lastNameControl = this.imedClaim.ClaimTypeId === +ImedClaimTypes.GeneralLiability ? event.target.form[4] : event.target.form[6];
            if (
                event.target === claimNumberControl ||
                event.target === fileNumberControl ||
                event.target === jobNumberControl ||
                event.target === firstNameControl ||
                event.target === lastNameControl
            ) {
                this.assignFormValuesToImedClaimObject(
                    claimNumberControl.value as string,
                    fileNumberControl.value as string,
                    jobNumberControl.value as string,
                    firstNameControl.value as string,
                    lastNameControl.value as string,
                );
                this.handleDuplicateCheck();
            }
        }
    }

    handleDuplicateCheck(): void {
        this.imedClaimService.checkForDuplicates(this.imedClaim).subscribe((duplicates) => {
            if (duplicates && duplicates.length) {
                this.duplicates = duplicates;
                this.duplicateCaseModalApi.show();
            }
        });
    }

    userConfirmDuplicateCaseModal(): void {
        location.reload();
    }

    userDismissDuplicateCaseModal(): void {
        this.duplicateWarningDismissed = true;
    }

    assignFormValuesToImedClaimObject(claimNumber: string, fileNumber: string, jobNumber: string, firstName: string, lastName: string): void {
        this.imedClaim.ClaimNumber = claimNumber;
        this.imedClaim.FileNumber = fileNumber;
        this.imedClaim.JobNumber = jobNumber;
        this.imedClaim.FirstName = firstName;
        this.imedClaim.LastName = lastName;
    }

    isGeneralLiabilityClaim(): boolean {
        if (this.imedClaim.Id === 0) {
            return this.imedClaimTypeId === +ImedClaimTypes.GeneralLiability;
        } else if (this.imedClaim.Id > 0) {
            return this.imedClaim.ClaimTypeId === +ImedClaimTypes.GeneralLiability;
        }
        return false;
    }

    isAutoOrWorkersCompensationClaim(): boolean {
        if (this.imedClaim.Id === 0) {
            return this.imedClaimTypeId === +ImedClaimTypes.Auto || this.imedClaimTypeId === +ImedClaimTypes.WorkersCompensation;
        } else if (this.imedClaim.Id > 0) {
            return this.imedClaim.ClaimTypeId === +ImedClaimTypes.Auto || this.imedClaim.ClaimTypeId === +ImedClaimTypes.WorkersCompensation;
        }
        return false;
    }

    isWorkersCompensationClaim(): boolean {
        if (this.imedClaim.Id === 0) {
            return this.imedClaimTypeId === +ImedClaimTypes.WorkersCompensation;
        } else if (this.imedClaim.Id > 0) {
            return this.imedClaim.ClaimTypeId === +ImedClaimTypes.WorkersCompensation;
        }
        return false;
    }

    isAutoClaim(): boolean {
        if (this.imedClaim.Id === 0) {
            return this.imedClaimTypeId === +ImedClaimTypes.Auto;
        } else if (this.imedClaim.Id > 0) {
            return this.imedClaim.ClaimTypeId === +ImedClaimTypes.Auto;
        }
        return false;
    }

    togglePhoneNumberEdit(): void {
        this.isEditingPhoneNumbers = !this.isEditingPhoneNumbers;
    }

    toggleEmailAddressEdit(): void {
        this.isEditingEmailAddresses = !this.isEditingEmailAddresses;
    }

    transferPhonesFromControl(event): void {
        this.togglePhoneNumberEdit();
        this.phoneNumbers = event;
    }

    transferEmailsFromControl(event): void {
        this.toggleEmailAddressEdit();
        this.emailAddresses = event;
    }

    onFormCreated(event: UntypedFormGroup): void {
        if (this.isAutoOrWorkersCompensationClaim()) {
            const imedClaimControls = event.controls.ImedClaim as UntypedFormGroup;
            const allStatesControl = imedClaimControls.controls.AllStates;
            this.subscriptions.add(
                allStatesControl.valueChanges.subscribe((value) => {
                    const stateControl = imedClaimControls.controls.StateId;

                    if (value) {
                        stateControl.setValue(null);
                        stateControl.mtSetRequired(false);
                        stateControl.disable();
                    } else {
                        stateControl.setValue(this.imedClaim.State ? this.imedClaim.StateId : null);
                        stateControl.mtSetRequired(true);
                        stateControl.enable();
                        stateControl.markAsPristine();
                    }

                    this.cdr.detectChanges();
                }),
            );
        }
    }

    hasAllRequiredFirms(): boolean {
        return !(
            (this.isGeneralLiabilityClaim() && (!this.imedClaim.DefenseAttorneyAddressBookId || !this.imedClaim.ParaLegalAddressBookId)) ||
            !this.imedClaim.CarrierPartyAddressBookId
        );
    }
}
