import { emptyConferenceCallService } from './services/service-specifics/conference-call-service/conference-call-service.service';
import { emptyTestimonyDepositionService } from './services/service-specifics/testimony-deposition-service/testimony-deposition-service.service';
import { ITestimonyDepositionService } from '@model/interfaces/testimony-deposition-service.d';
import { emptyBillDeskReviewService } from './services/service-specifics/bill-desk-review-service/bill-desk-review-service.service';
import { emptyRecordReviewService } from './services/service-specifics/record-review-service/record-review-service.service';
import { emptyRecordRetrievalService } from './services/service-specifics/record-retrieval-service/record-retrieval-service.service';
import { emptyImeService } from './services/service-specifics/ime-service/imeservice.service';
import { AddressBookSelectionEnums } from './../common/constants/Enums';
import { IImedClaimService } from '@model/partials/imed-claim-service.partial.d';
import { IEmailAddress } from '@model/interfaces/email-address.d';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { IMedClaimStatusIdEnums, ServiceStatusIdEnums, ServiceIdEnums } from '../common/constants/Enums';
import { IIMedClaimContactsInfo } from './imed-claim-basic-info/imed-claim-contacts-info';
import { IImedClaimPartial } from '@model/partials/imed-claim-partial';
import { emptyImedClaimService } from './services/imedclaimservice.service';
import { DateTimeConverterService } from '../common/services/date-time-converter.service';
import { map } from 'rxjs/operators';
import { IHeaderInfoDTO } from '@model/interfaces/custom/radiology-subservice-dto';
import { IImedClaimDTO } from '@model/interfaces/custom/imedclaim-dto';
import { ImedClaimTypes } from '../../client-portal/model/ImedClaimTypes';
import { IPhoneNumber } from '@model/interfaces/phone-number';
import { emptyFilmReviewService } from './services/common-components/film-review/film-review-service.service';
import { emptyNf10Service } from './services/nf10-services.service';
import { IAddress } from '@model/interfaces/address';
import { IImedClaimAdditionalRecord } from '@model/interfaces/imed-claim-additional-record';

export const emptyImedClaim: IImedClaimPartial = {
    AdjusterIsDefault: false,
    AllStates: true,
    Archived: false,
    Birthdate: null,
    CarrierPartyIsDefault: false,
    CarriersCounselIsDefault: false,
    CausalRelationship: false,
    ClaimantsAttorneyIsDefault: false,
    ClaimNumber: null,
    ClaimTypeId: ImedClaimTypes.GeneralLiability, // GL by default - will be overwritten by page logic
    CompilationAssignedToId: null,
    CreatedById: 0,
    CurrentCourseOfTreatment: true,
    CurrentSymptomsAndCompliants: true,
    DateCreated: new Date(),
    DateofAllegedAccidentOrInjury: null,
    DefenseAttorneyIsDefault: false,
    DegreeOfDisability: false,
    EmployerIsDefault: false,
    FileNumber: null,
    FirstName: null,
    HistoryOfAllegedInjury: true,
    Id: 0,
    ImedClaimStatusId: IMedClaimStatusIdEnums.Active,
    Impression: true,
    Insured: null,
    LastName: null,
    NeedForFurtherTreatment: false,
    Notes: null,
    OccupationalStatus: true,
    OtherIsDefault: false,
    ParaLegalIsDefault: false,
    PastMedicalHistory: true,
    PastSubsequentHistory: true,
    PhysicalCheckToFacilityRequired: false,
    PlantiffAttorneyIsDefault: false,
    ReportAssignedToId: null,
    SchedulingAssignedToId: null,
    Ssn: null,
    StateId: null,
    WorkStatus: false,
    MedicalCanvassing: false,
};

export interface IHasCreatedBy {
    CreatedById: number;
    DateCreated: Date;
    IsPrimary?: boolean;
}

export interface ICreateIMedClaimFormValue {
    IMedClaim: IImedClaimPartial;
    Address?: IAddress;
    EmailAddress?: IEmailAddress;
    PhoneNumber?: IPhoneNumber;
}

@Injectable()
export class ImedClaimService extends BaseService<IImedClaimPartial> {
    constructor(public http: HttpClient, private dateService: DateTimeConverterService) {
        super('/imedclaims', http);
    }

    private fixDatesForClaim = (claim: IImedClaimPartial): IImedClaimPartial => {
        claim.DateofAllegedAccidentOrInjury = this.dateService.assumeLocalTimeForUTCDateObject(claim.DateofAllegedAccidentOrInjury);
        claim.Birthdate = this.dateService.assumeLocalTimeForUTCDateObject(claim.Birthdate);
        return claim;
    };

    // overriding the getById so that I can update the dates that should not have time
    // to remove the 'Z' and therefore consider them already in browser's time zone
    getById(id: number): Observable<IImedClaimPartial> {
        return super.getById(id).pipe(map(this.fixDatesForClaim));
    }

    getByIdForDocQueue(id: number): Observable<IImedClaimPartial> {
        return this.http.get<IImedClaimPartial>(`/imedclaims/${id}/get-by-id-for-doc-queue`);
    }

    // overriding the get so that I can update the dates that should not have time
    // to remove the 'Z' and therefore consider them already in browser's time zone
    get(searchparameters: SearchParams): Observable<HttpResponse<IImedClaimPartial[]>> {
        return super.get(searchparameters).pipe(
            map((response) => {
                (<any>response.body) = response.body.map(this.fixDatesForClaim);
                return response;
            }),
        );
    }

    getEmptyImedClaim(): IImedClaimPartial {
        return { ...emptyImedClaim };
    }

    getPrimaryEmailForImedClaimDefenseAttorney(imedClaimId: number): Observable<IEmailAddress> {
        return this.http.get<IEmailAddress>(`/imedclaims/${imedClaimId}/defenseattorneyemail`, {});
    }

    searchClaims(data: SearchParams): Observable<any> {
        const params = this.getHttpParams(data);
        return this.http.get(`/imedclaims/_searchclaims`, { observe: 'response', params: params });
    }

    getClaimsByName(data: SearchParams): Observable<IImedClaimDTO[]> {
        const params = this.getHttpParams(data);
        return this.http.get<IImedClaimDTO[]>(`/imedclaims/get-claims-by-name`, { params: params });
    }
    assignContactsToClaim(imedClaim: IImedClaimPartial, imedClaimContacts: IIMedClaimContactsInfo): IImedClaim {
        imedClaim.CarrierPartyAddressBookId = imedClaimContacts.CarrierPartyAddressBookId;
        imedClaim.CarrierPartyIndividualId = imedClaimContacts.CarrierPartyIndividualId;
        imedClaim.CarrierPartyIsDefault = imedClaimContacts.CarrierPartyIsDefault;
        imedClaim.DefenseAttorneyAddressBookId = imedClaimContacts.DefenseAttorneyAddressBookId;
        imedClaim.DefenseAttorneyIndividualId = imedClaimContacts.DefenseAttorneyIndividualId;
        imedClaim.DefenseAttorneyIsDefault = imedClaimContacts.DefenseAttorneyIsDefault;
        imedClaim.OtherAddressBookId = imedClaimContacts.OtherAddressBookId;
        imedClaim.OtherIndividualId = imedClaimContacts.OtherIndividualId;
        imedClaim.OtherIsDefault = imedClaimContacts.OtherIsDefault;
        imedClaim.ParaLegalAddressBookId = imedClaimContacts.ParaLegalAddressBookId;
        imedClaim.ParaLegalIndividualId = imedClaimContacts.ParaLegalIndividualId;
        imedClaim.ParaLegalIsDefault = imedClaimContacts.ParaLegalIsDefault;
        imedClaim.PlantiffAttorneyAddressBookId = imedClaimContacts.PlantiffAttorneyAddressBookId;
        imedClaim.PlantiffAttorneyIndividualId = imedClaimContacts.PlantiffAttorneyIndividualId;
        imedClaim.PlantiffAttorneyIsDefault = imedClaimContacts.PlantiffAttorneyIsDefault;
        imedClaim.AdjusterAddressBookId = imedClaimContacts.AdjusterAddressBookId;
        imedClaim.AdjusterIndividualId = imedClaimContacts.AdjusterIndividualId;
        imedClaim.AdjusterIsDefault = imedClaimContacts.AdjusterIsDefault;
        imedClaim.ClaimantsAttorneyAddressBookId = imedClaimContacts.ClaimantsAttorneyAddressBookId;
        imedClaim.ClaimantsAttorneyIndividualId = imedClaimContacts.ClaimantsAttorneyIndividualId;
        imedClaim.ClaimantsAttorneyIsDefault = imedClaimContacts.ClaimantsAttorneyIsDefault;
        imedClaim.CarriersCounselAddressBookId = imedClaimContacts.CarriersCounselAddressBookId;
        imedClaim.CarriersCounselIndividualId = imedClaimContacts.CarriersCounselIndividualId;
        imedClaim.CarriersCounselIsDefault = imedClaimContacts.CarriersCounselIsDefault;
        imedClaim.EmployerAddressBookId = imedClaimContacts.EmployerAddressBookId;
        imedClaim.EmployerIndividualId = imedClaimContacts.EmployerIndividualId;
        imedClaim.EmployerIsDefault = imedClaimContacts.EmployerIsDefault;

        return imedClaim;
    }

    assignClaimToContacts(imedClaimContacts: IIMedClaimContactsInfo, imedClaim: IImedClaimPartial): IIMedClaimContactsInfo {
        imedClaimContacts.AdjusterName = imedClaim.AdjusterIndividual
            ? imedClaim.AdjusterIndividual.FirstName + ' ' + imedClaim.AdjusterIndividual.LastName
            : imedClaim.AdjusterAddressBook
            ? imedClaim.AdjusterAddressBook.FacilityName
            : '';

        imedClaimContacts.AdjusterIsDefault = imedClaim.AdjusterIsDefault;

        imedClaimContacts.CarrierPartyName = imedClaim.CarrierPartyIndividual
            ? imedClaim.CarrierPartyIndividual.FirstName + ' ' + imedClaim.CarrierPartyIndividual.LastName
            : imedClaim.CarrierPartyAddressBook
            ? imedClaim.CarrierPartyAddressBook.FacilityName
            : '';

        imedClaimContacts.CarrierPartyIsDefault = imedClaim.CarrierPartyIsDefault;

        imedClaimContacts.DefenseAttorneyName = imedClaim.DefenseAttorneyIndividual
            ? imedClaim.DefenseAttorneyIndividual.FirstName + ' ' + imedClaim.DefenseAttorneyIndividual.LastName
            : imedClaim.DefenseAttorneyAddressBook
            ? imedClaim.DefenseAttorneyAddressBook.FacilityName
            : '';

        imedClaimContacts.DefenseAttorneyIsDefault = imedClaim.DefenseAttorneyIsDefault;

        imedClaimContacts.OtherName = imedClaim.OtherIndividualId
            ? imedClaim.OtherIndividual.FirstName + ' ' + imedClaim.OtherIndividual.LastName
            : imedClaim.OtherAddressBook
            ? imedClaim.OtherAddressBook.FacilityName
            : '';

        imedClaimContacts.OtherIsDefault = imedClaim.OtherIsDefault;

        imedClaimContacts.ParaLegalName = imedClaim.ParaLegalIndividual
            ? imedClaim.ParaLegalIndividual.FirstName + ' ' + imedClaim.ParaLegalIndividual.LastName
            : imedClaim.ParaLegalAddressBook
            ? imedClaim.ParaLegalAddressBook.FacilityName
            : '';

        imedClaimContacts.ParaLegalIsDefault = imedClaim.ParaLegalIsDefault;

        imedClaimContacts.PlantiffAttorneyName = imedClaim.PlantiffAttorneyIndividual
            ? imedClaim.PlantiffAttorneyIndividual.FirstName + ' ' + imedClaim.PlantiffAttorneyIndividual.LastName
            : imedClaim.PlantiffAttorneyAddressBook
            ? imedClaim.PlantiffAttorneyAddressBook.FacilityName
            : '';

        imedClaimContacts.PlantiffAttorneyIsDefault = imedClaim.PlantiffAttorneyIsDefault;

        imedClaimContacts.ClaimantsAttorneyName = imedClaim.ClaimantsAttorneyIndividual
            ? imedClaim.ClaimantsAttorneyIndividual.FirstName + ' ' + imedClaim.ClaimantsAttorneyIndividual.LastName
            : imedClaim.ClaimantsAttorneyAddressBook
            ? imedClaim.ClaimantsAttorneyAddressBook.FacilityName
            : '';

        imedClaimContacts.ClaimantsAttorneyIsDefault = imedClaim.ClaimantsAttorneyIsDefault;

        imedClaimContacts.CarriersCounselName = imedClaim.CarriersCounselIndividual
            ? imedClaim.CarriersCounselIndividual.FirstName + ' ' + imedClaim.CarriersCounselIndividual.LastName
            : imedClaim.CarriersCounselAddressBook
            ? imedClaim.CarriersCounselAddressBook.FacilityName
            : '';

        imedClaimContacts.CarriersCounselIsDefault = imedClaim.CarriersCounselIsDefault;

        imedClaimContacts.EmployerName = imedClaim.EmployerIndividual
            ? imedClaim.EmployerIndividual.FirstName + ' ' + imedClaim.EmployerIndividual.LastName
            : imedClaim.EmployerAddressBook
            ? imedClaim.EmployerAddressBook.FacilityName
            : '';

        imedClaimContacts.EmployerIsDefault = imedClaim.EmployerIsDefault;

        return imedClaimContacts;
    }

    unAssignContactFromClaims(imedClaimContacts: IIMedClaimContactsInfo, name: string): IIMedClaimContactsInfo {
        switch (name as AddressBookSelectionEnums) {
            case AddressBookSelectionEnums.PlantiffAttorney:
                imedClaimContacts.PlantiffAttorneyAddressBookId = null;
                imedClaimContacts.PlantiffAttorneyIndividualId = null;
                imedClaimContacts.PlantiffAttorneyName = null;
                imedClaimContacts.PlantiffAttorneyIsDefault = false;
                break;
            case AddressBookSelectionEnums.DefenseAttorney:
                imedClaimContacts.DefenseAttorneyAddressBookId = null;
                imedClaimContacts.DefenseAttorneyIndividualId = null;
                imedClaimContacts.DefenseAttorneyName = null;
                imedClaimContacts.DefenseAttorneyIsDefault = false;
                break;
            case AddressBookSelectionEnums.Other:
                imedClaimContacts.OtherAddressBookId = null;
                imedClaimContacts.OtherIndividualId = null;
                imedClaimContacts.OtherName = null;
                imedClaimContacts.OtherIsDefault = false;
                break;
            case AddressBookSelectionEnums.ParaLegal:
                imedClaimContacts.ParaLegalAddressBookId = null;
                imedClaimContacts.ParaLegalIndividualId = null;
                imedClaimContacts.ParaLegalName = null;
                imedClaimContacts.ParaLegalIsDefault = false;
                break;
            case AddressBookSelectionEnums.CarrierParty:
                imedClaimContacts.CarrierPartyAddressBookId = null;
                imedClaimContacts.CarrierPartyIndividualId = null;
                imedClaimContacts.CarrierPartyName = null;
                imedClaimContacts.CarrierPartyIsDefault = false;
                break;
            case AddressBookSelectionEnums.Adjuster:
                imedClaimContacts.AdjusterAddressBookId = null;
                imedClaimContacts.AdjusterIndividualId = null;
                imedClaimContacts.AdjusterName = null;
                imedClaimContacts.AdjusterIsDefault = false;
                break;
            case AddressBookSelectionEnums.ClaimantsAttorney:
                imedClaimContacts.ClaimantsAttorneyAddressBookId = null;
                imedClaimContacts.ClaimantsAttorneyIndividualId = null;
                imedClaimContacts.ClaimantsAttorneyIsDefault = false;
                imedClaimContacts.ClaimantsAttorneyName = null;
                break;
            case AddressBookSelectionEnums.CarriersCounsel:
                imedClaimContacts.CarriersCounselAddressBookId = null;
                imedClaimContacts.CarriersCounselIndividualId = null;
                imedClaimContacts.CarriersCounselIsDefault = false;
                imedClaimContacts.CarriersCounselName = null;
                break;
            case AddressBookSelectionEnums.Employer:
                imedClaimContacts.EmployerAddressBookId = null;
                imedClaimContacts.EmployerIndividualId = null;
                imedClaimContacts.EmployerIsDefault = false;
                imedClaimContacts.EmployerName = null;
                break;
            default:
                break;
        }
        return imedClaimContacts;
    }

    getServiceStatusForNewService(serviceId: number): number {
        switch (serviceId as ServiceIdEnums) {
            case ServiceIdEnums.IME:
                return ServiceStatusIdEnums.IME_Pending_Scheduling;

            case ServiceIdEnums.IME_Addendum_Additional_Records:
                return ServiceStatusIdEnums.IME_Addendum_Scheduled_Pending_Receipt_Of_Records;

            case ServiceIdEnums.IME_Re_Examination:
                return ServiceStatusIdEnums.IME_Re_Exam_Pending_Scheduling;

            case ServiceIdEnums.IME_Expert_Consultation:
                return ServiceStatusIdEnums.IME_Expert_Consultation_Pending_Scheduling;

            case ServiceIdEnums.IME_Conference_Call:
                return ServiceStatusIdEnums.IME_Conference_Call_Pending_Scheduling;

            case ServiceIdEnums.Record_Retrievals_HIPAA_Processing:
                return ServiceStatusIdEnums.Record_Retrievals_HIPAA_Processing_Pending_Retrieval_Document_Submission_By_Mail_Or_Fax;

            case ServiceIdEnums.Film_Review:
                return ServiceStatusIdEnums.Radiology_Review_Pending_Receipt_Of_Films_From_Client;

            case ServiceIdEnums.Independent_Film_Review:
                return ServiceStatusIdEnums.Independent_Radiology_Review_Pending_Receipt_Of_Films_From_Client;

            case ServiceIdEnums.Record_Review:
                return ServiceStatusIdEnums.Record_Review_Requested_pending_physician_selection;

            case ServiceIdEnums.Billing_Desk_Review:
                return ServiceStatusIdEnums.Billing_Desk_Review_Scheduled_Pending_Receipt_Of_Records;

            case ServiceIdEnums.Full_Day_Testimony:
                return ServiceStatusIdEnums.CRS_Full_Day_Testimony_Dates_Requested_Pending_Confirmation;

            case ServiceIdEnums.Half_Day_Testimony:
                return ServiceStatusIdEnums.CRS_Half_Day_Testimony_Dates_Requested_Pending_Confirmation;

            case ServiceIdEnums.Deposition:
                return ServiceStatusIdEnums.CRS_Deposition_Dates_Requested_Pending_Confirmation;

            case ServiceIdEnums.Conference_Call_Between_Attorney_Physician_Or_Expert:
                return ServiceStatusIdEnums.CRS_Conference_Call_Pending_Scheduling;

            case ServiceIdEnums.IME_WC:
                return ServiceStatusIdEnums.WC_IME_Pending_Scheduling;

            case ServiceIdEnums.IME_Auto:
                return ServiceStatusIdEnums.Auto_IME_Pending_scheduling;

            case ServiceIdEnums.AUTO_Record_Review:
                return ServiceStatusIdEnums.Auto_Review_Requested_pending_physician_selection;

            case ServiceIdEnums.WC_Record_Review:
                return ServiceStatusIdEnums.WC_Review_Requested_pending_physician_selection;

            case ServiceIdEnums.WC_IME_Re_Examination:
                return ServiceStatusIdEnums.WC_IME_Re_Exam_Pending_scheduling;

            case ServiceIdEnums.WC_Film_Review:
                return ServiceStatusIdEnums.WC_Radiology_Review_Pending_receipt_of_films_from_client;

            case ServiceIdEnums.WC_Independent_Film_Review:
                return ServiceStatusIdEnums.WC_Independent_Radiology_Review_Pending_receipt_of_films_from_client;

            case ServiceIdEnums.WC_Functional_Capacity:
                return ServiceStatusIdEnums.WC_FCE_Pending_scheduling;

            case ServiceIdEnums.Auto_Radiology_Review:
                return ServiceStatusIdEnums.Auto_Radiology_Pending_receipt_of_films_from_client;

            case ServiceIdEnums.Auto_Independent_Film_Review:
                return ServiceStatusIdEnums.Auto_Independent_Radiology_Pending_receipt_of_films_from_client;

            case ServiceIdEnums.Auto_Addendum:
                return ServiceStatusIdEnums.Auto_Addendum_Scheduled_pending_receipt_of_records;

            case ServiceIdEnums.Auto_Conference_Call:
                return ServiceStatusIdEnums.Auto_Conference_Pending_scheduling;

            case ServiceIdEnums.WC_Addendum:
                return ServiceStatusIdEnums.WC_Addendum_Scheduled_pending_receipt_of_records;

            case ServiceIdEnums.WC_Conference_Call:
                return ServiceStatusIdEnums.WC_Conference_Call_Pending_scheduling;

            case ServiceIdEnums.AUTO_Peer_Review:
                return ServiceStatusIdEnums.Auto_Peer_Scheduled_pending_receipt_of_records;

            case ServiceIdEnums.Auto_Re_Exam:
                return ServiceStatusIdEnums.Auto_Re_Exam_Pending_scheduling;

            case ServiceIdEnums.Auto_NF_10:
                return ServiceStatusIdEnums.Auto_NF_10_Pending_scheduling;

            case ServiceIdEnums.Subpoena_Processing:
                return ServiceStatusIdEnums.Subpoena_Pending_Retrieval_Document_Submission_By_Mail_Or_Fax;

            case ServiceIdEnums.Radiology_Received_From_Client:
                return ServiceStatusIdEnums.RadiologyReceivedFromClient_Pending_Retrieval_Document_Submission_By_Mail_Or_Fax;

            default:
                return 1;
        }
    }

    getNewService(serviceId: number, authuserId: number, imedClaimId = 0): IImedClaimService {
        const now = new Date();
        const newService = { ...emptyImedClaimService };
        newService.ImeServices_ImedClaimServiceId = [];
        newService.RecordRetrievalServices = [];
        newService.FilmReviewServices = [];
        newService.RecordReviewServices = [];
        newService.BillDeskReviewServices = [];
        newService.TestimonyDepositionServices = [];
        newService.ConferenceCallServices = [];

        newService.ImedClaimId = imedClaimId;
        newService.CreatedById = authuserId;
        newService.DateCreated = now;
        newService.ServiceId = serviceId;
        newService.ServiceStatusId = this.getServiceStatusForNewService(serviceId);
        newService.StatusSetDate = now;
        const newIMEService = { ...emptyImeService };
        const newNF10Service = { ...emptyImeService };
        const newNf10ServiceFormData = { ...emptyNf10Service };
        const newRecordRetrivevalService = { ...emptyRecordRetrievalService };
        const newFilmReviewService = { ...emptyFilmReviewService };
        const newWCFilmReviewService = { ...emptyFilmReviewService };
        const newAutoFilmReviewService = { ...emptyFilmReviewService };
        const newRecordReviewService = { ...emptyRecordReviewService };
        const newBillDeskReviewService = { ...emptyBillDeskReviewService };
        const newConferenceCallService = { ...emptyConferenceCallService };
        const newWcRecordReviewService = { ...emptyRecordReviewService };
        const newAutoRecordReviewService = { ...emptyRecordReviewService };
        const newAutoPeerReviewService = { ...emptyRecordReviewService };

        switch (serviceId as ServiceIdEnums) {
            case ServiceIdEnums.IME_Conference_Call:
            case ServiceIdEnums.IME_Expert_Consultation:
            case ServiceIdEnums.IME_Addendum_Additional_Records:
            case ServiceIdEnums.IME_Re_Examination:
            case ServiceIdEnums.IME_WC:
            case ServiceIdEnums.WC_IME_Re_Examination:
            case ServiceIdEnums.WC_Functional_Capacity:
            case ServiceIdEnums.WC_Addendum:
            case ServiceIdEnums.WC_Conference_Call:
            case ServiceIdEnums.IME_Auto:
            case ServiceIdEnums.Auto_Addendum:
            case ServiceIdEnums.Auto_Conference_Call:
            case ServiceIdEnums.Auto_Re_Exam:
            case ServiceIdEnums.IME:
                newIMEService.CreatedById = authuserId;
                newIMEService.DateCreated = now;
                newService.ImeServices_ImedClaimServiceId.push(newIMEService);
                break;
            case ServiceIdEnums.Auto_NF_10:
                newNf10ServiceFormData.CreatedById = authuserId;
                newNf10ServiceFormData.DateCreated = now;
                newService.Nf10Services = [newNf10ServiceFormData];
                newNF10Service.CreatedById = authuserId;
                newNF10Service.DateCreated = now;
                newService.ImeServices_ImedClaimServiceId.push(newNF10Service);
                break;
            case ServiceIdEnums.Record_Retrievals_HIPAA_Processing:
                newRecordRetrivevalService.CreatedById = authuserId;
                newRecordRetrivevalService.DateCreated = now;
                newService.RecordRetrievalServices.push(newRecordRetrivevalService);
                break;
            case ServiceIdEnums.Radiology_Received_From_Client:
                newRecordRetrivevalService.CreatedById = authuserId;
                newRecordRetrivevalService.DateCreated = now;
                newService.RecordRetrievalServices.push(newRecordRetrivevalService);
                break;
            case ServiceIdEnums.Film_Review:
            case ServiceIdEnums.Independent_Film_Review:
                newFilmReviewService.CreatedById = authuserId;
                newFilmReviewService.DateCreated = now;
                newService.FilmReviewServices.push(newFilmReviewService);
                break;

            case ServiceIdEnums.WC_Film_Review:
            case ServiceIdEnums.WC_Independent_Film_Review:
                newWCFilmReviewService.CreatedById = authuserId;
                newWCFilmReviewService.DateCreated = now;
                newService.FilmReviewServices.push(newWCFilmReviewService);
                break;

            case ServiceIdEnums.Auto_Radiology_Review:
            case ServiceIdEnums.Auto_Independent_Film_Review:
                newAutoFilmReviewService.CreatedById = authuserId;
                newAutoFilmReviewService.DateCreated = now;
                newService.FilmReviewServices.push(newAutoFilmReviewService);
                break;

            case ServiceIdEnums.Record_Review:
                newRecordReviewService.CreatedById = authuserId;
                newRecordReviewService.DateCreated = now;
                newService.RecordReviewServices.push(newRecordReviewService);
                break;

            case ServiceIdEnums.Billing_Desk_Review:
                newBillDeskReviewService.CreatedById = authuserId;
                newBillDeskReviewService.DateCreated = now;
                newService.BillDeskReviewServices.push(newBillDeskReviewService);
                break;

            case ServiceIdEnums.Full_Day_Testimony:
                newService.TestimonyDepositionServices.push(this.getTestimonyDepositionRelatedService(authuserId, now));
                break;

            case ServiceIdEnums.Half_Day_Testimony:
                newService.TestimonyDepositionServices.push(this.getTestimonyDepositionRelatedService(authuserId, now));
                break;

            case ServiceIdEnums.Deposition:
                newService.TestimonyDepositionServices.push(this.getTestimonyDepositionRelatedService(authuserId, now));
                break;

            case ServiceIdEnums.Conference_Call_Between_Attorney_Physician_Or_Expert:
                newConferenceCallService.CreatedById = authuserId;
                newConferenceCallService.DateCreated = now;
                newService.ConferenceCallServices.push(newConferenceCallService);
                break;
            case ServiceIdEnums.WC_Record_Review:
                newService.PurposeOfExam =
                    'Please bring all available recent medical records, MRIs, CT scans, x-rays, and other diagnostic test results to the examiner at the time of the exam for review. Please comply with all local ordinances regarding COVID 19 and be sure to wear a face covering and bring Photo ID.';
                newWcRecordReviewService.CreatedById = authuserId;
                newWcRecordReviewService.DateCreated = now;
                newService.RecordReviewServices.push(newWcRecordReviewService);
                break;

            case ServiceIdEnums.AUTO_Record_Review:
                newAutoRecordReviewService.CreatedById = authuserId;
                newAutoRecordReviewService.DateCreated = now;
                newService.RecordReviewServices.push(newAutoRecordReviewService);
                break;

            case ServiceIdEnums.AUTO_Peer_Review:
                newAutoPeerReviewService.CreatedById = authuserId;
                newAutoPeerReviewService.DateCreated = now;
                newService.RecordReviewServices.push(newAutoPeerReviewService);
                break;

            default:
                break;
        }

        return newService;
    }

    getTestimonyDepositionRelatedService(authuserId, now): ITestimonyDepositionService {
        const newService = { ...emptyTestimonyDepositionService };
        newService.CreatedById = authuserId;
        newService.DateCreated = now;
        return newService;
    }

    showSoftPopupWhenDateOfAllegedNotFilled(imedClaim: IImedClaimPartial, claimServices: number[]): boolean {
        if (imedClaim.Id === 0) {
            const ServicesOtherThanRecordRetrival = claimServices.filter((item) => {
                return item !== +ServiceIdEnums.Record_Retrievals_HIPAA_Processing && item !== +ServiceIdEnums.Radiology_Received_From_Client;
            });
            const hasAnyServicesOtherThanRecordRetrival = ServicesOtherThanRecordRetrival !== null && ServicesOtherThanRecordRetrival.length > 0;
            if (!imedClaim.DateofAllegedAccidentOrInjury) {
                return hasAnyServicesOtherThanRecordRetrival;
            }
        } else {
            const ServicesOtherThanRecordRetrival = imedClaim.ImedClaimServices.filter((item) => {
                return item.ServiceId !== +ServiceIdEnums.Record_Retrievals_HIPAA_Processing && item.ServiceId !== +ServiceIdEnums.Radiology_Received_From_Client;
            });
            const hasAnyServicesOtherThanRecordRetrival = ServicesOtherThanRecordRetrival !== null && ServicesOtherThanRecordRetrival.length > 0;

            if (!imedClaim.DateofAllegedAccidentOrInjury) {
                return hasAnyServicesOtherThanRecordRetrival;
            }
        }

        return false;
    }

    createServicesForClaimCreation(imedClaim: IImedClaimPartial, claimServices: number[], authuserId: number): IImedClaimPartial {
        imedClaim.ImedClaimServices = [];
        claimServices.forEach((serviceId) => {
            imedClaim.ImedClaimServices.push(this.getNewService(serviceId, authuserId));
        });
        return imedClaim;
    }

    attachAddressForClaimCreation(imedClaim: IImedClaimPartial, address: any, authuserId: number): IImedClaimPartial {
        if (
            address &&
            address.Address1 !== '' &&
            address.City !== '' &&
            address.StateId > 0 &&
            address.Zip !== '' &&
            address.AddressTypes.length > 0
        ) {
            imedClaim.Addresses = [];
            const now = new Date();
            let newAddress = <IAddress>{};
            newAddress = address;
            newAddress.IsPrimary = true;
            newAddress.CreatedById = authuserId;
            newAddress.DateCreated = now;
            imedClaim.Addresses.push(newAddress);
        }
        return imedClaim;
    }

    attachPhoneNumberAndEmailForClaimCreation(
        imedClaim: IImedClaimPartial,
        emailAddress: IEmailAddress,
        phoneNumber: IPhoneNumber,
        authuserId: number,
    ): IImedClaimPartial {
        imedClaim.EmailAddresses = [];
        const now = new Date();
        let newEmailAddress = <IEmailAddress>{};
        newEmailAddress = emailAddress;
        newEmailAddress.IsPrimary = true;
        newEmailAddress.DateCreated = now;
        newEmailAddress.CreatedById = authuserId;
        imedClaim.EmailAddresses.push(newEmailAddress);

        imedClaim.PhoneNumbers = [];
        let newPhoneNumber = <IPhoneNumber>{};
        newPhoneNumber = phoneNumber;
        newPhoneNumber.IsPrimary = true;
        newPhoneNumber.DateCreated = now;
        newPhoneNumber.CreatedById = authuserId;
        imedClaim.PhoneNumbers.push(newPhoneNumber);
        return imedClaim;
    }

    setIsDefault(addressBookType: string, checked: boolean, imedClaimContacts: IIMedClaimContactsInfo): IIMedClaimContactsInfo {
        switch (addressBookType as AddressBookSelectionEnums) {
            case AddressBookSelectionEnums.PlantiffAttorney:
                imedClaimContacts.PlantiffAttorneyIsDefault = checked;
                break;
            case AddressBookSelectionEnums.DefenseAttorney:
                imedClaimContacts.DefenseAttorneyIsDefault = checked;
                break;
            case AddressBookSelectionEnums.Other:
                imedClaimContacts.OtherIsDefault = checked;
                break;
            case AddressBookSelectionEnums.ParaLegal:
                imedClaimContacts.ParaLegalIsDefault = checked;
                break;
            case AddressBookSelectionEnums.CarrierParty:
                imedClaimContacts.CarrierPartyIsDefault = checked;
                break;
            case AddressBookSelectionEnums.Adjuster:
                imedClaimContacts.AdjusterIsDefault = checked;
                break;
            case AddressBookSelectionEnums.ClaimantsAttorney:
                imedClaimContacts.ClaimantsAttorneyIsDefault = checked;
                break;
            case AddressBookSelectionEnums.CarriersCounsel:
                imedClaimContacts.CarriersCounselIsDefault = checked;
                break;
            case AddressBookSelectionEnums.Employer:
                imedClaimContacts.EmployerIsDefault = checked;
                break;
            default:
                break;
        }
        return imedClaimContacts;
    }

    getImedClaimStatusId(imedClaimId: number): Observable<IMedClaimStatusIdEnums> {
        return this.http.post<IMedClaimStatusIdEnums>(`/imedclaims/${imedClaimId}/getImedClaimStatusId`, {});
    }

    getHeaderInfo(imedClaimId: number, imedClaimServiceId?: number): Observable<IHeaderInfoDTO> {
        return this.http.get<IHeaderInfoDTO>(`/imedclaims/${imedClaimId}/get-header-info`, {
            params: {
                imedClaimServiceId: imedClaimServiceId ? imedClaimServiceId.toString() : '',
            },
        });
    }

    mergeCases(primaryClaimId: number, secondaryClaimId: number): any {
        return this.http.put(`/imedclaims/merge-cases`, { PrimaryClaimId: primaryClaimId, SecondaryClaimId: secondaryClaimId });
    }

    getImedClaimServiceListForDropdown(imedClaimId: number): Observable<any> {
        return this.http.get(`/imedclaims/getImedClaimServiceListForDropdown/${imedClaimId}`, {});
    }

    downloadSummarySheet(entityTypeName: string, imedClaimId: number): Observable<any> {
        return this.http.post(`/${entityTypeName}/download`, imedClaimId, { observe: 'response', responseType: 'blob' as 'json' });
    }

    checkForDuplicates(imedClaim: IImedClaim): Observable<IImedClaim[]> {
        return this.http.post<IImedClaim[]>(`/imedclaims/check-for-duplicates`, imedClaim);
    }

    togglePhysicalCheckRequired(imedClaim: IImedClaim): any {
        return this.http.put(`/imedclaims/togglePhysicalCheckRequired/${imedClaim.Id}`, {});
    }

    getAdditionalRecords(imedClaimId: number): Observable<IImedClaimAdditionalRecord[]> {
        return this.http.get<IImedClaimAdditionalRecord[]>(`/imedclaims/${imedClaimId}/additional-records`, {});
    }

    checkForConsentToChangeDocument(Id): Observable<boolean> {
        return this.http.get<boolean>(`/imedclaims/checkForConsentToChangeDocument/${Id}`);
    }
}
