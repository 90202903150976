import { FilmInformationService, emptyFilmInformation } from '@app-shared/services/film-information.service';
import { FilmInformationDynamicConfig } from './film-information.dynamic-config';
import { IFilmInformation } from '@model/interfaces/film-information.d';
import { IImedClaimService } from '@model/interfaces/imed-claim-service.d';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import 'rxjs/operators';
import { finalize } from 'rxjs/operators';
import { IBodyPart } from '@model/interfaces/body-part';
import { IImageType } from '@model/interfaces/image-type';
import { ImageTypeService } from '../../imed-managed-list/image-type.service';
import { BodyPartService } from '../../imed-managed-list/body-part.service';
import { forkJoin } from 'rxjs';
import { IFilmInformationDTO } from '@model/interfaces/custom/film-information-dto';
import { IRecordRetrievalService } from '@model/interfaces/record-retrieval-service';
import { DynamicLabel, DynamicField, IDynamicFormConfig } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-imed-claim-service-film-information',
    templateUrl: './imed-claim-service-film-information.component.html',
})
export class ImedClaimServiceFilmInformationComponent implements OnInit {
    @Input() iImedClaimServiceModel: IImedClaimService;
    @Input() serviceModel: IRecordRetrievalService;
    @Input() canEdit: boolean;
    @Input() filmInformations: IFilmInformationDTO[];
    @Output('refreshFilmInformation') refreshFilmInformation: EventEmitter<any> = new EventEmitter<any>();
    @Input() isAddingFilmInfo: boolean;

    filmInformation: IFilmInformation;

    isEditing: boolean;
    isHovered: boolean;
    config: IDynamicFormConfig;
    form: UntypedFormGroup;
    imeAppointmentForm: any;
    formFactory: FilmInformationDynamicConfig<IFilmInformation>;
    doubleClickIsDisabled = false;
    softPopUpErrorMessages = Function;
    imageTypes: IImageType[] = [];
    bodyParts: IBodyPart[] = [];

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private filmInformationService: FilmInformationService,
        private notificationsService: NotificationsService,
        private fb: UntypedFormBuilder,
        private imageTypeService: ImageTypeService,
        private bodyPartService: BodyPartService,
    ) {}

    ngOnInit(): void {
        this.setConfig(null);

        forkJoin(this.bodyPartService.getItems(), this.imageTypeService.getItems()).subscribe(
            ([bodyParts, imageTypes]) => {
                this.bodyParts = bodyParts;
                this.imageTypes = imageTypes;
            },
            () => null,
            () => this.setConfig(null),
        );
    }

    cancel(): void {
        this.resetFields();
        this.isEditing = false;
    }

    resetFields(): void {
        this.filmInformation = { ...emptyFilmInformation };
        this.setConfig(null);
    }

    addItem(): void {
        if (this.canEdit) {
            this.setConfig(null);
            this.isEditing = true;
        }
    }

    delete(filmInformation: IFilmInformation): void {
        filmInformation.Archived = true;
        let deleteSub;

        if (filmInformation.StudyUuid) {
            deleteSub = this.filmInformationService.deleteAmbraStudy(filmInformation.Id);
        } else {
            deleteSub = this.filmInformationService.deleteFilm(filmInformation.Id);
        }

        deleteSub.subscribe(() => {
            this.deletedMessage();
            this.refreshFilmInformation.emit();
        });
    }

    editItem(filmInformation: IFilmInformation): void {
        this.isEditing = false;
        this.setConfig(filmInformation);
        setTimeout(() => {
            if (this.canEdit) {
                this.isEditing = true;
            }
        });
    }

    setConfig(filmInformation: IFilmInformation): void {
        this.filmInformation = filmInformation ? filmInformation : { ...emptyFilmInformation };
        this.form = this.fb.group({});
        this.config = { formObject: [], viewOnly: [] };
        this.formFactory = new FilmInformationDynamicConfig<IFilmInformation>(
            this.bodyParts,
            this.imageTypes,
            this.filmInformation,
            this.serviceModel?.RadiologyFacility,
            ['ImageDate', 'ImageTypeId', 'BodyPartImagedId', 'DateProcessed', 'FacilityName'],
        );
        this.config = this.formFactory.getForUpdate();

        this.viewOnly = this.config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = this.config.formObject?.map((x) => new DynamicField(x));
    }

    formSubmitted(): void {
        if (this.form.valid) {
            this.filmInformation.ImedClaimServiceId = this.iImedClaimServiceModel.Id;
            this.formFactory.assignFormValues(this.filmInformation, this.form.value.FilmInformation as IFilmInformation);
            if (this.filmInformation.Id > 0) {
                this.filmInformation = this.filmInformationService.normalizeFilmInformation(this.filmInformation);
                this.filmInformationService.update(this.filmInformation).subscribe(() => {
                    this.resetFields();
                    this.isEditing = false;
                    this.success();
                    this.refreshFilmInformation.emit();
                });
            } else {
                const radiologyFacilityName = this.serviceModel?.RadiologyFacility;
                if (!this.filmInformation.FacilityName && radiologyFacilityName) {
                    this.filmInformation.FacilityName = radiologyFacilityName;
                }
                this.filmInformationService.create(this.filmInformation).subscribe(() => {
                    this.resetFields();
                    this.isEditing = false;
                    this.success();
                    this.refreshFilmInformation.emit();
                });
            }
        } else {
            markAllFormFieldsAsTouched(this.form);
            this.error();
        }
    }

    error(): void {
        this.notificationsService.error('Save Failed');
    }

    deletedMessage(): void {
        this.notificationsService.success('Film Information Deleted');
    }

    success(): void {
        this.notificationsService.success('Film Information Saved Successfully');
    }
}
