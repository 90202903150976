import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IPhysicianProfileAssociation } from '../interfaces/physician-profile-association';
import { IPhysician } from '../interfaces/physician';

export interface IPhysicianProfileAssociationDynamicControlsParameters {
    formGroup?: string;
    primaryPhysicians?: IPhysician[];
    duplicatePhysicians?: IPhysician[];
}

export class PhysicianProfileAssociationDynamicControls {

    formGroup: string;
    primaryPhysicians?: IPhysician[];
    duplicatePhysicians?: IPhysician[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private physicianprofileassociation?: IPhysicianProfileAssociation, additionalParameters?: IPhysicianProfileAssociationDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'PhysicianProfileAssociation';
        this.primaryPhysicians = additionalParameters && additionalParameters.primaryPhysicians || undefined;
        this.duplicatePhysicians = additionalParameters && additionalParameters.duplicatePhysicians || undefined;

        this.Form = {
            DuplicatePhysicianId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Duplicate Physician',
                name: 'DuplicatePhysicianId',
                options: this.duplicatePhysicians,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.physicianprofileassociation && this.physicianprofileassociation.DuplicatePhysicianId || null,
            }),
            PrimaryPhysicianId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Primary Physician',
                name: 'PrimaryPhysicianId',
                options: this.primaryPhysicians,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.physicianprofileassociation && this.physicianprofileassociation.PrimaryPhysicianId || null,
            }),
        };

        this.View = {
            DuplicatePhysicianId: new DynamicLabel({
                label: 'Duplicate Physician',
                value: getMetaItemValue(this.duplicatePhysicians as unknown as IMetaItem[], this.physicianprofileassociation && this.physicianprofileassociation.hasOwnProperty('DuplicatePhysicianId') ? this.physicianprofileassociation.DuplicatePhysicianId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            PrimaryPhysicianId: new DynamicLabel({
                label: 'Primary Physician',
                value: getMetaItemValue(this.primaryPhysicians as unknown as IMetaItem[], this.physicianprofileassociation && this.physicianprofileassociation.hasOwnProperty('PrimaryPhysicianId') ? this.physicianprofileassociation.PrimaryPhysicianId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
