import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { ISubpoenaService } from '../interfaces/subpoena-service';
import { IAddressBook } from '../interfaces/address-book';
import { IImedClaimCourtFee } from '../interfaces/imed-claim-court-fee';
import { IImedClaimService } from '../interfaces/imed-claim-service';

export interface ISubpoenaServiceDynamicControlsParameters {
    formGroup?: string;
    imedClaimServices?: IImedClaimService[];
    foreignCourts?: IAddressBook[];
    processServers?: IAddressBook[];
    imedClaimCourtFees?: IImedClaimCourtFee[];
}

export class SubpoenaServiceDynamicControls {

    formGroup: string;
    imedClaimServices?: IImedClaimService[];
    foreignCourts?: IAddressBook[];
    processServers?: IAddressBook[];
    imedClaimCourtFees?: IImedClaimCourtFee[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private subpoenaservice?: ISubpoenaService, additionalParameters?: ISubpoenaServiceDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'SubpoenaService';
        this.imedClaimServices = additionalParameters && additionalParameters.imedClaimServices || undefined;
        this.foreignCourts = additionalParameters && additionalParameters.foreignCourts || undefined;
        this.processServers = additionalParameters && additionalParameters.processServers || undefined;
        this.imedClaimCourtFees = additionalParameters && additionalParameters.imedClaimCourtFees || undefined;

        this.Form = {
            AffidavitOfService: new DynamicField({
                formGroup: this.formGroup,
                label: 'Affidavit Of Service',
                name: 'AffidavitOfService',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('AffidavitOfService') && this.subpoenaservice.AffidavitOfService != null ? this.subpoenaservice.AffidavitOfService : true,
            }),
            CertificationOfRecords: new DynamicField({
                formGroup: this.formGroup,
                label: 'Certification Of Records',
                name: 'CertificationOfRecords',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('CertificationOfRecords') && this.subpoenaservice.CertificationOfRecords != null ? this.subpoenaservice.CertificationOfRecords : true,
            }),
            DepositionSubpoena: new DynamicField({
                formGroup: this.formGroup,
                label: 'Deposition Subpoena',
                name: 'DepositionSubpoena',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('DepositionSubpoena') && this.subpoenaservice.DepositionSubpoena != null ? this.subpoenaservice.DepositionSubpoena : false,
            }),
            DomesticationRequired: new DynamicField({
                formGroup: this.formGroup,
                label: 'Domestication Required',
                name: 'DomesticationRequired',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('DomesticationRequired') && this.subpoenaservice.DomesticationRequired != null ? this.subpoenaservice.DomesticationRequired : false,
            }),
            ForeignCourtId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Foreign Court',
                name: 'ForeignCourtId',
                options: this.foreignCourts,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.subpoenaservice && this.subpoenaservice.ForeignCourtId || null,
            }),
            ImedClaimCourtFeeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Court Fee',
                name: 'ImedClaimCourtFeeId',
                options: this.imedClaimCourtFees,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.subpoenaservice && this.subpoenaservice.ImedClaimCourtFeeId || null,
            }),
            ImedClaimServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Service',
                name: 'ImedClaimServiceId',
                options: this.imedClaimServices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.subpoenaservice && this.subpoenaservice.ImedClaimServiceId || null,
            }),
            LetterOfIntent: new DynamicField({
                formGroup: this.formGroup,
                label: 'Letter Of Intent',
                name: 'LetterOfIntent',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('LetterOfIntent') && this.subpoenaservice.LetterOfIntent != null ? this.subpoenaservice.LetterOfIntent : true,
            }),
            LetterOfRepresentation: new DynamicField({
                formGroup: this.formGroup,
                label: 'Letter Of Representation',
                name: 'LetterOfRepresentation',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('LetterOfRepresentation') && this.subpoenaservice.LetterOfRepresentation != null ? this.subpoenaservice.LetterOfRepresentation : true,
            }),
            OptionalField1: new DynamicField({
                formGroup: this.formGroup,
                label: 'Optional Field1',
                name: 'OptionalField1',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('OptionalField1') && this.subpoenaservice.OptionalField1 != null ? this.subpoenaservice.OptionalField1.toString() : '',
            }),
            OptionalField2: new DynamicField({
                formGroup: this.formGroup,
                label: 'Optional Field2',
                name: 'OptionalField2',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('OptionalField2') && this.subpoenaservice.OptionalField2 != null ? this.subpoenaservice.OptionalField2.toString() : '',
            }),
            OtherSubpoena: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other Subpoena',
                name: 'OtherSubpoena',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('OtherSubpoena') && this.subpoenaservice.OtherSubpoena != null ? this.subpoenaservice.OtherSubpoena : false,
            }),
            PhysicalCheckToFacilityRequired: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physical Check To Facility Required',
                name: 'PhysicalCheckToFacilityRequired',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('PhysicalCheckToFacilityRequired') && this.subpoenaservice.PhysicalCheckToFacilityRequired != null ? this.subpoenaservice.PhysicalCheckToFacilityRequired : false,
            }),
            PhysicalCheckToFacilityRequiredFacilityFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physical Check To Facility Required Facility Fee',
                name: 'PhysicalCheckToFacilityRequiredFacilityFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('PhysicalCheckToFacilityRequiredFacilityFee') && this.subpoenaservice.PhysicalCheckToFacilityRequiredFacilityFee != null ? this.subpoenaservice.PhysicalCheckToFacilityRequiredFacilityFee : false,
            }),
            PhysicalCheckToFacilityRequiredProcessServerFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physical Check To Facility Required Process Server Fee',
                name: 'PhysicalCheckToFacilityRequiredProcessServerFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('PhysicalCheckToFacilityRequiredProcessServerFee') && this.subpoenaservice.PhysicalCheckToFacilityRequiredProcessServerFee != null ? this.subpoenaservice.PhysicalCheckToFacilityRequiredProcessServerFee : false,
            }),
            PhysicalCheckToFacilityRequiredWitnessFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physical Check To Facility Required Witness Fee',
                name: 'PhysicalCheckToFacilityRequiredWitnessFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('PhysicalCheckToFacilityRequiredWitnessFee') && this.subpoenaservice.PhysicalCheckToFacilityRequiredWitnessFee != null ? this.subpoenaservice.PhysicalCheckToFacilityRequiredWitnessFee : false,
            }),
            PhysicalCheckToForeignCourtRequiredWitnessFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physical Check To Foreign Court Required Witness Fee',
                name: 'PhysicalCheckToForeignCourtRequiredWitnessFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('PhysicalCheckToForeignCourtRequiredWitnessFee') && this.subpoenaservice.PhysicalCheckToForeignCourtRequiredWitnessFee != null ? this.subpoenaservice.PhysicalCheckToForeignCourtRequiredWitnessFee : false,
            }),
            ProcessServerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Process Server',
                name: 'ProcessServerId',
                options: this.processServers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.subpoenaservice && this.subpoenaservice.ProcessServerId || null,
            }),
            ProcessServerRequired: new DynamicField({
                formGroup: this.formGroup,
                label: 'Process Server Required',
                name: 'ProcessServerRequired',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('ProcessServerRequired') && this.subpoenaservice.ProcessServerRequired != null ? this.subpoenaservice.ProcessServerRequired : false,
            }),
            ProofOfservice: new DynamicField({
                formGroup: this.formGroup,
                label: 'Proof Ofservice',
                name: 'ProofOfservice',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('ProofOfservice') && this.subpoenaservice.ProofOfservice != null ? this.subpoenaservice.ProofOfservice : true,
            }),
            ProofOfServiceOfObjection: new DynamicField({
                formGroup: this.formGroup,
                label: 'Proof Of Service Of Objection',
                name: 'ProofOfServiceOfObjection',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('ProofOfServiceOfObjection') && this.subpoenaservice.ProofOfServiceOfObjection != null ? this.subpoenaservice.ProofOfServiceOfObjection : true,
            }),
            RadiologyFacility: new DynamicField({
                formGroup: this.formGroup,
                label: 'Radiology Facility',
                name: 'RadiologyFacility',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('RadiologyFacility') && this.subpoenaservice.RadiologyFacility != null ? this.subpoenaservice.RadiologyFacility.toString() : '',
            }),
            Reference: new DynamicField({
                formGroup: this.formGroup,
                label: 'Reference',
                name: 'Reference',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(1000) ],
                validators: { 'maxlength': 1000 },
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('Reference') && this.subpoenaservice.Reference != null ? this.subpoenaservice.Reference.toString() : '',
            }),
            SubpoenaCoverPage: new DynamicField({
                formGroup: this.formGroup,
                label: 'Subpoena Cover Page',
                name: 'SubpoenaCoverPage',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('SubpoenaCoverPage') && this.subpoenaservice.SubpoenaCoverPage != null ? this.subpoenaservice.SubpoenaCoverPage : true,
            }),
            SubpoenaDucesTecum: new DynamicField({
                formGroup: this.formGroup,
                label: 'Subpoena Duces Tecum',
                name: 'SubpoenaDucesTecum',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('SubpoenaDucesTecum') && this.subpoenaservice.SubpoenaDucesTecum != null ? this.subpoenaservice.SubpoenaDucesTecum : false,
            }),
            TrialSubpoena: new DynamicField({
                formGroup: this.formGroup,
                label: 'Trial Subpoena',
                name: 'TrialSubpoena',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('TrialSubpoena') && this.subpoenaservice.TrialSubpoena != null ? this.subpoenaservice.TrialSubpoena : false,
            }),
        };

        this.View = {
            AffidavitOfService: new DynamicLabel({
                label: 'Affidavit Of Service',
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('AffidavitOfService') && this.subpoenaservice.AffidavitOfService != null ? this.subpoenaservice.AffidavitOfService : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            CertificationOfRecords: new DynamicLabel({
                label: 'Certification Of Records',
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('CertificationOfRecords') && this.subpoenaservice.CertificationOfRecords != null ? this.subpoenaservice.CertificationOfRecords : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DepositionSubpoena: new DynamicLabel({
                label: 'Deposition Subpoena',
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('DepositionSubpoena') && this.subpoenaservice.DepositionSubpoena != null ? this.subpoenaservice.DepositionSubpoena : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DomesticationRequired: new DynamicLabel({
                label: 'Domestication Required',
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('DomesticationRequired') && this.subpoenaservice.DomesticationRequired != null ? this.subpoenaservice.DomesticationRequired : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ForeignCourtId: new DynamicLabel({
                label: 'Foreign Court',
                value: getMetaItemValue(this.foreignCourts as unknown as IMetaItem[], this.subpoenaservice && this.subpoenaservice.hasOwnProperty('ForeignCourtId') ? this.subpoenaservice.ForeignCourtId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ImedClaimCourtFeeId: new DynamicLabel({
                label: 'Imed Claim Court Fee',
                value: getMetaItemValue(this.imedClaimCourtFees as unknown as IMetaItem[], this.subpoenaservice && this.subpoenaservice.hasOwnProperty('ImedClaimCourtFeeId') ? this.subpoenaservice.ImedClaimCourtFeeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ImedClaimServiceId: new DynamicLabel({
                label: 'Imed Claim Service',
                value: getMetaItemValue(this.imedClaimServices as unknown as IMetaItem[], this.subpoenaservice && this.subpoenaservice.hasOwnProperty('ImedClaimServiceId') ? this.subpoenaservice.ImedClaimServiceId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            LetterOfIntent: new DynamicLabel({
                label: 'Letter Of Intent',
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('LetterOfIntent') && this.subpoenaservice.LetterOfIntent != null ? this.subpoenaservice.LetterOfIntent : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            LetterOfRepresentation: new DynamicLabel({
                label: 'Letter Of Representation',
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('LetterOfRepresentation') && this.subpoenaservice.LetterOfRepresentation != null ? this.subpoenaservice.LetterOfRepresentation : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            OptionalField1: new DynamicLabel({
                label: 'Optional Field1',
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('OptionalField1') && this.subpoenaservice.OptionalField1 != null ? this.subpoenaservice.OptionalField1.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            OptionalField2: new DynamicLabel({
                label: 'Optional Field2',
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('OptionalField2') && this.subpoenaservice.OptionalField2 != null ? this.subpoenaservice.OptionalField2.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            OtherSubpoena: new DynamicLabel({
                label: 'Other Subpoena',
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('OtherSubpoena') && this.subpoenaservice.OtherSubpoena != null ? this.subpoenaservice.OtherSubpoena : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            PhysicalCheckToFacilityRequired: new DynamicLabel({
                label: 'Physical Check To Facility Required',
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('PhysicalCheckToFacilityRequired') && this.subpoenaservice.PhysicalCheckToFacilityRequired != null ? this.subpoenaservice.PhysicalCheckToFacilityRequired : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            PhysicalCheckToFacilityRequiredFacilityFee: new DynamicLabel({
                label: 'Physical Check To Facility Required Facility Fee',
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('PhysicalCheckToFacilityRequiredFacilityFee') && this.subpoenaservice.PhysicalCheckToFacilityRequiredFacilityFee != null ? this.subpoenaservice.PhysicalCheckToFacilityRequiredFacilityFee : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            PhysicalCheckToFacilityRequiredProcessServerFee: new DynamicLabel({
                label: 'Physical Check To Facility Required Process Server Fee',
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('PhysicalCheckToFacilityRequiredProcessServerFee') && this.subpoenaservice.PhysicalCheckToFacilityRequiredProcessServerFee != null ? this.subpoenaservice.PhysicalCheckToFacilityRequiredProcessServerFee : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            PhysicalCheckToFacilityRequiredWitnessFee: new DynamicLabel({
                label: 'Physical Check To Facility Required Witness Fee',
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('PhysicalCheckToFacilityRequiredWitnessFee') && this.subpoenaservice.PhysicalCheckToFacilityRequiredWitnessFee != null ? this.subpoenaservice.PhysicalCheckToFacilityRequiredWitnessFee : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            PhysicalCheckToForeignCourtRequiredWitnessFee: new DynamicLabel({
                label: 'Physical Check To Foreign Court Required Witness Fee',
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('PhysicalCheckToForeignCourtRequiredWitnessFee') && this.subpoenaservice.PhysicalCheckToForeignCourtRequiredWitnessFee != null ? this.subpoenaservice.PhysicalCheckToForeignCourtRequiredWitnessFee : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ProcessServerId: new DynamicLabel({
                label: 'Process Server',
                value: getMetaItemValue(this.processServers as unknown as IMetaItem[], this.subpoenaservice && this.subpoenaservice.hasOwnProperty('ProcessServerId') ? this.subpoenaservice.ProcessServerId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ProcessServerRequired: new DynamicLabel({
                label: 'Process Server Required',
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('ProcessServerRequired') && this.subpoenaservice.ProcessServerRequired != null ? this.subpoenaservice.ProcessServerRequired : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ProofOfservice: new DynamicLabel({
                label: 'Proof Ofservice',
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('ProofOfservice') && this.subpoenaservice.ProofOfservice != null ? this.subpoenaservice.ProofOfservice : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ProofOfServiceOfObjection: new DynamicLabel({
                label: 'Proof Of Service Of Objection',
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('ProofOfServiceOfObjection') && this.subpoenaservice.ProofOfServiceOfObjection != null ? this.subpoenaservice.ProofOfServiceOfObjection : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            RadiologyFacility: new DynamicLabel({
                label: 'Radiology Facility',
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('RadiologyFacility') && this.subpoenaservice.RadiologyFacility != null ? this.subpoenaservice.RadiologyFacility.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Reference: new DynamicLabel({
                label: 'Reference',
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('Reference') && this.subpoenaservice.Reference != null ? this.subpoenaservice.Reference.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            SubpoenaCoverPage: new DynamicLabel({
                label: 'Subpoena Cover Page',
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('SubpoenaCoverPage') && this.subpoenaservice.SubpoenaCoverPage != null ? this.subpoenaservice.SubpoenaCoverPage : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            SubpoenaDucesTecum: new DynamicLabel({
                label: 'Subpoena Duces Tecum',
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('SubpoenaDucesTecum') && this.subpoenaservice.SubpoenaDucesTecum != null ? this.subpoenaservice.SubpoenaDucesTecum : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            TrialSubpoena: new DynamicLabel({
                label: 'Trial Subpoena',
                value: this.subpoenaservice && this.subpoenaservice.hasOwnProperty('TrialSubpoena') && this.subpoenaservice.TrialSubpoena != null ? this.subpoenaservice.TrialSubpoena : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
