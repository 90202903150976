import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IImedClaimService } from '../interfaces/imed-claim-service';
import { IAutoIssue } from '../interfaces/auto-issue';
import { IUser } from '../interfaces/user';
import { IEligibilityCriteria } from '../interfaces/eligibility-criteria';
import { IAddressBook } from '../interfaces/address-book';
import { IIndividual } from '../interfaces/individual';
import { IImedClaimCourtFee } from '../interfaces/imed-claim-court-fee';
import { IImedClaim } from '../interfaces/imed-claim';
import { IPhysician } from '../interfaces/physician';
import { IAddress } from '../interfaces/address';
import { IService } from '../interfaces/service';
import { IServiceStatus } from '../interfaces/service-status';
import { ISpecialty } from '../interfaces/specialty';
import { IWcIssue } from '../interfaces/wc-issue';

export interface IImedClaimServiceDynamicControlsParameters {
    formGroup?: string;
    imedClaims?: IImedClaim[];
    services?: IService[];
    physicians?: IPhysician[];
    serviceStatuses?: IServiceStatus[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
    serviceAddresses?: IAddress[];
    facilityAddressBooks?: IAddressBook[];
    facilityIndividuals?: IIndividual[];
    serviceAssignedTos?: IUser[];
    retrievalAssignedTos?: IUser[];
    subpoenaAssignedTos?: IUser[];
    specialtyRequesteds?: ISpecialty[];
    eligibilityCriteria?: IEligibilityCriteria[];
    imedClaimCourtFees?: IImedClaimCourtFee[];
    wcIssues?: IWcIssue[];
    autoIssues?: IAutoIssue[];
}

export class ImedClaimServiceDynamicControls {

    formGroup: string;
    imedClaims?: IImedClaim[];
    services?: IService[];
    physicians?: IPhysician[];
    serviceStatuses?: IServiceStatus[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
    serviceAddresses?: IAddress[];
    facilityAddressBooks?: IAddressBook[];
    facilityIndividuals?: IIndividual[];
    serviceAssignedTos?: IUser[];
    retrievalAssignedTos?: IUser[];
    subpoenaAssignedTos?: IUser[];
    specialtyRequesteds?: ISpecialty[];
    eligibilityCriteria?: IEligibilityCriteria[];
    imedClaimCourtFees?: IImedClaimCourtFee[];
    wcIssues?: IWcIssue[];
    autoIssues?: IAutoIssue[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private imedclaimservice?: IImedClaimService, additionalParameters?: IImedClaimServiceDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ImedClaimService';
        this.imedClaims = additionalParameters && additionalParameters.imedClaims || undefined;
        this.services = additionalParameters && additionalParameters.services || undefined;
        this.physicians = additionalParameters && additionalParameters.physicians || undefined;
        this.serviceStatuses = additionalParameters && additionalParameters.serviceStatuses || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;
        this.serviceAddresses = additionalParameters && additionalParameters.serviceAddresses || undefined;
        this.facilityAddressBooks = additionalParameters && additionalParameters.facilityAddressBooks || undefined;
        this.facilityIndividuals = additionalParameters && additionalParameters.facilityIndividuals || undefined;
        this.serviceAssignedTos = additionalParameters && additionalParameters.serviceAssignedTos || undefined;
        this.retrievalAssignedTos = additionalParameters && additionalParameters.retrievalAssignedTos || undefined;
        this.subpoenaAssignedTos = additionalParameters && additionalParameters.subpoenaAssignedTos || undefined;
        this.specialtyRequesteds = additionalParameters && additionalParameters.specialtyRequesteds || undefined;
        this.eligibilityCriteria = additionalParameters && additionalParameters.eligibilityCriteria || undefined;
        this.imedClaimCourtFees = additionalParameters && additionalParameters.imedClaimCourtFees || undefined;
        this.wcIssues = additionalParameters && additionalParameters.wcIssues || undefined;
        this.autoIssues = additionalParameters && additionalParameters.autoIssues || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.hasOwnProperty('Archived') && this.imedclaimservice.Archived != null ? this.imedclaimservice.Archived : false,
            }),
            AutoIssueId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Auto Issue',
                name: 'AutoIssueId',
                options: this.autoIssues,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.AutoIssueId || null,
            }),
            BeginningPageCount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Beginning Page Count',
                name: 'BeginningPageCount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.BeginningPageCount || null,
            }),
            CompilationDueDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Compilation Due Date',
                name: 'CompilationDueDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.CompilationDueDate || null,
            }),
            ContractRateFrom: new DynamicField({
                formGroup: this.formGroup,
                label: 'Contract Rate From',
                name: 'ContractRateFrom',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.ContractRateFrom || null,
            }),
            ContractRateTo: new DynamicField({
                formGroup: this.formGroup,
                label: 'Contract Rate To',
                name: 'ContractRateTo',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.ContractRateTo || null,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imedclaimservice && this.imedclaimservice.CreatedById || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.DateModified || null,
            }),
            EligibilityCriteriaId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Eligibility Criteria',
                name: 'EligibilityCriteriaId',
                options: this.eligibilityCriteria,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.EligibilityCriteriaId || null,
            }),
            EndingPageCount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ending Page Count',
                name: 'EndingPageCount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.EndingPageCount || null,
            }),
            ExamRequestedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Exam Requested By',
                name: 'ExamRequestedById',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.ExamRequestedById || null,
            }),
            FacilityAddressBookId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Facility Address Book',
                name: 'FacilityAddressBookId',
                options: this.facilityAddressBooks,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.FacilityAddressBookId || null,
            }),
            FacilityIndividualId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Facility Individual',
                name: 'FacilityIndividualId',
                options: this.facilityIndividuals,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.FacilityIndividualId || null,
            }),
            HighValueService: new DynamicField({
                formGroup: this.formGroup,
                label: 'High Value Service',
                name: 'HighValueService',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.hasOwnProperty('HighValueService') && this.imedclaimservice.HighValueService != null ? this.imedclaimservice.HighValueService : false,
            }),
            HistoricalNotes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Historical Notes',
                name: 'HistoricalNotes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.hasOwnProperty('HistoricalNotes') && this.imedclaimservice.HistoricalNotes != null ? this.imedclaimservice.HistoricalNotes.toString() : '',
            }),
            ImedClaimCourtFeeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Court Fee',
                name: 'ImedClaimCourtFeeId',
                options: this.imedClaimCourtFees,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.ImedClaimCourtFeeId || null,
            }),
            ImedClaimId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim',
                name: 'ImedClaimId',
                options: this.imedClaims,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imedclaimservice && this.imedclaimservice.ImedClaimId || null,
            }),
            LegacyImedClaimServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Legacy Imed Claim Service',
                name: 'LegacyImedClaimServiceId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.imedclaimservice && this.imedclaimservice.hasOwnProperty('LegacyImedClaimServiceId') && this.imedclaimservice.LegacyImedClaimServiceId != null ? this.imedclaimservice.LegacyImedClaimServiceId.toString() : '',
            }),
            Mmi: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mmi',
                name: 'Mmi',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.Mmi || null,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.ModifiedById || null,
            }),
            PhysicianId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physician',
                name: 'PhysicianId',
                options: this.physicians,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.PhysicianId || null,
            }),
            PurposeOfExam: new DynamicField({
                formGroup: this.formGroup,
                label: 'Purpose Of Exam',
                name: 'PurposeOfExam',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(500) ],
                validators: { 'maxlength': 500 },
                value: this.imedclaimservice && this.imedclaimservice.hasOwnProperty('PurposeOfExam') && this.imedclaimservice.PurposeOfExam != null ? this.imedclaimservice.PurposeOfExam.toString() : '',
            }),
            RetrievalAssignedToId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Retrieval Assigned To',
                name: 'RetrievalAssignedToId',
                options: this.retrievalAssignedTos,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.RetrievalAssignedToId || null,
            }),
            RushService: new DynamicField({
                formGroup: this.formGroup,
                label: 'Rush Service',
                name: 'RushService',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.hasOwnProperty('RushService') && this.imedclaimservice.RushService != null ? this.imedclaimservice.RushService : false,
            }),
            ServiceAddressId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Service Address',
                name: 'ServiceAddressId',
                options: this.serviceAddresses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.ServiceAddressId || null,
            }),
            ServiceAssignedToId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Service Assigned To',
                name: 'ServiceAssignedToId',
                options: this.serviceAssignedTos,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.ServiceAssignedToId || null,
            }),
            ServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Service',
                name: 'ServiceId',
                options: this.services,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imedclaimservice && this.imedclaimservice.ServiceId || null,
            }),
            ServiceStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Service Status',
                name: 'ServiceStatusId',
                options: this.serviceStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imedclaimservice && this.imedclaimservice.ServiceStatusId || null,
            }),
            SpecialtyRequestedId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Specialty Requested',
                name: 'SpecialtyRequestedId',
                options: this.specialtyRequesteds,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.SpecialtyRequestedId || null,
            }),
            StatusSetDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Status Set Date',
                name: 'StatusSetDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.StatusSetDate || null,
            }),
            StrivenBillId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Striven Bill',
                name: 'StrivenBillId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.StrivenBillId || null,
            }),
            SubpoenaAssignedToId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Subpoena Assigned To',
                name: 'SubpoenaAssignedToId',
                options: this.subpoenaAssignedTos,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.SubpoenaAssignedToId || null,
            }),
            WcIssueId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Wc Issue',
                name: 'WcIssueId',
                options: this.wcIssues,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaimservice && this.imedclaimservice.WcIssueId || null,
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
                label: 'Archived',
                value: this.imedclaimservice && this.imedclaimservice.hasOwnProperty('Archived') && this.imedclaimservice.Archived != null ? this.imedclaimservice.Archived : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            AutoIssueId: new DynamicLabel({
                label: 'Auto Issue',
                value: getMetaItemValue(this.autoIssues as unknown as IMetaItem[], this.imedclaimservice && this.imedclaimservice.hasOwnProperty('AutoIssueId') ? this.imedclaimservice.AutoIssueId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            BeginningPageCount: new DynamicLabel({
                label: 'Beginning Page Count',
                value: this.imedclaimservice && this.imedclaimservice.BeginningPageCount || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            CompilationDueDate: new DynamicLabel({
                label: 'Compilation Due Date',
                value: this.imedclaimservice && this.imedclaimservice.CompilationDueDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            ContractRateFrom: new DynamicLabel({
                label: 'Contract Rate From',
                value: this.imedclaimservice && this.imedclaimservice.ContractRateFrom || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            ContractRateTo: new DynamicLabel({
                label: 'Contract Rate To',
                value: this.imedclaimservice && this.imedclaimservice.ContractRateTo || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: null,
                    scale: 2,
                }),
            }),
            CreatedById: new DynamicLabel({
                label: 'Created By',
                value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.imedclaimservice && this.imedclaimservice.hasOwnProperty('CreatedById') ? this.imedclaimservice.CreatedById : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            DateCreated: new DynamicLabel({
                label: 'Date Created',
                value: this.imedclaimservice && this.imedclaimservice.DateCreated || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DateModified: new DynamicLabel({
                label: 'Date Modified',
                value: this.imedclaimservice && this.imedclaimservice.DateModified || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            EligibilityCriteriaId: new DynamicLabel({
                label: 'Eligibility Criteria',
                value: getMetaItemValue(this.eligibilityCriteria as unknown as IMetaItem[], this.imedclaimservice && this.imedclaimservice.hasOwnProperty('EligibilityCriteriaId') ? this.imedclaimservice.EligibilityCriteriaId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            EndingPageCount: new DynamicLabel({
                label: 'Ending Page Count',
                value: this.imedclaimservice && this.imedclaimservice.EndingPageCount || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            ExamRequestedById: new DynamicLabel({
                label: 'Exam Requested By',
                value: this.imedclaimservice && this.imedclaimservice.ExamRequestedById || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            FacilityAddressBookId: new DynamicLabel({
                label: 'Facility Address Book',
                value: getMetaItemValue(this.facilityAddressBooks as unknown as IMetaItem[], this.imedclaimservice && this.imedclaimservice.hasOwnProperty('FacilityAddressBookId') ? this.imedclaimservice.FacilityAddressBookId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            FacilityIndividualId: new DynamicLabel({
                label: 'Facility Individual',
                value: getMetaItemValue(this.facilityIndividuals as unknown as IMetaItem[], this.imedclaimservice && this.imedclaimservice.hasOwnProperty('FacilityIndividualId') ? this.imedclaimservice.FacilityIndividualId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            HighValueService: new DynamicLabel({
                label: 'High Value Service',
                value: this.imedclaimservice && this.imedclaimservice.hasOwnProperty('HighValueService') && this.imedclaimservice.HighValueService != null ? this.imedclaimservice.HighValueService : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            HistoricalNotes: new DynamicLabel({
                label: 'Historical Notes',
                value: this.imedclaimservice && this.imedclaimservice.hasOwnProperty('HistoricalNotes') && this.imedclaimservice.HistoricalNotes != null ? this.imedclaimservice.HistoricalNotes.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
            }),
            ImedClaimCourtFeeId: new DynamicLabel({
                label: 'Imed Claim Court Fee',
                value: getMetaItemValue(this.imedClaimCourtFees as unknown as IMetaItem[], this.imedclaimservice && this.imedclaimservice.hasOwnProperty('ImedClaimCourtFeeId') ? this.imedclaimservice.ImedClaimCourtFeeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ImedClaimId: new DynamicLabel({
                label: 'Imed Claim',
                value: getMetaItemValue(this.imedClaims as unknown as IMetaItem[], this.imedclaimservice && this.imedclaimservice.hasOwnProperty('ImedClaimId') ? this.imedclaimservice.ImedClaimId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            LegacyImedClaimServiceId: new DynamicLabel({
                label: 'Legacy Imed Claim Service',
                value: this.imedclaimservice && this.imedclaimservice.hasOwnProperty('LegacyImedClaimServiceId') && this.imedclaimservice.LegacyImedClaimServiceId != null ? this.imedclaimservice.LegacyImedClaimServiceId.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Mmi: new DynamicLabel({
                label: 'Mmi',
                value: this.imedclaimservice && this.imedclaimservice.Mmi || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            ModifiedById: new DynamicLabel({
                label: 'Modified By',
                value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.imedclaimservice && this.imedclaimservice.hasOwnProperty('ModifiedById') ? this.imedclaimservice.ModifiedById : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            PhysicianId: new DynamicLabel({
                label: 'Physician',
                value: getMetaItemValue(this.physicians as unknown as IMetaItem[], this.imedclaimservice && this.imedclaimservice.hasOwnProperty('PhysicianId') ? this.imedclaimservice.PhysicianId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            PurposeOfExam: new DynamicLabel({
                label: 'Purpose Of Exam',
                value: this.imedclaimservice && this.imedclaimservice.hasOwnProperty('PurposeOfExam') && this.imedclaimservice.PurposeOfExam != null ? this.imedclaimservice.PurposeOfExam.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            RetrievalAssignedToId: new DynamicLabel({
                label: 'Retrieval Assigned To',
                value: getMetaItemValue(this.retrievalAssignedTos as unknown as IMetaItem[], this.imedclaimservice && this.imedclaimservice.hasOwnProperty('RetrievalAssignedToId') ? this.imedclaimservice.RetrievalAssignedToId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            RushService: new DynamicLabel({
                label: 'Rush Service',
                value: this.imedclaimservice && this.imedclaimservice.hasOwnProperty('RushService') && this.imedclaimservice.RushService != null ? this.imedclaimservice.RushService : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ServiceAddressId: new DynamicLabel({
                label: 'Service Address',
                value: getMetaItemValue(this.serviceAddresses as unknown as IMetaItem[], this.imedclaimservice && this.imedclaimservice.hasOwnProperty('ServiceAddressId') ? this.imedclaimservice.ServiceAddressId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ServiceAssignedToId: new DynamicLabel({
                label: 'Service Assigned To',
                value: getMetaItemValue(this.serviceAssignedTos as unknown as IMetaItem[], this.imedclaimservice && this.imedclaimservice.hasOwnProperty('ServiceAssignedToId') ? this.imedclaimservice.ServiceAssignedToId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ServiceId: new DynamicLabel({
                label: 'Service',
                value: getMetaItemValue(this.services as unknown as IMetaItem[], this.imedclaimservice && this.imedclaimservice.hasOwnProperty('ServiceId') ? this.imedclaimservice.ServiceId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ServiceStatusId: new DynamicLabel({
                label: 'Service Status',
                value: getMetaItemValue(this.serviceStatuses as unknown as IMetaItem[], this.imedclaimservice && this.imedclaimservice.hasOwnProperty('ServiceStatusId') ? this.imedclaimservice.ServiceStatusId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            SpecialtyRequestedId: new DynamicLabel({
                label: 'Specialty Requested',
                value: getMetaItemValue(this.specialtyRequesteds as unknown as IMetaItem[], this.imedclaimservice && this.imedclaimservice.hasOwnProperty('SpecialtyRequestedId') ? this.imedclaimservice.SpecialtyRequestedId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            StatusSetDate: new DynamicLabel({
                label: 'Status Set Date',
                value: this.imedclaimservice && this.imedclaimservice.StatusSetDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            StrivenBillId: new DynamicLabel({
                label: 'Striven Bill',
                value: this.imedclaimservice && this.imedclaimservice.StrivenBillId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            SubpoenaAssignedToId: new DynamicLabel({
                label: 'Subpoena Assigned To',
                value: getMetaItemValue(this.subpoenaAssignedTos as unknown as IMetaItem[], this.imedclaimservice && this.imedclaimservice.hasOwnProperty('SubpoenaAssignedToId') ? this.imedclaimservice.SubpoenaAssignedToId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            WcIssueId: new DynamicLabel({
                label: 'Wc Issue',
                value: getMetaItemValue(this.wcIssues as unknown as IMetaItem[], this.imedclaimservice && this.imedclaimservice.hasOwnProperty('WcIssueId') ? this.imedclaimservice.WcIssueId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}

