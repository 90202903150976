import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IAutoIssue } from '../interfaces/auto-issue';

export interface IAutoIssueDynamicControlsParameters {
    formGroup?: string;
}

export class AutoIssueDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private autoissue?: IAutoIssue, additionalParameters?: IAutoIssueDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'AutoIssue';

        this.Form = {
            AdLsThatMayAffectThisClaim: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ad Ls That May Affect This Claim',
                name: 'AdLsThatMayAffectThisClaim',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('AdLsThatMayAffectThisClaim') && this.autoissue.AdLsThatMayAffectThisClaim != null ? this.autoissue.AdLsThatMayAffectThisClaim : true,
            }),
            Apportionment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Apportionment',
                name: 'Apportionment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('Apportionment') && this.autoissue.Apportionment != null ? this.autoissue.Apportionment : false,
            }),
            AppropriatenessOfTreatment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Appropriateness Of Treatment',
                name: 'AppropriatenessOfTreatment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('AppropriatenessOfTreatment') && this.autoissue.AppropriatenessOfTreatment != null ? this.autoissue.AppropriatenessOfTreatment : true,
            }),
            CausalRelationship: new DynamicField({
                formGroup: this.formGroup,
                label: 'Causal Relationship',
                name: 'CausalRelationship',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('CausalRelationship') && this.autoissue.CausalRelationship != null ? this.autoissue.CausalRelationship : true,
            }),
            CompleteHistory: new DynamicField({
                formGroup: this.formGroup,
                label: 'Complete History',
                name: 'CompleteHistory',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('CompleteHistory') && this.autoissue.CompleteHistory != null ? this.autoissue.CompleteHistory : true,
            }),
            DegreeOfDisability: new DynamicField({
                formGroup: this.formGroup,
                label: 'Degree Of Disability',
                name: 'DegreeOfDisability',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('DegreeOfDisability') && this.autoissue.DegreeOfDisability != null ? this.autoissue.DegreeOfDisability : false,
            }),
            Diagnosis: new DynamicField({
                formGroup: this.formGroup,
                label: 'Diagnosis',
                name: 'Diagnosis',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('Diagnosis') && this.autoissue.Diagnosis != null ? this.autoissue.Diagnosis : true,
            }),
            FunctionalCapabilityEvaluation: new DynamicField({
                formGroup: this.formGroup,
                label: 'Functional Capability Evaluation',
                name: 'FunctionalCapabilityEvaluation',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('FunctionalCapabilityEvaluation') && this.autoissue.FunctionalCapabilityEvaluation != null ? this.autoissue.FunctionalCapabilityEvaluation : false,
            }),
            HistoryOfAllegedInjury: new DynamicField({
                formGroup: this.formGroup,
                label: 'History Of Alleged Injury',
                name: 'HistoryOfAllegedInjury',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('HistoryOfAllegedInjury') && this.autoissue.HistoryOfAllegedInjury != null ? this.autoissue.HistoryOfAllegedInjury : true,
            }),
            Mmi: new DynamicField({
                formGroup: this.formGroup,
                label: 'Mmi',
                name: 'Mmi',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('Mmi') && this.autoissue.Mmi != null ? this.autoissue.Mmi : false,
            }),
            NeedForContinuedFollowUp: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Continued Follow Up',
                name: 'NeedForContinuedFollowUp',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForContinuedFollowUp') && this.autoissue.NeedForContinuedFollowUp != null ? this.autoissue.NeedForContinuedFollowUp : false,
            }),
            NeedForDme: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Dme',
                name: 'NeedForDme',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForDme') && this.autoissue.NeedForDme != null ? this.autoissue.NeedForDme : false,
            }),
            NeedForEssentialServicesHouseholdHelp: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Essential Services Household Help',
                name: 'NeedForEssentialServicesHouseholdHelp',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForEssentialServicesHouseholdHelp') && this.autoissue.NeedForEssentialServicesHouseholdHelp != null ? this.autoissue.NeedForEssentialServicesHouseholdHelp : false,
            }),
            NeedForFurtherDiagnosticTesting: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Further Diagnostic Testing',
                name: 'NeedForFurtherDiagnosticTesting',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForFurtherDiagnosticTesting') && this.autoissue.NeedForFurtherDiagnosticTesting != null ? this.autoissue.NeedForFurtherDiagnosticTesting : false,
            }),
            NeedForFurtherTreatment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Further Treatment',
                name: 'NeedForFurtherTreatment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForFurtherTreatment') && this.autoissue.NeedForFurtherTreatment != null ? this.autoissue.NeedForFurtherTreatment : false,
            }),
            NeedForFurtherTreatmentWithinYourScope: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Further Treatment Within Your Scope',
                name: 'NeedForFurtherTreatmentWithinYourScope',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForFurtherTreatmentWithinYourScope') && this.autoissue.NeedForFurtherTreatmentWithinYourScope != null ? this.autoissue.NeedForFurtherTreatmentWithinYourScope : true,
            }),
            NeedForInjections: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Injections',
                name: 'NeedForInjections',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForInjections') && this.autoissue.NeedForInjections != null ? this.autoissue.NeedForInjections : false,
            }),
            NeedForMassage: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Massage',
                name: 'NeedForMassage',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForMassage') && this.autoissue.NeedForMassage != null ? this.autoissue.NeedForMassage : false,
            }),
            NeedForPhysicalTherapy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Physical Therapy',
                name: 'NeedForPhysicalTherapy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForPhysicalTherapy') && this.autoissue.NeedForPhysicalTherapy != null ? this.autoissue.NeedForPhysicalTherapy : false,
            }),
            NeedForPrescriptionMedication: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Prescription Medication',
                name: 'NeedForPrescriptionMedication',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForPrescriptionMedication') && this.autoissue.NeedForPrescriptionMedication != null ? this.autoissue.NeedForPrescriptionMedication : false,
            }),
            NeedForSurgery: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Surgery',
                name: 'NeedForSurgery',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForSurgery') && this.autoissue.NeedForSurgery != null ? this.autoissue.NeedForSurgery : false,
            }),
            NeedForTransportation: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Transportation',
                name: 'NeedForTransportation',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForTransportation') && this.autoissue.NeedForTransportation != null ? this.autoissue.NeedForTransportation : false,
            }),
            ObjectiveFindingsOnExamDiagnosis: new DynamicField({
                formGroup: this.formGroup,
                label: 'Objective Findings On Exam Diagnosis',
                name: 'ObjectiveFindingsOnExamDiagnosis',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('ObjectiveFindingsOnExamDiagnosis') && this.autoissue.ObjectiveFindingsOnExamDiagnosis != null ? this.autoissue.ObjectiveFindingsOnExamDiagnosis : true,
            }),
            OccupationalHistory: new DynamicField({
                formGroup: this.formGroup,
                label: 'Occupational History',
                name: 'OccupationalHistory',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('OccupationalHistory') && this.autoissue.OccupationalHistory != null ? this.autoissue.OccupationalHistory : true,
            }),
            PastSubsequentMedicalHistory: new DynamicField({
                formGroup: this.formGroup,
                label: 'Past Subsequent Medical History',
                name: 'PastSubsequentMedicalHistory',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('PastSubsequentMedicalHistory') && this.autoissue.PastSubsequentMedicalHistory != null ? this.autoissue.PastSubsequentMedicalHistory : true,
            }),
            Permanency: new DynamicField({
                formGroup: this.formGroup,
                label: 'Permanency',
                name: 'Permanency',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('Permanency') && this.autoissue.Permanency != null ? this.autoissue.Permanency : false,
            }),
            PreExistingConditions: new DynamicField({
                formGroup: this.formGroup,
                label: 'Pre Existing Conditions',
                name: 'PreExistingConditions',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('PreExistingConditions') && this.autoissue.PreExistingConditions != null ? this.autoissue.PreExistingConditions : true,
            }),
            PresentComplaints: new DynamicField({
                formGroup: this.formGroup,
                label: 'Present Complaints',
                name: 'PresentComplaints',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('PresentComplaints') && this.autoissue.PresentComplaints != null ? this.autoissue.PresentComplaints : true,
            }),
            RecommendationForFurtherTreatment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Recommendation For Further Treatment',
                name: 'RecommendationForFurtherTreatment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('RecommendationForFurtherTreatment') && this.autoissue.RecommendationForFurtherTreatment != null ? this.autoissue.RecommendationForFurtherTreatment : false,
            }),
            ReturnToWorkRestrictionsAdl: new DynamicField({
                formGroup: this.formGroup,
                label: 'Return To Work Restrictions Adl',
                name: 'ReturnToWorkRestrictionsAdl',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('ReturnToWorkRestrictionsAdl') && this.autoissue.ReturnToWorkRestrictionsAdl != null ? this.autoissue.ReturnToWorkRestrictionsAdl : false,
            }),
            ReviewOfRecords: new DynamicField({
                formGroup: this.formGroup,
                label: 'Review Of Records',
                name: 'ReviewOfRecords',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('ReviewOfRecords') && this.autoissue.ReviewOfRecords != null ? this.autoissue.ReviewOfRecords : true,
            }),
            Slu: new DynamicField({
                formGroup: this.formGroup,
                label: 'Slu',
                name: 'Slu',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('Slu') && this.autoissue.Slu != null ? this.autoissue.Slu : false,
            }),
            SpecificTreatmentQuestions: new DynamicField({
                formGroup: this.formGroup,
                label: 'Specific Treatment Questions',
                name: 'SpecificTreatmentQuestions',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.autoissue && this.autoissue.hasOwnProperty('SpecificTreatmentQuestions') && this.autoissue.SpecificTreatmentQuestions != null ? this.autoissue.SpecificTreatmentQuestions.toString() : '',
            }),
        };

        this.View = {
            AdLsThatMayAffectThisClaim: new DynamicLabel({
                label: 'Ad Ls That May Affect This Claim',
                value: this.autoissue && this.autoissue.hasOwnProperty('AdLsThatMayAffectThisClaim') && this.autoissue.AdLsThatMayAffectThisClaim != null ? this.autoissue.AdLsThatMayAffectThisClaim : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            Apportionment: new DynamicLabel({
                label: 'Apportionment',
                value: this.autoissue && this.autoissue.hasOwnProperty('Apportionment') && this.autoissue.Apportionment != null ? this.autoissue.Apportionment : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            AppropriatenessOfTreatment: new DynamicLabel({
                label: 'Appropriateness Of Treatment',
                value: this.autoissue && this.autoissue.hasOwnProperty('AppropriatenessOfTreatment') && this.autoissue.AppropriatenessOfTreatment != null ? this.autoissue.AppropriatenessOfTreatment : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            CausalRelationship: new DynamicLabel({
                label: 'Causal Relationship',
                value: this.autoissue && this.autoissue.hasOwnProperty('CausalRelationship') && this.autoissue.CausalRelationship != null ? this.autoissue.CausalRelationship : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            CompleteHistory: new DynamicLabel({
                label: 'Complete History',
                value: this.autoissue && this.autoissue.hasOwnProperty('CompleteHistory') && this.autoissue.CompleteHistory != null ? this.autoissue.CompleteHistory : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DegreeOfDisability: new DynamicLabel({
                label: 'Degree Of Disability',
                value: this.autoissue && this.autoissue.hasOwnProperty('DegreeOfDisability') && this.autoissue.DegreeOfDisability != null ? this.autoissue.DegreeOfDisability : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            Diagnosis: new DynamicLabel({
                label: 'Diagnosis',
                value: this.autoissue && this.autoissue.hasOwnProperty('Diagnosis') && this.autoissue.Diagnosis != null ? this.autoissue.Diagnosis : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            FunctionalCapabilityEvaluation: new DynamicLabel({
                label: 'Functional Capability Evaluation',
                value: this.autoissue && this.autoissue.hasOwnProperty('FunctionalCapabilityEvaluation') && this.autoissue.FunctionalCapabilityEvaluation != null ? this.autoissue.FunctionalCapabilityEvaluation : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            HistoryOfAllegedInjury: new DynamicLabel({
                label: 'History Of Alleged Injury',
                value: this.autoissue && this.autoissue.hasOwnProperty('HistoryOfAllegedInjury') && this.autoissue.HistoryOfAllegedInjury != null ? this.autoissue.HistoryOfAllegedInjury : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            Mmi: new DynamicLabel({
                label: 'Mmi',
                value: this.autoissue && this.autoissue.hasOwnProperty('Mmi') && this.autoissue.Mmi != null ? this.autoissue.Mmi : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            NeedForContinuedFollowUp: new DynamicLabel({
                label: 'Need For Continued Follow Up',
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForContinuedFollowUp') && this.autoissue.NeedForContinuedFollowUp != null ? this.autoissue.NeedForContinuedFollowUp : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            NeedForDme: new DynamicLabel({
                label: 'Need For Dme',
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForDme') && this.autoissue.NeedForDme != null ? this.autoissue.NeedForDme : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            NeedForEssentialServicesHouseholdHelp: new DynamicLabel({
                label: 'Need For Essential Services Household Help',
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForEssentialServicesHouseholdHelp') && this.autoissue.NeedForEssentialServicesHouseholdHelp != null ? this.autoissue.NeedForEssentialServicesHouseholdHelp : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            NeedForFurtherDiagnosticTesting: new DynamicLabel({
                label: 'Need For Further Diagnostic Testing',
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForFurtherDiagnosticTesting') && this.autoissue.NeedForFurtherDiagnosticTesting != null ? this.autoissue.NeedForFurtherDiagnosticTesting : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            NeedForFurtherTreatment: new DynamicLabel({
                label: 'Need For Further Treatment',
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForFurtherTreatment') && this.autoissue.NeedForFurtherTreatment != null ? this.autoissue.NeedForFurtherTreatment : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            NeedForFurtherTreatmentWithinYourScope: new DynamicLabel({
                label: 'Need For Further Treatment Within Your Scope',
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForFurtherTreatmentWithinYourScope') && this.autoissue.NeedForFurtherTreatmentWithinYourScope != null ? this.autoissue.NeedForFurtherTreatmentWithinYourScope : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            NeedForInjections: new DynamicLabel({
                label: 'Need For Injections',
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForInjections') && this.autoissue.NeedForInjections != null ? this.autoissue.NeedForInjections : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            NeedForMassage: new DynamicLabel({
                label: 'Need For Massage',
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForMassage') && this.autoissue.NeedForMassage != null ? this.autoissue.NeedForMassage : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            NeedForPhysicalTherapy: new DynamicLabel({
                label: 'Need For Physical Therapy',
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForPhysicalTherapy') && this.autoissue.NeedForPhysicalTherapy != null ? this.autoissue.NeedForPhysicalTherapy : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            NeedForPrescriptionMedication: new DynamicLabel({
                label: 'Need For Prescription Medication',
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForPrescriptionMedication') && this.autoissue.NeedForPrescriptionMedication != null ? this.autoissue.NeedForPrescriptionMedication : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            NeedForSurgery: new DynamicLabel({
                label: 'Need For Surgery',
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForSurgery') && this.autoissue.NeedForSurgery != null ? this.autoissue.NeedForSurgery : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            NeedForTransportation: new DynamicLabel({
                label: 'Need For Transportation',
                value: this.autoissue && this.autoissue.hasOwnProperty('NeedForTransportation') && this.autoissue.NeedForTransportation != null ? this.autoissue.NeedForTransportation : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ObjectiveFindingsOnExamDiagnosis: new DynamicLabel({
                label: 'Objective Findings On Exam Diagnosis',
                value: this.autoissue && this.autoissue.hasOwnProperty('ObjectiveFindingsOnExamDiagnosis') && this.autoissue.ObjectiveFindingsOnExamDiagnosis != null ? this.autoissue.ObjectiveFindingsOnExamDiagnosis : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            OccupationalHistory: new DynamicLabel({
                label: 'Occupational History',
                value: this.autoissue && this.autoissue.hasOwnProperty('OccupationalHistory') && this.autoissue.OccupationalHistory != null ? this.autoissue.OccupationalHistory : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            PastSubsequentMedicalHistory: new DynamicLabel({
                label: 'Past Subsequent Medical History',
                value: this.autoissue && this.autoissue.hasOwnProperty('PastSubsequentMedicalHistory') && this.autoissue.PastSubsequentMedicalHistory != null ? this.autoissue.PastSubsequentMedicalHistory : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            Permanency: new DynamicLabel({
                label: 'Permanency',
                value: this.autoissue && this.autoissue.hasOwnProperty('Permanency') && this.autoissue.Permanency != null ? this.autoissue.Permanency : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            PreExistingConditions: new DynamicLabel({
                label: 'Pre Existing Conditions',
                value: this.autoissue && this.autoissue.hasOwnProperty('PreExistingConditions') && this.autoissue.PreExistingConditions != null ? this.autoissue.PreExistingConditions : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            PresentComplaints: new DynamicLabel({
                label: 'Present Complaints',
                value: this.autoissue && this.autoissue.hasOwnProperty('PresentComplaints') && this.autoissue.PresentComplaints != null ? this.autoissue.PresentComplaints : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            RecommendationForFurtherTreatment: new DynamicLabel({
                label: 'Recommendation For Further Treatment',
                value: this.autoissue && this.autoissue.hasOwnProperty('RecommendationForFurtherTreatment') && this.autoissue.RecommendationForFurtherTreatment != null ? this.autoissue.RecommendationForFurtherTreatment : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ReturnToWorkRestrictionsAdl: new DynamicLabel({
                label: 'Return To Work Restrictions Adl',
                value: this.autoissue && this.autoissue.hasOwnProperty('ReturnToWorkRestrictionsAdl') && this.autoissue.ReturnToWorkRestrictionsAdl != null ? this.autoissue.ReturnToWorkRestrictionsAdl : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ReviewOfRecords: new DynamicLabel({
                label: 'Review Of Records',
                value: this.autoissue && this.autoissue.hasOwnProperty('ReviewOfRecords') && this.autoissue.ReviewOfRecords != null ? this.autoissue.ReviewOfRecords : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            Slu: new DynamicLabel({
                label: 'Slu',
                value: this.autoissue && this.autoissue.hasOwnProperty('Slu') && this.autoissue.Slu != null ? this.autoissue.Slu : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            SpecificTreatmentQuestions: new DynamicLabel({
                label: 'Specific Treatment Questions',
                value: this.autoissue && this.autoissue.hasOwnProperty('SpecificTreatmentQuestions') && this.autoissue.SpecificTreatmentQuestions != null ? this.autoissue.SpecificTreatmentQuestions.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
            }),
        };

    }
}

